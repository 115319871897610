import { Button, Descriptions, Drawer, DrawerProps, List, Space, Tag, Typography } from "antd";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { TRANSLATION_KEY } from "../helpers/consts";
import { Filter, IUser } from "../models/user";
import { FilterOutlined, ReloadOutlined, RestOutlined } from "@ant-design/icons";
import moment from "moment";
import { useAppSelector } from "../hooks";
import { RootState } from "../store";

type Props = {
  filters: Filter[];
  resetFilters: () => void;
} & DrawerProps;

const FiltersView: React.FC<Props> = ({ filters, resetFilters }) => {
  const [visible, set_filtersView] = React.useState<boolean>(false);
  const [localFilters, set_localFilters] = React.useState<Filter[]>([]);

  const { user } = useAppSelector((state: RootState) => state.userReducer);

  useEffect(() => {
    set_localFilters(
      mergeFilters(
        filters.filter((x) => x.value !== "true,false"),
        user,
      ),
    );
  }, [filters]);

  return (
    <>
      <Button
        onClick={() => {
          set_filtersView(true);
        }}
        type="link"
        icon={<FilterOutlined />}
      >
        {t(TRANSLATION_KEY.numberOfFilters)}: {localFilters.length}
      </Button>
      <Drawer
        width={540}
        title={t(TRANSLATION_KEY.activeFilters)}
        onClose={() => {
          set_filtersView(false);
        }}
        visible={visible}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button type="primary" onClick={resetFilters}>
            <ReloadOutlined />
            {t(TRANSLATION_KEY.resetFilters)}
          </Button>
        </div>

        <List
          dataSource={localFilters}
          renderItem={(filter, index) => {
            return (
              <List.Item>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography.Text>{t(filter.name)}:</Typography.Text>{" "}
                  <Typography.Text>{displayFilters(filter, "LLL")}</Typography.Text>
                </div>
              </List.Item>
            );
          }}
        />
      </Drawer>
    </>
  );
};

export default FiltersView;

const displayFilters = (filter: Filter, dateFormat: string) => {
  if (filter.value instanceof Array) {
    return filter.value.join(", ");
  } else if (filter.columnFilterType === "DateTimeFilter") {
    return filter.value as string;
  } else if (filter.columnFilterType === "BooleanFilter") {
    if (filter.value === "true") {
      return (
        <Tag style={{ margin: 0 }} color="green">
          {t(TRANSLATION_KEY.yes)}
        </Tag>
      );
    } else if (filter.value === "false") {
      return (
        <Tag style={{ margin: 0 }} color="red">
          {t(TRANSLATION_KEY.no)}
        </Tag>
      );
    }
  }
  return t(filter.value as string);
};

function mergeFilters(filters: Filter[], user: IUser): Filter[] {
  const merged: Record<string, Filter> = {};
  for (const filter of filters) {
    if (filter.cf_id) {
      if (!merged[filter.cf_id]) {
        // If the filter with the same cf_id doesn't exist, initialize it
        merged[filter.cf_id] = { ...filter };
      } else {
        // Merge the values if the filter with the same cf_id already exists
        const existingFilter = merged[filter.cf_id];
        if (existingFilter.columnFilterType === "DateTimeFilter") {
          // Combine date ranges
          const values = [existingFilter.value, filter.value].sort();
          existingFilter.value =
            moment(values[0] as string).format(user.account.date_format || "LLL") +
            " - " +
            moment(values[1] as string).format(user.account.date_format || "LLL");
        } else if (existingFilter.columnFilterType === "NumberFilter") {
          // Combine date ranges
          const values = [existingFilter.value, filter.value].sort();
          existingFilter.value = values[0] + " - " + values[1];
        }
      }
    } else {
      // For filters without cf_id, treat them as unique
      merged[Math.random().toString(36)] = { ...filter };
    }
  }

  // Return the merged filters as an array
  return Object.values(merged);
}
