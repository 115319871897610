import { financeSlice } from ".";
import { AppDispatch } from "../..";
import { DataWithPagination, IApiResponse, IDataToApi } from "../../../models";
import { AddBankAccount, AddInvoice, BankAccount, Invoice } from "../../../models/finance";
import api, { failedQueue, isRefreshing } from "../../../services";

// #region Bank Accounts

export const getBankAccountsXHR = async (
  data: Partial<
    { mergeData: boolean } & IDataToApi<null, IApiResponse<DataWithPagination<BankAccount>>>
  >,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(financeSlice.actions.getBankAccounts());
    let response = await api.get<IApiResponse<DataWithPagination<BankAccount>>>(
      "finances/bank_account/",
      {
        params: data.queryParams,
        headers: { Authorization: "Bearer " + token },
      },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(
      financeSlice.actions.getBankAccountsSuccess({
        ...response.data,
        mergeData: !!data.mergeData,
      }),
    );
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getBankAccountsXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(financeSlice.actions.getBankAccountsFail(error.message));
  }
};

export const addBankAccountXHR = async (
  data: Partial<IDataToApi<AddBankAccount, IApiResponse<BankAccount>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(financeSlice.actions.addBankAccount());
    let response = await api.post<IApiResponse<BankAccount>>(
      `finances/bank_account/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(financeSlice.actions.addBankAccountSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => addBankAccountXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(financeSlice.actions.addBankAccountFail(error.message));
  }
};

export const updateBankAccountXHR = async (
  data: Partial<IDataToApi<AddBankAccount, IApiResponse<BankAccount>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(financeSlice.actions.updateBankAccount());
    let response = await api.put<IApiResponse<BankAccount>>(
      `finances/bank_account/${data.id}/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(financeSlice.actions.updateBankAccountSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => updateBankAccountXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(financeSlice.actions.updateBankAccountFail(error.message));
  }
};

// #endregion

// #region Invoices
export const getInvoicesXHR = async (
  data: Partial<{ mergeData: boolean } & IDataToApi<null, IApiResponse<Invoice[]>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");
  try {
    dispatch(financeSlice.actions.getInvoices());
    let response = await api.get<IApiResponse<Invoice[]>>("finances/invoice/", {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(financeSlice.actions.getInvoicesSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => getInvoicesXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(financeSlice.actions.getInvoicesFail(error.message));
  }
};

export const addInvoiceXHR = async (
  data: Partial<IDataToApi<AddInvoice, IApiResponse<Invoice>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(financeSlice.actions.addInvoice());
    let response = await api.post<IApiResponse<Invoice>>(
      `finances/invoice/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(financeSlice.actions.addInvoiceSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => addInvoiceXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(financeSlice.actions.addInvoiceFail(error.message));
  }
};

export const updateInvoiceXHR = async (
  data: Partial<IDataToApi<AddInvoice, IApiResponse<Invoice>>>,
  dispatch: AppDispatch,
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(financeSlice.actions.updateInvoice());
    let response = await api.put<IApiResponse<Invoice>>(
      `finances/invoice/${data.id}/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } },
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(financeSlice.actions.updateInvoiceSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      if (isRefreshing) {
        failedQueue.push(() => updateInvoiceXHR(data, dispatch));
      }
      return;
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(financeSlice.actions.updateInvoiceFail(error.message));
  }
};

//#endregion
