import { message } from "antd";
import { t } from "i18next";
import { useCallback, useState } from "react";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { debounce } from "../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  addInvoiceXHR,
  getInvoicesXHR,
  updateInvoiceXHR,
} from "../../store/reducers/finance/actionCreators";
import { AddInvoice } from "../../models/finance";

const useInvoices = () => {
  // Hooks
  const dispatch = useAppDispatch();

  // State
  const { invoices, getInvoicesStatus } = useAppSelector((state) => state.financeReducer);
  const [filters, setFilters] = useState<InvoicesFilters>({
    offset: 0,
    limit: 10,
    search: "",
  });

  // #region CRUD Operations
  function fetchData(queryParams = filters) {
    getInvoicesXHR(
      {
        queryParams,
        errorCallback: (data) => {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
        },
      },
      dispatch,
    );
  }

  function addInvoice(body: AddInvoice, onSuccess?: () => void) {
    addInvoiceXHR(
      {
        body,
        successCallback(data) {
          onSuccess && onSuccess();
        },
        errorCallback: (data) => {
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
        },
      },
      dispatch,
    );
  }

  function updateInvoice(body: AddInvoice, id: number, onSuccess?: () => void) {
    updateInvoiceXHR(
      {
        body,
        id,
        successCallback(data) {
          onSuccess && onSuccess();
        },
        errorCallback: (data) => {
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
        },
      },
      dispatch,
    );
  }

  // #endregion

  // #region Filters
  function updateFilters(newFilters: Partial<InvoicesFilters>) {
    setFilters((prev) => ({
      ...prev,
      ...newFilters,
    }));
  }

  function sync(filters: InvoicesFilters) {
    // Update filters
    updateFilters(filters);
    // Fetch data
    fetchData(filters);
  }

  function onPageChange(page: number, pageSize: number) {
    let newFilters: InvoicesFilters = {
      ...filters,
      offset: (page - 1) * pageSize,
      limit: pageSize,
    };
    sync(newFilters);
  }

  function onSearchChange(search: string) {
    let newFilters = {
      ...filters,
      search,
    };
    sync(newFilters);
  }

  const search = useCallback(debounce<typeof onSearchChange>(onSearchChange, 500), []);

  // #endregion

  return {
    data: invoices,
    loading: getInvoicesStatus === "loading",
    filters,
    fetchData,
    onPageChange,
    search,
    add: addInvoice,
    update: updateInvoice,
  };
};

export default useInvoices;
export type InvoicesHook = ReturnType<typeof useInvoices>;
export type InvoicesFilters = {
  // Pagination params
  offset: number;
  limit: number;
  // Data params
  search: string;
};
