import {
  Button,
  Drawer,
  Input,
  Modal,
  PageHeader,
  Space,
  Spin,
  Table,
  TableColumnType,
  Tooltip,
  Typography,
  message,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { IClient } from "../../../models/clients";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getClientsXHR } from "../../../store/reducers/clients/actionCreators";
import { InfoCircleOutlined } from "@ant-design/icons";
import ClientForm from "../../../componentsform/ClientForm";
import RentForm from "../../../componentsform/RentForm";
import { debounce } from "../../../helpers/functions";
import LoadMoreButton from "../../../components/LoadMoreButton";
import { clientsSlice } from "../../../store/reducers/clients";
import DrawerAsset from "../../Client/components/Drawer/Asset";
import { TableView } from "../../../models/user";
import ViewTabs, {
  ColumnFilterType,
  CustomTableColumn,
  customTableColumnRender,
  filterColumns,
  getFilterComponent,
  saveTableDefinition,
} from "../../../components/ViewTabs";
import Columns from "../../../components/ViewTabs/Columns";
import { useDrawerToggle } from "..";

interface IProps {}

export const CLIENTS_LIMIT_SIZE = 20;

export interface ILodadMoreParams {
  offset: number;
  limit: number;
}

interface IFilters {
  search: string;
}

type TableRenders =
  | "ClientName"
  | "ClientDescription"
  | "ClientAddress"
  | "ClientPhone"
  | "ClientAsset";

const Clients: React.FC<IProps> = ({}) => {
  // Hooks
  const dispatch = useAppDispatch();

  // Variables
  const [selectedClient, setSelectedClient] = React.useState<IClient>();
  const [assetVisible, set_assetVisible] = React.useState<number>(-1);
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const { addNew, set_addNew } = useDrawerToggle();
  const [firstMount, set_firstMount] = React.useState<boolean>(true);
  const [filters, set_filters] = React.useState<IFilters>({
    search: "",
  });
  const [loadMoreParams, setLoadMoreParams] = useState<ILodadMoreParams>({
    offset: 0,
    limit: CLIENTS_LIMIT_SIZE,
  });

  const { user } = useAppSelector((state) => state.userReducer);

  const [tableDefinition, set_tableDefinition] = useState<
    Array<
      TableView & {
        updated: number;
        active: boolean;
      }
    >
  >(
    user.account.views?.clients?.map((x, i) => ({
      ...x,
      updated: new Date().getTime(),
      active: i === 0,
    })) || [],
  );

  const [hasMore, setHasMore] = useState<boolean>(true);
  const [COLUMNS, set_COLUMNS] = React.useState<
    Array<Partial<CustomTableColumn> & TableColumnType<IClient>>
  >([]);

  useEffect(() => {
    if (firstMount) {
      set_firstMount(false);
      return;
    }
    let tmpDefinitions =
      user.account.views?.clients?.map((x, i) => ({
        ...x,
        updated: new Date().getTime(),
        active: i === 0,
      })) || [];
    set_tableDefinition(tmpDefinitions);
  }, [user]);

  // Methods
  const getClients = async (
    loadMoreParams: ILodadMoreParams,
    mergeData: boolean,
    _fitler: IFilters,
  ) => {
    getClientsXHR(
      {
        queryParams: {
          search: _fitler.search,
          limit: loadMoreParams.limit,
          offset: loadMoreParams.offset,
          status: "active,archived",
        },
        mergeData,
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
          set_filters(_fitler);
        },
        successCallback: (res) => {
          set_filters(_fitler);
          setLoadMoreParams({
            ...loadMoreParams,
            offset: loadMoreParams.offset + CLIENTS_LIMIT_SIZE,
          });
          if (res.results && res.results?.length < CLIENTS_LIMIT_SIZE) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
        },
      },
      dispatch,
    );
  };

  const { clients, getClientsStatus } = useAppSelector((state) => state.clientsReducer);
  useEffect(() => {
    let activeView = tableDefinition.find((x) => x.active);
    if (activeView) {
      setColumns(activeView.table_structure, 0);
    }
    getClients(loadMoreParams, false, filters);
  }, []);

  const debouncedGetClients = useCallback(
    debounce((_loadMoreParams: ILodadMoreParams, _mergeData: boolean, _fitler: IFilters) => {
      getClients(_loadMoreParams, _mergeData, _fitler);
    }, 600),
    [],
  );

  function onSearch(value: string) {
    // When searching by name, we need to reset the offset and limit
    let newLoadMoreParams = { offset: 0, limit: CLIENTS_LIMIT_SIZE };
    setLoadMoreParams(newLoadMoreParams);
    debouncedGetClients(newLoadMoreParams, false, { ...filters, search: value });
  }

  const render = (key: TableRenders) => {
    let tmp: Record<TableRenders, any> = {
      ClientName: (text: string, value: IClient) => <Link to={`${value.id}`}>{value.name}</Link>,
      ClientDescription: (text: string, value: IClient) => (
        <Typography.Text>{t(value.description)}</Typography.Text>
      ),
      ClientAddress: (text: string, value: IClient) => (
        <Typography.Text>{t(value.address)}</Typography.Text>
      ),
      ClientPhone: (text: string, value: IClient) => (
        <Typography.Text>{t(value.phone_number)}</Typography.Text>
      ),
      ClientAsset: (text: string, value: IClient) => (
        <Button
          onClick={() => {
            setSelectedClient(value);
            set_assetVisible(value.id);
          }}
          type="link"
        >
          {t(TRANSLATION_KEY.asset)}
        </Button>
      ),
    };
    return tmp[key];
  };

  const setColumns = (_c: CustomTableColumn[], viewIndex: number) => {
    // ostaje unutar komponente za koju se prave views-ovi iz razloga jer će trebati filtere okidati
    let tmp: Array<CustomTableColumn & TableColumnType<IClient>> = [];
    _c.forEach((c) => {
      tmp.push({
        ...c,
        title: t(c.title),
        render: c.columnRenderComponent
          ? render(c.columnRenderComponent as TableRenders)
          : customTableColumnRender(
              c.filterComponent as ColumnFilterType,
              c.dataIndex,
              user,
              "custom_fields_v2",
            ),
        onCell: (record: IClient) => {
          return { rowSpan: 1 };
        },
        filterDropdown: undefined,
      });
    });
    set_COLUMNS(tmp);
  };

  return (
    <div>
      <div>
        <div>
          <ViewTabs
            hideDivider
            onChange={(activeKey) => {
              let index = tableDefinition.findIndex((view) => view.name === activeKey);
              let tmpList = [...tableDefinition];
              if (index === -1) {
                message.error("view_onSaveColumnsDefinition");
                return;
              }
              tmpList = tmpList.map((x) => ({ ...x, active: false }));
              tmpList[index] = { ...tmpList[index], active: true };
              getClients(
                {
                  limit: CLIENTS_LIMIT_SIZE,
                  offset: 0,
                },
                false,
                filters,
              );
              setColumns(tmpList[index].table_structure, index);
              set_tableDefinition(tmpList);
            }}
            setColumns={setColumns}
            views={tableDefinition}
            viewCategory="clients"
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "18px 0",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {!firstMount && (
              <Columns
                columns={COLUMNS}
                set_COLUMNS={(columns) => {
                  let index = tableDefinition.findIndex((view) => view.active === true);
                  if (index === -1) {
                    message.error("view");
                    return;
                  }
                  let tmp = tableDefinition[index];
                  if (!tmp) {
                    return;
                  }

                  let inerTmp: CustomTableColumn[] = [];
                  columns.forEach((x) => {
                    inerTmp.push({
                      title: x.title || "",
                      dataIndex: x.dataIndex || "",
                      visible: x.visible === undefined ? 1 : x.visible,
                      onCellFlag: x.onCellFlag || false,
                      filterComponent: x.filterComponent || null,
                      columnRenderComponent: x.columnRenderComponent || null,
                      objectKey: x.objectKey || null,
                    });
                  });
                  tmp = { ...tmp, table_structure: inerTmp, updated: Date.now(), active: true };
                  let tmpTableDefinition = [...tableDefinition].map((x) => ({
                    ...x,
                    active: false,
                  }));
                  tmpTableDefinition[index] = tmp;
                  setColumns(inerTmp, index);
                  set_tableDefinition(tmpTableDefinition);
                }}
              />
            )}

            <Button
              style={{ marginLeft: 18 }}
              type="link"
              onClick={() => {
                let activeView = tableDefinition.find((x) => x.active);
                saveTableDefinition(
                  tableDefinition,
                  user.account.views?.clients?.find((x) => x.id === activeView?.id),
                  "clients",
                );
              }}
            >
              {t(TRANSLATION_KEY.saveChanges)}
            </Button>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tooltip title={t(TRANSLATION_KEY.searchByClientName)}>
              <InfoCircleOutlined />
            </Tooltip>
            <div style={{ width: 12 }} />
            <Input.Search
              onChange={({ target: { value } }) => {
                let search = value?.toLocaleLowerCase() || "";
                onSearch(search);
              }}
              allowClear
            />
          </div>
        </div>
        <Table
          size="small"
          rowKey={(item) => item.id}
          dataSource={clients}
          columns={filterColumns<IClient>(COLUMNS)}
          pagination={false}
          loading={getClientsStatus === "loading"}
        />

        {hasMore && (
          <LoadMoreButton
            loading={getClientsStatus === "loading"}
            onClick={() => {
              getClients(loadMoreParams, true, filters);
            }}
          >
            {t(TRANSLATION_KEY.loadMore)}
          </LoadMoreButton>
        )}

        <Drawer
          title={t(TRANSLATION_KEY.addClient)}
          width={540}
          destroyOnClose={true}
          children={
            addNew?.client ? (
              <ClientForm
                showAddMore={true}
                client={addNew.client}
                close={() => set_addNew(undefined)}
              />
            ) : null
          }
          visible={!!addNew}
          onClose={() => set_addNew(undefined)}
        />

        {/* Modal */}
        <Modal
          visible={modalVisible}
          onCancel={() => {
            setModalVisible(false);
          }}
          footer={null}
          centered
          title={t(TRANSLATION_KEY.rentAssetTitle)}
          width={1200}
          closable={true}
          destroyOnClose
        >
          {selectedClient && (
            <RentForm client={selectedClient} close={() => setModalVisible(false)} />
          )}
        </Modal>

        <Drawer
          width={540}
          visible={assetVisible !== -1}
          onClose={() => set_assetVisible(-1)}
          title={t(TRANSLATION_KEY.asset)}
          destroyOnClose={true}
        >
          <DrawerAsset client_id={assetVisible} openModal={() => setModalVisible(true)} />
        </Drawer>
      </div>
    </div>
  );
};

export default Clients;
