import { Button, DatePicker, Select } from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useImperativeHandle, useRef, useState, forwardRef } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useEffectOnce } from "react-use";
import { TRANSLATION_KEY } from "../helpers/consts";
import { useAppSelector } from "../hooks";

export type CalendarTypes = "month" | "date" | "quarter" | "year" | "week";

const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {
  onChange: (start: string | undefined, end: string | undefined, type?: CalendarTypes) => void;
  start?: string;
  end?: string;
  type: CalendarTypes;
  wraperStyle?: React.CSSProperties;
  pickerStyle?: React.CSSProperties;
  selectStyle?: React.CSSProperties;
  dropdownStyle?: React.CSSProperties;
  skipInitial: boolean;
  allowClear?: boolean;
}

const DatePickerCustom = forwardRef((props: IProps, ref) => {
  const {
    onChange,
    start,
    end,
    type,
    wraperStyle,
    pickerStyle,
    selectStyle,
    dropdownStyle,
    skipInitial,
    allowClear,
  } = props;

  // State Variables
  const [initial, setInitial] = useState(skipInitial);
  const { user } = useAppSelector((state) => state.userReducer);
  const [calendarType, set_calendarType] = useState<CalendarTypes>(type);
  const [calendarValue, set_calendarValue] = useState<{
    start: string | undefined;
    end: string | undefined;
  }>({ start, end });
  const [rangePickerTypes, setRangePickerTypes] = useState([
    { text: t(TRANSLATION_KEY.week), value: "week" },
    { text: t(TRANSLATION_KEY.month), value: "month" },
    { text: t(TRANSLATION_KEY.year), value: "year" },
    { text: t(TRANSLATION_KEY.quarter), value: "quarter" },
    { text: t(TRANSLATION_KEY.date), value: "date" },
  ]);

  useEffect(() => {
    set_calendarValue({ start, end });
    set_calendarType(type);
  }, [start, end, type]);

  // Methods exposed to parent
  useImperativeHandle(ref, () => ({
    setValues: (type: CalendarTypes, start: string | undefined, end: string | undefined) => {
      set_calendarValue({ start, end });
      set_calendarType(type);
    },
    getCurrentValue: () => calendarValue,
  }));

  useEffect(() => {
    setRangePickerTypes([
      { text: t(TRANSLATION_KEY.week), value: "week" },
      { text: t(TRANSLATION_KEY.month), value: "month" },
      { text: t(TRANSLATION_KEY.year), value: "year" },
      { text: t(TRANSLATION_KEY.quarter), value: "quarter" },
      { text: t(TRANSLATION_KEY.date), value: "date" },
    ]);
  }, [user.account.language]);

  useEffectOnce(() => {
    if (!initial) {
      onChange(calendarValue.start || "", calendarValue.end || "", calendarType);
    }
  });

  return (
    <div style={wraperStyle}>
      <Select
        dropdownStyle={dropdownStyle}
        placeholder="Tip kalendara"
        style={selectStyle}
        value={calendarType}
        onClear={() => set_calendarType("date")}
        onSelect={(x: CalendarTypes) => {
          unstable_batchedUpdates(() => {
            let _start =
              x === "date"
                ? moment().subtract(30, "day").toISOString(true)
                : moment().startOf(x).toISOString(true);
            let _end =
              x === "date"
                ? moment().add(1, "day").toISOString(true)
                : moment().endOf(x).toISOString(true);
            set_calendarValue({ start: _start, end: _end });
            set_calendarType(x);
            onChange(_start, _end, x);
          });
        }}
      >
        {rangePickerTypes.map((x) => (
          <Option key={x.value} value={x.value}>
            {x.text}
          </Option>
        ))}
      </Select>

      {calendarType === "date" ? (
        <RangePicker
          allowClear={allowClear}
          format={user.account.date_format || "DD.MM.YYYY - HH:mm"}
          value={
            calendarValue.start
              ? [moment(calendarValue.start), moment(calendarValue.end)]
              : undefined
          }
          style={{ ...pickerStyle, width: 360 }}
          picker={calendarType}
          onChange={(a) => {
            if (!a) {
              set_calendarValue({ start: "", end: "" });
              return;
            }
            let _start = a[0]?.toISOString(true);
            let _end = a[1]?.toISOString(true);
            set_calendarValue({ start: _start, end: _end });
            onChange(_start, _end, calendarType);
          }}
        />
      ) : (
        <DatePicker
          allowClear={allowClear}
          style={{ ...pickerStyle, width: 360 }}
          picker={calendarType}
          value={calendarValue.start ? moment(calendarValue.start) : undefined}
          onChange={(a) => {
            let _start = a?.startOf(calendarType).toISOString(true);
            let _end = a?.endOf(calendarType).toISOString(true);
            set_calendarValue({
              start: _start,
              end: _end,
            });
            onChange(_start, _end, calendarType);
          }}
        />
      )}
    </div>
  );
});

export default DatePickerCustom;
