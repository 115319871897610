import { Col, message, Modal, Row, Space, Spin, Table, TableColumnType, Typography } from "antd";
import axios from "axios";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import AnalyticsCard from "../../../../components/Cards/AnalyticsCard";
import PreviewButton from "../../../../components/PreviewButton";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import { parseAnalyticsTime, parseCosts } from "../../../../helpers/functions";
import { IApiResponse, ILoadMoreParams } from "../../../../models";
import AssetModalPreview from "../../../assetdetails/components/AssetModalPreview";
import Filters, { IFilters } from "./components/Filters";
import Loader from "../../components/Loader";
import { useSearchParams } from "react-router-dom";
import LoadMoreButton from "../../../../components/LoadMoreButton";
import { failedQueue, isRefreshing } from "../../../../services";

interface IProps {}

interface IByTypeSpecificAsset {
  asset_id: number;
  custom_id: number;
  name: string;
  asset_path: string | null;
  is_active: boolean;
}

export interface IReportGeneral {
  total_count: number;
  total_downtime: number | null;
  MTTR: number | null;
  MTBF: number | null;
}

export interface IByTypeSpecific {
  halt_times: number | string;
  time: number | string;
  mttr: number | string;
  mtbf: number | string;
  asset: IByTypeSpecificAsset;
}

export interface IReportAssets {
  assets: Array<IByTypeSpecific>;
}

const LIMIT_SIZE = 90;

const Reportone: React.FC<IProps> = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  // Columns
  let COLUMNS: TableColumnType<IByTypeSpecific>[] = [
    {
      dataIndex: "name",
      title: t(TRANSLATION_KEY.asset),
      render: (text: string, value: IByTypeSpecific) => {
        if (!value.asset)
          return <Typography.Text>{t(TRANSLATION_KEY.withoutAsset)}</Typography.Text>;
        return (
          <Space direction="vertical" size={0}>
            <PreviewButton
              isActive={value.asset.is_active}
              title={value.asset.name}
              id={value.asset.asset_id}
              url={`/app/asset-details/${value.asset.asset_id}`}
              containerStyle={{ minWidth: "unset" }}
              permissionsRequired={["view_asset"]}
              onClick={() => (set_selectedAsset(value.asset.asset_id), set_modalVisible(true))}
            />
            {value.asset && (
              <Typography.Text
                type="secondary"
                style={{ maxWidth: 320 }}
                ellipsis={{ tooltip: value.asset.asset_path }}
              >
                {value.asset?.asset_path}
              </Typography.Text>
            )}
          </Space>
        );
      },
    },
    {
      dataIndex: "halt_times",
      title: t(TRANSLATION_KEY.haltTimes),
    },
    {
      dataIndex: "time",
      title: t(TRANSLATION_KEY.downtime),
      render: (text: string, value: IByTypeSpecific) => parseDowntime(text),
    },
    {
      dataIndex: "mttr",
      title: t(TRANSLATION_KEY.MTTR),
      render: (text: number, value: IByTypeSpecific) => parseTime(text || 0),
    },
    {
      dataIndex: "mtbf",
      title: t(TRANSLATION_KEY.MTBF),
      render: (text: number, value: IByTypeSpecific) => parseTime(text || 0),
    },
  ];

  function parseDowntime(value: number | string) {
    return `${Number(value).toFixed(1).toString().replace(/\./g, ",")} h`;
  }

  function parseTime(value: number) {
    const roundedValue = value.toFixed(2);
    const formattedValue = roundedValue.replace(".", ",");
    return `${formattedValue}h`;
  }

  // Variables
  const [modalVisible, set_modalVisible] = useState<boolean>(false);
  const [selectedAsset, set_selectedAsset] = useState<string | number | undefined>(undefined);
  const [generalLoading, set_generalLoading] = useState(false);
  const [generalReport, set_generalReport] = useState<IReportGeneral>({
    MTTR: 0,
    MTBF: 0,
    total_count: 0,
    total_downtime: 0,
  });
  const [loadMoreFilters, setl_loadMoreFilters] = useState<IFilters>({
    assets: [],
    asset_categories: [],
    end: "",
    start: "",
    maintenance_types: [],
    calendarType: "month",
    clients: [],
  });
  const [loadMoreParams, set_loadMoreParams] = useState<ILoadMoreParams>({
    offset: 0,
    limit: LIMIT_SIZE,
  });
  const [loadMoreButtonVisible, set_loadMoreButtonVisible] = useState<boolean>(true);
  const [assetloading, set_assetLoading] = useState<boolean>(false);
  const [firstTime, set_firstTime] = useState<boolean>(true);

  const [reportByAsset, set_reportByAsset] = useState<IReportAssets>({
    assets: [],
  });

  const getGeneral = async (filters: IFilters) => {
    set_generalLoading(true);
    let token = await localStorage.getItem("token");
    let params = {
      assets: filters.assets.join("|"),
      asset_categories: filters.asset_categories.join("|"),
      end: filters.end,
      start: filters.start,
      maintenance_types: filters.maintenance_types.join("|"),
      clients: filters.clients?.join("|") || "",
    };

    let reqOne = await axios.get<IApiResponse<IReportGeneral>>(
      process.env.REACT_APP_HOST_BACKEND + "/api/maintenance/analytics/by_type_general/",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
        params,
      },
    );

    try {
      reqOne.data.results && set_generalReport(reqOne.data.results);
      set_generalLoading(false);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => getGeneral(filters));
        }
        return;
      }
      console.log(error);
      message.error(t(TRANSLATION_KEY.errorOnGetData));
      set_generalLoading(false);
    } finally {
      setSearchParams({ ...params, calendarType: filters.calendarType } as any);
    }
  };

  const getBySpecific = async (filters: IFilters, loadMoreParams: ILoadMoreParams) => {
    set_assetLoading(true);
    if (loadMoreParams.offset === 0) {
      set_loadMoreButtonVisible(true);
    }
    let token = await localStorage.getItem("token");
    let params = {
      assets: filters.assets.join("|"),
      asset_categories: filters.asset_categories.join("|"),
      end: filters.end,
      start: filters.start,
      maintenance_types: filters.maintenance_types.join("|"),
      offset: loadMoreParams.offset,
      limit: loadMoreParams.limit,
      clients: filters.clients?.join("|"),
    };

    axios
      .get<IApiResponse<IReportAssets>>(
        process.env.REACT_APP_HOST_BACKEND + "/api/maintenance/analytics/by_type_specific/",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
          params,
        },
      )
      .then((response) => {
        setl_loadMoreFilters(filters);
        if (!response.data.results) {
          return;
        }
        console.log(response.data.results.assets.length, loadMoreParams.limit);
        if (response.data.results.assets.length < loadMoreParams.limit) {
          set_loadMoreButtonVisible(false);
        }
        set_loadMoreParams({
          offset: loadMoreParams.offset + loadMoreParams.limit,
          limit: loadMoreParams.limit,
        });
        if (loadMoreParams.offset === 0) {
          set_reportByAsset(response.data.results);
        } else {
          set_reportByAsset({
            assets: [...reportByAsset.assets, ...response.data.results.assets],
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          if (isRefreshing) {
            failedQueue.push(() => getBySpecific(filters, loadMoreParams));
          }
          return;
        }
        console.log(error);
        message.error(t(TRANSLATION_KEY.errorOnGetData));
      })
      .finally(() => {
        set_assetLoading(false);
      });
  };

  return (
    <>
      {/* Spinner */}
      <Loader isLoading={generalLoading} />

      {/* Filters */}
      <Filters
        onChange={(f) => {
          getGeneral(f);
          getBySpecific(f, {
            ...loadMoreParams,
            offset: 0,
          });
        }}
      />

      {/* Cards */}
      <Row gutter={[24, 24]} style={{ marginTop: 24, marginBottom: 24 }}>
        <Col xs={24} md={12}>
          <AnalyticsCard
            label={t(TRANSLATION_KEY.numberOfStops)}
            value={generalReport.total_count}
          />
        </Col>
        <Col xs={24} md={12}>
          <AnalyticsCard
            label={t(TRANSLATION_KEY.downtime)}
            value={parseAnalyticsTime(generalReport.total_downtime || 0, 2)}
          />
        </Col>
        <Col xs={24} md={12}>
          <AnalyticsCard
            label={t(TRANSLATION_KEY.MTTR)}
            value={parseAnalyticsTime(generalReport.MTTR || 0, 2)}
          />
        </Col>
        <Col xs={24} md={12}>
          <AnalyticsCard
            label={t(TRANSLATION_KEY.MTBF)}
            value={parseAnalyticsTime(generalReport.MTBF || 0, 2)}
          />
        </Col>
      </Row>

      <div>
        <Table dataSource={reportByAsset.assets} pagination={false} columns={COLUMNS} />
        {loadMoreButtonVisible && (
          <LoadMoreButton
            loading={assetloading}
            onClick={() => {
              getBySpecific(loadMoreFilters, loadMoreParams);
            }}
          >
            {t(TRANSLATION_KEY.loadMore)}
          </LoadMoreButton>
        )}
      </div>

      {/* Asset preview */}
      <Modal
        visible={modalVisible}
        onCancel={() => set_modalVisible(false)}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        <AssetModalPreview id={selectedAsset} />
      </Modal>
    </>
  );
};

export default Reportone;
