import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { getAssetXHR } from "../../../../../store/reducers/asstes/actionCreators";
import { DatePicker, Select, Spin, TreeSelect, message } from "antd";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";
import moment from "moment";
import { getPartsCategoriesXHR } from "../../../../../store/reducers/warehouse/actionCreator";
import { createTreeSelect } from "../../../../../helpers/functions";
import DatePickerCustom, { CalendarTypes } from "../../../../../components/DatePickerCustom";

interface IProps {
  onChange: (filters: IFilters) => void;
}

export interface IFilters {
  calendarType: CalendarTypes;
  start: string | undefined;
  end: string | undefined;
  assets: number[];
  part_categories: number[];
}

const FiltersWithCustomDatePicker = ({ onChange }: IProps) => {
  // Hooks
  const dispatch = useAppDispatch();

  // Variables
  const { assetList, getAssetStatus } = useAppSelector((state) => state.assetReducer);
  const { categories, getPartsCategoriesStatus } = useAppSelector(
    (state) => state.warehouseReducer,
  );
  const { user } = useAppSelector((state) => state.userReducer);
  const [filters, set_filters] = useState<IFilters>();

  // Methods
  async function setFilters() {
    let filters: string | null = await localStorage.getItem(
      window.location.pathname + `|${user.account.id}|${user.account.name}`,
    );
    if (!filters) {
      set_filters({
        calendarType: "month",
        start: moment().startOf("month").toISOString(true),
        end: moment().endOf("month").toISOString(true),
        assets: [],
        part_categories: [],
      });
    } else {
      let tmp: IFilters = JSON.parse(filters);
      set_filters(tmp);
    }
  }

  useEffect(() => {
    if (assetList.length === 0) {
      getAssetXHR(
        {
          errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        },
        dispatch,
      );
    }
    if (categories.length === 0) {
      getPartsCategoriesXHR(
        {
          errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        },
        dispatch,
      );
    }
    setFilters();
  }, []);

  useEffect(() => {
    if (filters) {
      localStorage.setItem(
        window.location.pathname + `|${user.account.id}|${user.account.name}`,
        JSON.stringify(filters),
      );
      onChange(filters);
    }
  }, [filters]);

  let tree = createTreeSelect(
    assetList
      .filter((item) => item.type === "wrh")
      .map((x) => ({
        title: x.name,
        value: x.id.toString(),
        parent_id: x.parent_id?.toString() || null,
        disabled: false,
      })),
    null,
  );

  let content = filters && (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        padding: "12px",
        background: "white",
        justifyContent: "stretch",
        borderRadius: 4,
        gap: 12,
        border: "1px solid #dedede",
      }}
    >
      <Select
        onChange={(e) =>
          set_filters({
            ...filters,
            part_categories: e,
          })
        }
        defaultValue={filters.part_categories}
        onClear={() => set_filters({ ...filters, part_categories: [] })}
        placeholder={t(TRANSLATION_KEY.partsCategories)}
        style={{ overflow: "auto", width: "16%", maxWidth: 224 }}
        allowClear={true}
        mode="multiple"
        maxTagCount="responsive"
        showSearch
        optionFilterProp="name"
        filterOption={(input, option: any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {categories.map((item, index) => (
          <Select.Option key={index} value={item.id}>
            {t(item.name)}
          </Select.Option>
        ))}
      </Select>
      <TreeSelect
        allowClear
        onClear={() => set_filters({ ...filters, assets: [] })}
        maxTagCount="responsive"
        defaultValue={filters.assets}
        placeholder={t(TRANSLATION_KEY.selectWarehouse)}
        style={{ width: 224 }}
        treeCheckable
        showCheckedStrategy={TreeSelect.SHOW_ALL}
        treeCheckStrictly={true}
        treeDefaultExpandAll={true}
        filterTreeNode={(search: string, item: any) => {
          return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
        }}
        onDeselect={(e) => {
          let tmp = [...filters.assets];
          let index = tmp.findIndex((x) => x === e);
          if (index > -1) {
            tmp.splice(index, 1);
          }
          set_filters({
            ...filters,
            assets: tmp,
          });
        }}
        onSelect={(e) => {
          let tmp = [...filters.assets];
          tmp.push(e);
          set_filters({
            ...filters,
            assets: tmp,
          });
        }}
        multiple
        treeData={tree}
      />
      <div style={{ flex: 1 }} />
      <DatePickerCustom
        selectStyle={{ marginRight: 12 }}
        pickerStyle={{ maxWidth: 224 }}
        start={filters.start}
        end={filters.end}
        type="month"
        skipInitial
        onChange={(start, end, type) => {
          set_filters({
            ...filters,
            start,
            end,
            calendarType: type || "month",
          });
        }}
      />
    </div>
  );

  return (
    <Spin spinning={getAssetStatus === "loading" || getPartsCategoriesStatus === "loading"}>
      {content}
    </Spin>
  );
};

export default FiltersWithCustomDatePicker;
