import * as Sentry from "@sentry/react";

Sentry.init({
  environment: process.env.REACT_APP_ENVIRONMENT,
  dsn: "https://05eab2c6060266ef3a974eb239ccc479@o4508290447376384.ingest.de.sentry.io/4508291064987728",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/app\.development\.metrikon\.io\/api/,
    /^https:\/\/app\.staging\.metrikon\.io\/api/,
    /^https:\/\/app\.metrikon\.io\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export default Sentry;
