import { message, Select, Spin, TreeSelect } from "antd";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import moment from "moment";
import { getAssetXHR } from "../../../../../store/reducers/asstes/actionCreators";
import { createTreeSelect } from "../../../../../helpers/functions";
import DatePickerCustom, { CalendarTypes } from "../../../../../components/DatePickerCustom";
import { useSearchParams } from "react-router-dom";
import { IAccount } from "../../../../../models/user";
import AssetTreeSelect, {
  TREE_SELECT_LOADING,
} from "../../../../../componentsform/AssetTreeSelect";
import ClientsSelect from "../../../../../componentsform/ClientsSelect";

interface IProps {
  onChange: (filters: IFilters) => void;
}

export interface IFilters {
  calendarType: CalendarTypes;
  start: string | undefined;
  end: string | undefined;
  asset_categories: number[];
  maintenance_types: number[];
  assets: number[];
  clients: number[] | undefined;
}

const Filters: React.FC<IProps> = ({ onChange }) => {
  const { categories, getAssetStatus } = useAppSelector((state) => state.assetReducer);
  const { maintenancesTypes } = useAppSelector((state) => state.maintenanceReducer);
  const { user } = useAppSelector((state) => state.userReducer);
  let [searchParams, setSearchParams] = useSearchParams();
  const [localAccounts, set_localAccounts] = useState<IAccount[]>([]);
  const [calendarVisible, set_calendarVisible] = useState(false);
  const [firstRender, set_firstRender] = useState(true);
  const dispatch = useAppDispatch();

  const [filters, set_filters] = useState<IFilters>({
    // here i want take params from url and set to filters
    calendarType: (searchParams.get("calendarType") as CalendarTypes) || "month",
    start: searchParams.get("start") || moment().startOf("month").toISOString(true),
    end: searchParams.get("end") || moment().endOf("month").toISOString(true),
    asset_categories:
      searchParams
        .get("asset_categories")
        ?.split("|")
        .map((x) => +x) || [],
    maintenance_types:
      searchParams
        .get("maintenance_types")
        ?.split("|")
        .map((x) => +x) || [],
    assets:
      searchParams
        .get("assets")
        ?.split("|")
        .map((x) => +x) || [],
    clients:
      searchParams
        .get("clients")
        ?.split("|")
        .map((x) => +x) || [],
  });
  useEffect(() => {
    setFilters();
    setTimeout(() => {
      set_calendarVisible(true);
    }, 210);
  }, []);

  const setFilters = async () => {
    let filters: string | null = await localStorage.getItem(
      window.location.pathname + `|${user.account.id}|${user.account.name}`,
    );

    if (!filters) {
      set_filters({
        calendarType: "month",
        start: moment().startOf("month").toISOString(true),
        end: moment().endOf("month").toISOString(true),
        asset_categories: [],
        maintenance_types: [],
        assets: [],
        clients: [],
      });
    } else {
      let tmp: IFilters = JSON.parse(filters);

      set_filters(tmp);
    }
  };

  useEffect(() => {
    if (filters && !firstRender) {
      localStorage.setItem(
        window.location.pathname + `|${user.account.id}|${user.account.name}`,
        JSON.stringify(filters),
      );
      onChange(filters);
    }
    set_firstRender(false);
  }, [filters]);

  let hasClients = user?.account?.company?.modules?.filter((x) => x.name === "clients").length > 0;

  let content = filters && (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        padding: "12px",
        background: "white",
        justifyContent: "stretch",
        borderRadius: 4,
        gap: 12,
        border: "1px solid #dedede",
      }}
    >
      <Select
        onChange={(e) => {
          set_filters({
            ...filters,
            asset_categories: e,
          });
        }}
        value={filters.asset_categories}
        onClear={() => {
          set_filters({ ...filters, asset_categories: [] });
        }}
        placeholder={t(TRANSLATION_KEY.assetCategory)}
        style={{ overflow: "auto", width: "16%", maxWidth: 224 }}
        allowClear={true}
        mode="multiple"
        maxTagCount="responsive"
        showSearch
        optionFilterProp="name"
        filterOption={(input, option: any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {categories.map((item, index) => (
          <Select.Option key={index} value={item.id}>
            {t(item.trans_key || item.name)}
          </Select.Option>
        ))}
      </Select>
      <Select
        onChange={(e) => {
          set_filters({
            ...filters,
            maintenance_types: e,
          });
        }}
        value={filters.maintenance_types}
        onClear={() => {
          set_filters({ ...filters, maintenance_types: [] });
        }}
        placeholder={t(TRANSLATION_KEY.maintenanceTypes)}
        style={{ overflow: "auto", width: "16%", maxWidth: 224 }}
        allowClear={true}
        mode="multiple"
        maxTagCount="responsive"
        showSearch
        optionFilterProp="name"
        filterOption={(input, option: any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {maintenancesTypes.map((item, index) => (
          <Select.Option key={index} value={item.id}>
            {t(item.trans_key || item.name)}
          </Select.Option>
        ))}
      </Select>
      <AssetTreeSelect
        includeWithoutAsset={true}
        onChange={(e) => {
          if (Array.isArray(e)) {
            set_filters({
              ...filters,
              assets: e,
            });
          }
        }}
        treeProps={{
          allowClear: true,
          maxTagCount: "responsive",
          style: { width: 224 },
          value: filters.assets,
          placeholder: t(TRANSLATION_KEY.selectAssets),
          treeCheckable: true,
          showCheckedStrategy: TreeSelect.SHOW_ALL,
          treeCheckStrictly: true,
          treeDefaultExpandAll: true,
          multiple: true,
        }}
      />
      {hasClients && (
        <>
          <ClientsSelect
            includeArchived={true}
            includeInactive={true}
            placeholder={t(TRANSLATION_KEY.clients)}
            style={{ width: 240 }}
            mode="multiple"
            value={filters.clients}
            onChange={(a) => {
              if (!Array.isArray(a)) return;

              set_filters({ ...filters, clients: a });
            }}
            name=""
            label=""
            form={undefined}
          />
        </>
      )}
      <div style={{ flex: 1 }} />
      {calendarVisible && (
        <DatePickerCustom
          selectStyle={{ marginRight: 12 }}
          pickerStyle={{ maxWidth: 224 }}
          start={filters.start}
          end={filters.end}
          type={filters.calendarType}
          skipInitial
          onChange={(start, end, type) => {
            set_filters({
              ...filters,
              start,
              end,
              calendarType: type || "month",
            });
          }}
        />
      )}
    </div>
  );

  return content;
};

export default Filters;
