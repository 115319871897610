import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Empty,
  Form,
  Input,
  Modal,
  Popconfirm,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import { t } from "i18next";
import React, { useState } from "react";
import { TRANSLATION_KEY } from "../../../../../../helpers/consts";
import { ITableColumn } from "../../../../../../models";
import { AddBankAccount, BankAccount } from "../../../../../../models/finance";
import BankAccountForm from "./Form";
import useBankAccounts from "./useBankAccounts";

type Props = {};

const BankAccounts = (props: Props) => {
  // Hooks
  const bankAccounts = useBankAccounts();
  const [form] = Form.useForm<AddBankAccount>();

  // State
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<BankAccount | undefined>(undefined);

  // Effects
  React.useEffect(() => {
    bankAccounts.fetchData();
  }, []);

  // Functions
  function onClose() {
    setModalVisible(false);
    setSelectedRow(undefined);
  }

  // Columns
  const columns: ITableColumn<BankAccount>[] = [
    {
      title: t(TRANSLATION_KEY.bankName),
      dataIndex: "bank_name",
      visible: true,
    },
    {
      title: t(TRANSLATION_KEY.accountOwner),
      dataIndex: "owner",
      visible: true,
    },
    {
      title: (
        <Typography.Text style={{ textTransform: "uppercase" }}>
          {t(TRANSLATION_KEY.iban)}
        </Typography.Text>
      ) as any,
      dataIndex: "iban",
      visible: true,
    },
    {
      title: (
        <Typography.Text style={{ textTransform: "uppercase" }}>
          {t(TRANSLATION_KEY.bic)}
        </Typography.Text>
      ) as any,
      dataIndex: "bic",
      visible: true,
    },
    {
      title: "",
      dataIndex: "actions",
      visible: true,
      width: "100px",
      render: (value, record) => {
        return (
          <Space size={8}>
            <Button
              type="link"
              onClick={() => {
                setModalVisible(true);
                setSelectedRow(record);
              }}
              icon={<EditOutlined />}
            />
            <Popconfirm
              onConfirm={() => bankAccounts.delete(record.id)}
              title={t(TRANSLATION_KEY.continueWithAction)}
              cancelText={t(TRANSLATION_KEY.no)}
              okText={t(TRANSLATION_KEY.yes)}
              placement="top"
            >
              <Button
                type="link"
                loading={bankAccounts.deleteLoading === record.id}
                danger
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <div style={{ paddingBottom: 20, position: "relative" }}>
      {/* Title */}
      <div className="spaceBetweenRow">
        {/* Title */}
        <Typography.Title level={5} style={{ paddingTop: 10 }}>
          {t(TRANSLATION_KEY.bankAccounts)}
        </Typography.Title>
        {/* CTA-s */}
        <Space size={16}>
          <Input.Search
            placeholder={t(TRANSLATION_KEY.searchByName)}
            onChange={({ target: { value } }) => bankAccounts.search(value)}
            allowClear
          />
          <Button
            onClick={() => {
              setModalVisible(true);
            }}
            shape="circle"
            icon={<PlusOutlined />}
            type="primary"
            style={{ marginLeft: 8 }}
          />
        </Space>
      </div>

      {/* Divider */}
      <Divider style={{ marginTop: 12, marginBottom: 12 }} />

      {/* Table */}
      {bankAccounts.data.length > 0 ? (
        <Spin spinning={bankAccounts.loading}>
          <Table
            columns={columns}
            dataSource={bankAccounts.data}
            bordered={false}
            pagination={{
              pageSize: bankAccounts.filters.limit,
              total: bankAccounts.total,
              showSizeChanger: false,
              onChange: bankAccounts.onPageChange,
            }}
          />
        </Spin>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}

      {/* Form */}
      <Modal
        destroyOnClose={true}
        title={t(TRANSLATION_KEY.newBankAccount)}
        okButtonProps={{ htmlType: "button" }}
        okText={t(TRANSLATION_KEY.save)}
        cancelText={t(TRANSLATION_KEY.cancel)}
        visible={modalVisible}
        onCancel={onClose}
        onOk={() => form.submit()}
        centered
      >
        <BankAccountForm form={form} close={onClose} bankAccount={selectedRow} />
      </Modal>
    </div>
  );
};

export default BankAccounts;
