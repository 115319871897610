import { Drawer } from "antd";
import { t } from "i18next";
import { useDrawerToggle } from "..";
import InvoicesTable from "../../../components/InvoicesTable";
import InvoiceForm from "../../../componentsform/InvoiceForm";
import { TRANSLATION_KEY } from "../../../helpers/consts";

type Props = {};

const Invoices = (props: Props) => {
  // Hooks
  const { addNew, set_addNew } = useDrawerToggle();

  return (
    <>
      <InvoicesTable />

      <Drawer
        title={t(TRANSLATION_KEY.newInvoice)}
        width={540}
        destroyOnClose={true}
        children={
          addNew?.invoice ? (
            <InvoiceForm invoice={addNew.invoice} close={() => set_addNew(undefined)} />
          ) : null
        }
        visible={!!addNew}
        onClose={() => set_addNew(undefined)}
      />
    </>
  );
};

export default Invoices;
