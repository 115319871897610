import React, { useEffect, useState } from "react";

// Antd
import {
  Button,
  DatePicker,
  Input,
  message,
  Modal,
  Popconfirm,
  Space,
  Table,
  TableColumnType,
  Tooltip,
  TreeSelect,
  Typography,
} from "antd";
import styles from "./styles.module.css";

// Rest
import { t } from "i18next";
import { useLocation, useParams } from "react-router-dom";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { ICursor, ITableColumn } from "../../../models";
import { IPartSpend } from "../../../models/parts";
import { createTreeSelect, formatNumber, parseCosts } from "../../../helpers/functions";
import LoadMoreButton from "../../../components/LoadMoreButton";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks";
import AccountModalPreview from "../../account/components/AccountModalPreview";
import AssetModalPreview from "../../assetdetails/components/AssetModalPreview";
import {
  confirmPartSpendXHR,
  removePartSpendXHR,
  retrievePartXHR,
} from "../../../store/reducers/warehouse/actionCreator";
import { warehouseSlice } from "../../../store/reducers/warehouse";
import PreviewButton from "../../../components/PreviewButton";
import DatePickerCustom, { CalendarTypes } from "../../../components/DatePickerCustom";
import MaintenanceModalPreview from "../../maintenance/components/ModalPreview";
import { ILocalFilters } from "../pages/SpendHistory";
import { getAssetXHR } from "../../../store/reducers/asstes/actionCreators";
import ViewTabs, {
  ColumnFilterType,
  CustomTableColumn,
  customTableColumnRender,
  filterColumns,
  getFilterComponent,
  saveTableDefinition,
} from "../../../components/ViewTabs";
import { TableView } from "../../../models/user";
import Columns from "../../../components/ViewTabs/Columns";
import { store } from "../../../store";

export interface IRemovePartSpend {
  spend_part: number;
}

export interface IConfirmPartSpend {
  spend_part: number;
  confirm: boolean;
}

// Props
interface IProps {
  data: IPartSpend[];
  cursor: ICursor;
  loading: boolean;
  filters: ILocalFilters;
  setFilters: (f: ILocalFilters) => void;
  getPartSpendList: (filters: ILocalFilters) => void;
  onCalendarChange: (
    start: string | undefined,
    end: string | undefined,
    type: CalendarTypes | undefined,
  ) => void;
  onLoadMore: () => void;
  onSearch: (search: string) => void;
}
type TableRenders =
  | "SpendNote"
  | "SpendQty"
  | "SpendPrice"
  | "SpendCreatedBy"
  | "SpendOrderNumber"
  | "SpendLocation"
  | "SpendAction";
const SpendHistoryTable: React.FC<IProps> = ({
  data,
  loading,
  filters,
  setFilters,
  onCalendarChange,
  onLoadMore,
  getPartSpendList,
  cursor,
}) => {
  const [selectedMaintenance, set_selectedMaintenance] = useState<number>();
  const { id } = useParams();
  const { user } = useAppSelector((state) => state.userReducer);
  const { assetList, getAssetStatus } = useAppSelector((state) => state.assetReducer);
  // Hooks
  const location = useLocation();
  const dispatch = useDispatch();

  let _c: ITableColumn<IPartSpend>[] = [
    {
      title: t(TRANSLATION_KEY.note),
      dataIndex: "note",
      visible: true,
    },
    {
      title: t(TRANSLATION_KEY.qty),
      dataIndex: "qty",
      visible: true,
      render: (text: string, value: IPartSpend) => <span>{formatNumber(value.qty)}</span>,
    },
    {
      title: t(TRANSLATION_KEY.price),
      dataIndex: "price",
      visible: true,
      render: (text: string, value: IPartSpend) => (
        <span>{`${parseCosts(value.total_price.toString())} ${value.total_price_uom}`}</span>
      ),
    },
    {
      title: t(TRANSLATION_KEY.createdBy),
      dataIndex: "created_by",
      visible: true,
      render: (text: string, value: IPartSpend) => (
        <>
          <PreviewButton
            isActive={value.created_by.is_active}
            title={value.created_by.name}
            id={value.created_by.id}
            url={`/app/humanresources/${value.created_by.id}`}
            permissionsRequired={["manage_user"]}
            onClick={() => {
              set_modalVisible(true);
              set_selectedAccount(value.created_by.id);
            }}
          />
          <Typography.Text type="secondary">
            {moment(value.created_at).format(user.account.date_format || undefined)}
          </Typography.Text>
        </>
      ),
    },
    {
      title: t(TRANSLATION_KEY.orderNumber),
      dataIndex: "order_number",
      visible: true,
      render: (text: string, value: IPartSpend) => (
        <PreviewButton
          isActive={value.order_info?.is_active}
          title={value.order_info?.order_number}
          id={value.order_info?.id}
          url={`/app/maintenances/${value.order_info?.id}/`}
          onClick={() => {
            set_selectedMaintenance(value.order_info?.id);
          }}
        />
      ),
    },
    {
      title: t(TRANSLATION_KEY.location),
      dataIndex: "location",
      visible: true,
      render: (text: string, value: IPartSpend) => (
        <PreviewButton
          isActive={value.storage.is_active}
          title={value.storage.name}
          id={value.storage.id}
          url={`/app/asset-details/${value.storage.id}`}
          permissionsRequired={["view_asset"]}
          onClick={() => {
            set_modalVisible(true);
            set_selectedAsset(value.storage.id);
          }}
        />
      ),
    },
    {
      title: "",
      dataIndex: "action",
      visible: "always",
      render: (text: string, value: IPartSpend) => (
        <Space size="middle">
          <Popconfirm
            onConfirm={() => {
              handleRemovePartSpend(value.id);
            }}
            title={t(TRANSLATION_KEY.continueWithAction)}
            cancelText={t(TRANSLATION_KEY.no)}
            okText={t(TRANSLATION_KEY.yes)}
            placement="top"
          >
            <Button type="link" danger>
              {t(TRANSLATION_KEY.remove)}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // Variables
  const [modalVisible, set_modalVisible] = useState<boolean>(false);
  const [selectedAsset, set_selectedAsset] = useState<string | number | undefined>(undefined);
  const [selectedAccount, set_selectedAccount] = useState<string | number | undefined>(undefined);
  const [firstMount, set_firstMount] = useState<boolean>(true);
  const [COLUMNS, set_COLUMNS] = React.useState<
    Array<Partial<CustomTableColumn> & TableColumnType<IPartSpend>>
  >([]);
  const [tableDefinition, set_tableDefinition] = useState<
    Array<
      TableView & {
        updated: number;
        active: boolean;
      }
    >
  >(
    user.account.views?.spend?.map((x, i) => ({
      ...x,
      updated: new Date().getTime(),
      active: i === 0,
    })) || [],
  );

  useEffect(() => {
    if (firstMount) {
      set_firstMount(false);
      return;
    }
    let tmpDefinitions =
      user.account.views?.spend?.map((x, i) => ({
        ...x,
        updated: new Date().getTime(),
        active: i === 0,
      })) || [];
    set_tableDefinition(tmpDefinitions);
  }, [user]);
  useEffect(() => {
    set_COLUMNS([...COLUMNS]);
  }, [data]);

  // Methods
  function handleRemovePartSpend(spend_part: number) {
    removePartSpendXHR(
      {
        body: { spend_part },
        errorCallback: (data) => {
          console.log(data);
          message.error(t(TRANSLATION_KEY.errorOnGetData));
        },
        successCallback: ({ results }) => {
          retrievePartXHR({ id, loading: "refresh" }, dispatch);
          let arr: IPartSpend[] = [...store.getState().warehouseReducer.partSpendList.data];
          let index = arr.findIndex((item) => item.id === spend_part);
          // Remove part spend
          arr.splice(index, 1);
          // Update store
          dispatch(warehouseSlice.actions.updatePartSpendList(arr));
          // message.success(t(TRANSLATION_KEY.successOnSaveData));
        },
      },
      dispatch,
    );
  }

  useEffect(() => {
    let activeView = tableDefinition.find((x) => x.active);
    if (activeView) {
      setColumns(activeView.table_structure, 0);
    }
    getAssetXHR(
      { errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)) },
      dispatch,
    );
  }, []);

  let warehouse = assetList.filter((x) => x.type === "wrh");

  warehouse = warehouse.map((x) => ({
    ...x,
    parent_id: x.parent_id
      ? warehouse.some((y) => y.id === x.parent_id)
        ? x.parent_id
        : null
      : null,
  }));

  let selectTreeData = createTreeSelect(
    warehouse.map((x) => ({
      title: x.name,
      value: x.id.toString(),
      parent_id: x.parent_id?.toString() || null,
      disabled: false,
    })),
    null,
  );

  const render = (key: TableRenders) => {
    let tmp: Record<TableRenders, any> = {
      SpendPrice: (text: string, value: IPartSpend) => (
        <span>{`${parseCosts(value.total_price.toString())} ${value.total_price_uom}`}</span>
      ),
      SpendNote: (text: string, value: IPartSpend) => (
        <Typography.Text>{value.note}</Typography.Text>
      ),
      SpendLocation: (text: string, value: IPartSpend) => (
        <PreviewButton
          isActive={value.storage.is_active}
          title={value.storage.name}
          id={value.storage.id}
          url={`/app/asset-details/${value.storage.id}`}
          permissionsRequired={["view_asset"]}
          onClick={() => {
            set_modalVisible(true);
            set_selectedAsset(value.storage.id);
          }}
        />
      ),
      SpendQty: (text: string, value: IPartSpend) => <span>{formatNumber(value.qty)}</span>,
      SpendCreatedBy: (text: string, value: IPartSpend) => (
        <>
          <PreviewButton
            isActive={value.created_by.is_active}
            title={value.created_by.name}
            id={value.created_by.id}
            url={`/app/humanresources/${value.created_by.id}`}
            permissionsRequired={["manage_user"]}
            onClick={() => {
              set_modalVisible(true);
              set_selectedAccount(value.created_by.id);
            }}
          />
          <Typography.Text type="secondary">
            {moment(value.created_at).format(user.account.date_format || undefined)}
          </Typography.Text>
        </>
      ),
      SpendOrderNumber: (text: string, value: IPartSpend) => (
        <PreviewButton
          isActive={value.order_info?.is_active}
          title={value.order_info?.order_number}
          id={value.order_info?.id}
          url={`/app/maintenances/${value.order_info?.id}/`}
          onClick={() => {
            set_selectedMaintenance(value.order_info?.id);
          }}
        />
      ),
      SpendAction: (text: string, value: IPartSpend) => (
        <Space size="middle">
          <Popconfirm
            onConfirm={() => {
              handleRemovePartSpend(value.id);
            }}
            title={t(TRANSLATION_KEY.continueWithAction)}
            cancelText={t(TRANSLATION_KEY.no)}
            okText={t(TRANSLATION_KEY.yes)}
            placement="top"
          >
            <Button type="link" danger>
              {t(TRANSLATION_KEY.remove)}
            </Button>
          </Popconfirm>
        </Space>
      ),
    };
    return tmp[key];
  };

  const setColumns = (_c: CustomTableColumn[], viewIndex: number) => {
    // ostaje unutar komponente za koju se prave views-ovi iz razloga jer će trebati filtere okidati
    let tmp: Array<CustomTableColumn & TableColumnType<IPartSpend>> = [];
    _c.forEach((c) => {
      tmp.push({
        ...c,
        title: t(c.title),
        render: c.columnRenderComponent
          ? render(c.columnRenderComponent as TableRenders)
          : customTableColumnRender(
              c.filterComponent as ColumnFilterType,
              c.dataIndex,
              user,
              "custom_fields_v2",
            ),
        onCell: (record: IPartSpend) => {
          return { rowSpan: 1 };
        },
        filterDropdown: undefined,
      });
    });
    set_COLUMNS(tmp);
  };

  return (
    <>
      <div>
        <ViewTabs
          hideDivider={true}
          onChange={(activeKey) => {
            let index = tableDefinition.findIndex((view) => view.name === activeKey);
            let tmpList = [...tableDefinition];
            if (index === -1) {
              message.error("view_onSaveColumnsDefinition");
              return;
            }
            tmpList = tmpList.map((x) => ({ ...x, active: false }));
            tmpList[index] = { ...tmpList[index], active: true };
            getPartSpendList(filters);
            setColumns(tmpList[index].table_structure, index);
            set_tableDefinition(tmpList);
          }}
          setColumns={setColumns}
          views={tableDefinition}
          viewCategory="spend"
        />
      </div>
      {/* Header */}
      <div className={styles.headerContainer}>
        {/* Columns */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {!firstMount && (
            <Columns
              columns={COLUMNS}
              set_COLUMNS={(columns) => {
                let index = tableDefinition.findIndex((view) => view.active === true);
                if (index === -1) {
                  message.error("view");
                  return;
                }
                let tmp = tableDefinition[index];
                if (!tmp) {
                  return;
                }

                let inerTmp: CustomTableColumn[] = [];
                columns.forEach((x) => {
                  inerTmp.push({
                    title: x.title || "",
                    dataIndex: x.dataIndex || "",
                    visible: x.visible === undefined ? 1 : x.visible,
                    onCellFlag: x.onCellFlag || false,
                    filterComponent: x.filterComponent || null,
                    objectKey: x.objectKey || null,
                    columnRenderComponent: x.columnRenderComponent || null,
                  });
                });
                tmp = { ...tmp, table_structure: inerTmp, updated: Date.now(), active: true };
                let tmpTableDefinition = [...tableDefinition].map((x) => ({ ...x, active: false }));
                tmpTableDefinition[index] = tmp;
                setColumns(inerTmp, index);
                set_tableDefinition(tmpTableDefinition);
              }}
            />
          )}
          <Button
            style={{ marginLeft: 18 }}
            type="link"
            onClick={() => {
              let activeView = tableDefinition.find((x) => x.active);
              saveTableDefinition(
                tableDefinition,
                user.account.views?.spend?.find((x) => x.id === activeView?.id),
                "spend",
              );
            }}
          >
            {t(TRANSLATION_KEY.saveChanges)}
          </Button>
        </div>

        <div className={styles.headerFlexSpaceBetween}>
          <div className={styles.headerFlexSpaceBetween}>
            <DatePickerCustom
              wraperStyle={{ display: "flex" }}
              pickerStyle={{ marginRight: 12, minWidth: 270 }}
              selectStyle={{ marginRight: 12 }}
              type="month"
              end={moment().endOf("month").toISOString(true)}
              start={moment().startOf("month").toISOString(true)}
              skipInitial={false}
              onChange={onCalendarChange}
            />
            {/* Date picker */}

            <TreeSelect
              onClear={() => {
                getPartSpendList({ ...filters, warehouse: [] });
              }}
              onSelect={(a: unknown) => {
                let tmpArr = [...filters.warehouse];
                if (typeof a === "string") {
                  tmpArr.push(a);
                }

                getPartSpendList({ ...filters, warehouse: tmpArr });
              }}
              onDeselect={(a: unknown) => {
                let tmpArr = [...filters.warehouse];
                let index = tmpArr.findIndex((x) => x === a);
                if (index !== -1) {
                  tmpArr.splice(index, 1);
                }
                getPartSpendList({ ...filters, warehouse: tmpArr });
              }}
              multiple
              allowClear
              placeholder={t(TRANSLATION_KEY.selectWarehouse)}
              style={{ minWidth: 240, marginRight: 12 }}
              treeData={selectTreeData}
            />
          </div>
        </div>
      </div>

      {/* Table */}
      <Table
        loading={loading || getAssetStatus === "loading"}
        size="small"
        dataSource={data}
        columns={filterColumns<IPartSpend>(COLUMNS)}
        rowKey={(item: IPartSpend) => item.id}
        pagination={false}
      />

      {/* Load more button */}
      {cursor.next && <LoadMoreButton loading={loading} onClick={onLoadMore} />}

      {/* Modal */}
      <Modal
        visible={modalVisible || !!selectedMaintenance}
        onCancel={() => {
          set_modalVisible(false);
          set_selectedAccount(undefined);
          set_selectedAsset(undefined);
          set_selectedMaintenance(undefined);
        }}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        {selectedAccount && <AccountModalPreview id={selectedAccount} />}
        {selectedAsset && <AssetModalPreview id={selectedAsset} />}
        {!!selectedMaintenance && <MaintenanceModalPreview id={selectedMaintenance} />}
      </Modal>
    </>
  );
};

export default SpendHistoryTable;
