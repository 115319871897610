import { Button, DatePicker, Form, InputNumber, Radio, Select } from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useState } from "react";
import useInvoices from "../components/InvoicesTable/useInvoices";
import { TRANSLATION_KEY } from "../helpers/consts";
import { useAppSelector } from "../hooks";
import { AddInvoice, Invoice } from "../models/finance";
import ClientsSelect from "./ClientsSelect";
import CloseInvoiceForm from "./CloseInvoiceForm";
import RentOrderSelect from "./RentOrderSelect";

type Props = {
  invoice: Invoice | undefined;
  close: () => void;
};

type DaysToDueType = "date" | "days";

const InvoiceForm = ({ close, invoice }: Props) => {
  // Refs
  const clientsSelectRef = React.useRef<React.ElementRef<typeof ClientsSelect>>(null);

  // Hooks
  const { add, update } = useInvoices();
  const [form] = Form.useForm();

  // State
  const [showTransactionFields, setShowTransactionFields] = useState<boolean>(false);
  const [daysToDue, setDaysToDue] = useState<DaysToDueType>("date");
  const { user } = useAppSelector((state) => state.userReducer);
  const { currencies } = useAppSelector((state) => state.settingsReducer);
  const format = user.account?.date_format || "DD.MM.YYYY - HH:mm";
  let isUpdating = !!invoice?.id;

  // Functions
  const onFinish = (values: FormValues) => {
    let { days_to_due__date, days_to_due__days, ...rest } = values;
    let body: AddInvoice = {
      ...rest,
      days_to_due: calculateDaysToDue(days_to_due__date, days_to_due__days),
    };
    if (isUpdating && invoice && invoice.id) {
      update(body, invoice.id, close);
    } else {
      add(body, close);
    }
  };

  function calculateDaysToDue(date: string, days: string): number {
    // Calculate days from today to due date
    if (daysToDue === "date") {
      let dueDate = moment(date);
      let today = moment();
      return dueDate.diff(today, "days") + 1; // +1 because we want to include today
    }
    // Calculate days to due from days
    else {
      return parseInt(days);
    }
  }

  // Initial values
  const initialValues: Partial<FormValues> = {
    client: invoice?.rent_order?.client?.id || undefined,
    currency: invoice?.currency || currencies[0],
    price: invoice && invoice.price ? Number(invoice.price) : undefined,
    rent_order: invoice?.rent_order?.id || undefined,
    //? Local form variables
    // @ts-ignore
    days_to_due: "date",
    days_to_due__date: invoice?.due_date ? moment(invoice.due_date) : (moment() as any),
    // Calculate number of days from today to due date
    days_to_due__days: invoice?.due_date
      ? moment(invoice.due_date).diff(moment(), "days") + 1
      : (1 as any),
    //? Local form variables
  };

  return (
    <div style={{ width: "100%" }}>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        size="large"
        style={{ width: "100%" }}
        initialValues={initialValues}
      >
        {/* Client */}
        <ClientsSelect
          includeArchived={false}
          includeInactive={false}
          ref={clientsSelectRef}
          style={undefined}
          mode={undefined}
          value={form.getFieldValue("client")}
          label={t(TRANSLATION_KEY.client)}
          name="client"
          form={undefined}
          onChange={() => {}}
          required
        />

        {/* Rent order */}
        <RentOrderSelect
          selectProps={{ style: { width: "100%" } }}
          form={form}
          name="rent_order"
          label={t(TRANSLATION_KEY.rentOrder)}
          includeInactive={false}
          required={true}
        />

        {/* Price */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "calc(100% - 100px - 20px)" }}>
            <Form.Item
              name="price"
              label={t(TRANSLATION_KEY.price)}
              rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </div>
          <div style={{ width: "100px" }}>
            <Form.Item
              name="currency"
              label={t(TRANSLATION_KEY.priceUom)}
              rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
            >
              <Select>
                {currencies.map((x, index) => (
                  <Select.Option key={index} value={x}>
                    {t(x || "")}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="days_to_due"
            rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
          >
            <Radio.Group
              onChange={(e) => setDaysToDue(e.target.value)}
              value={daysToDue}
              style={{ width: "100%", display: "flex", justifyContent: "space-between" }}
            >
              <Radio value="date" style={{ width: "calc(50% - 10px)", marginRight: 0, padding: 0 }}>
                <Form.Item name="days_to_due__date" label={t(TRANSLATION_KEY.dueDate)}>
                  <DatePicker
                    format={format}
                    style={{ marginLeft: -24, width: "calc(100% + 32px)" }}
                  />
                </Form.Item>
              </Radio>
              <Radio value="days" style={{ width: "calc(50% - 2px)", marginRight: -8, padding: 0 }}>
                <Form.Item name="days_to_due__days" label={t(TRANSLATION_KEY.numberOfDays)}>
                  <InputNumber style={{ width: "calc(100% + 24px)", marginLeft: -24 }} />
                </Form.Item>
              </Radio>
            </Radio.Group>
          </Form.Item>
        </div>

        <Button
          style={{ display: "block", marginBottom: 32, marginTop: -16 }}
          block
          type="dashed"
          onClick={() => setShowTransactionFields((prev) => !prev)}
        >
          {showTransactionFields ? t(TRANSLATION_KEY.close) : t(TRANSLATION_KEY.addMoreData)}
        </Button>

        {showTransactionFields && <CloseInvoiceForm invoiceForm={form} invoice={invoice} />}

        {/* Submit */}
        <Form.Item>
          <Button htmlType="submit" type="primary">
            {t(TRANSLATION_KEY.save)}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

type FormValues = AddInvoice & { days_to_due__date: string; days_to_due__days: string };

export default InvoiceForm;
