import { message, Modal, Space, Table, TableColumnType, Typography } from "antd";
import axios from "axios";
import { t } from "i18next";
import { useState } from "react";
import LoadMoreButton from "../../../../components/LoadMoreButton";
import PreviewButton from "../../../../components/PreviewButton";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import { parseCosts } from "../../../../helpers/functions";
import { IApiResponse, ILoadMoreParams } from "../../../../models";
import { failedQueue, isRefreshing } from "../../../../services";
import AssetModalPreview from "../../../assetdetails/components/AssetModalPreview";
import Loader from "../../components/Loader";
import ReportTwoFilters, { IFilters } from "./components/ReportTwoFilters";

type Props = {};

type TableData = {
  asset_id: number;
  asset_name: string;
  total_cost: number;
  is_active: boolean;
};

// Constants
const LIMIT_SIZE = 10;

const AssetMaintenanceCosts = (props: Props) => {
  // State
  const [modalVisible, set_modalVisible] = useState<boolean>(false);
  const [selectedAsset, set_selectedAsset] = useState<string | number | undefined>(undefined);
  const [generalLoading, set_generalLoading] = useState(false);
  const [loadMoreFilters, setl_loadMoreFilters] = useState<IFilters>({
    maintenance_categories: [],
    end: "",
    start: "",
    maintenance_type: [],
    calendarType: "month",
    executors: [],
  });
  const [loadMoreParams, set_loadMoreParams] = useState<ILoadMoreParams>({
    offset: 0,
    limit: LIMIT_SIZE,
  });
  const [loadMoreButtonVisible, set_loadMoreButtonVisible] = useState<boolean>(true);
  const [assetloading, set_assetLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<TableData[]>([]);

  // Functions
  const fetchTableData = async (filters: IFilters, loadMoreParams: ILoadMoreParams) => {
    console.log("🚧 - fetchTableData - filters:", filters);
    set_assetLoading(true);
    if (loadMoreParams.offset === 0) {
      set_loadMoreButtonVisible(true);
    }
    let token = await localStorage.getItem("token");
    let params = {
      maintenance_categories: filters.maintenance_categories.join("|"),
      end: filters.end,
      start: filters.start,
      maintenance_type: filters.maintenance_type.join("|"),
      offset: loadMoreParams.offset,
      limit: loadMoreParams.limit,
      executors: filters.executors?.join("|"),
    };

    axios
      .get<IApiResponse<TableData[]>>(
        process.env.REACT_APP_HOST_BACKEND + "/api/maintenance/analytics/asset_maintenance_costs/",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
          params,
        },
      )
      .then((response) => {
        setl_loadMoreFilters(filters);
        if (!response.data.results) {
          return;
        }
        if (response.data.results.length < loadMoreParams.limit) {
          set_loadMoreButtonVisible(false);
        }
        set_loadMoreParams({
          offset: loadMoreParams.offset + loadMoreParams.limit,
          limit: loadMoreParams.limit,
        });
        if (loadMoreParams.offset === 0) {
          setTableData(response.data.results);
        } else {
          // Merge the new data with the old data
          setTableData([...tableData, ...response.data.results]);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          if (isRefreshing) {
            failedQueue.push(() => fetchTableData(filters, loadMoreParams));
          }
          return;
        }
        console.log(error);
        message.error(t(TRANSLATION_KEY.errorOnGetData));
      })
      .finally(() => {
        set_assetLoading(false);
      });
  };

  // Columns
  let COLUMNS: TableColumnType<TableData>[] = [
    {
      dataIndex: "asset_custom_id",
      title: t(TRANSLATION_KEY.code),
      render: (text: string, value: TableData) => text || "-",
    },
    {
      dataIndex: "name",
      title: t(TRANSLATION_KEY.asset),
      render: (text: string, value: TableData) => {
        if (!value.asset_id)
          return <Typography.Text>{t(TRANSLATION_KEY.withoutAsset)}</Typography.Text>;
        return (
          <Space direction="vertical" size={0}>
            <PreviewButton
              isActive={value.is_active}
              title={value.asset_name}
              id={value.asset_id}
              url={`/app/asset-details/${value.asset_id}`}
              containerStyle={{ minWidth: "unset" }}
              permissionsRequired={["view_asset"]}
              onClick={() => (set_selectedAsset(value.asset_id), set_modalVisible(true))}
            />
          </Space>
        );
      },
    },

    {
      dataIndex: "total_cost",
      title: t(TRANSLATION_KEY.totalCosts),
      render: (text: number, value: TableData) => parseCosts(text.toString()),
    },
  ];

  return (
    <>
      {/* Spinner */}
      <Loader isLoading={generalLoading} />

      {/* Filters */}
      <ReportTwoFilters
        onChange={(f) => {
          fetchTableData(f, {
            ...loadMoreParams,
            offset: 0,
          });
        }}
      />

      <div style={{ marginTop: 24 }}>
        <Table dataSource={tableData} pagination={false} columns={COLUMNS} />
        {loadMoreButtonVisible && (
          <LoadMoreButton
            loading={assetloading}
            onClick={() => {
              fetchTableData(loadMoreFilters, loadMoreParams);
            }}
          >
            {t(TRANSLATION_KEY.loadMore)}
          </LoadMoreButton>
        )}
      </div>

      {/* Asset preview */}
      <Modal
        visible={modalVisible}
        onCancel={() => set_modalVisible(false)}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        <AssetModalPreview id={selectedAsset} />
      </Modal>
    </>
  );
};

export default AssetMaintenanceCosts;
