import { MailOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Drawer,
  Form,
  List,
  message,
  Modal,
  Popconfirm,
  Space,
  Typography,
} from "antd";
import { t } from "i18next";
import { useState } from "react";
import PreviewButton from "../../../../../components/PreviewButton";
import RequirePermission from "../../../../../components/RequirePermission";
import AddExecutorToOrder from "../../../../../componentsform/AddExecutorToOrder";
import { IFormExecutors } from "../../../../../componentsform/MaintenanceForm";
import SupplierEmail from "../../../../../componentsform/SupplierEmail";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";
import { hasPermission } from "../../../../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { IExecutor, IMaintenanceDetails } from "../../../../../models/maintenances";
import { ISupplier } from "../../../../../models/supplier";
import {
  addExecutorsFromOrderXHR,
  getPotentialExecutorsXHR,
  removeExecutorFromOrderXHR,
} from "../../../../../store/reducers/maintenance/actionCreator";
import AccountModalPreview from "../../../../account/components/AccountModalPreview";
import SupplierModalPreview from "../../../../supplier/components/SupplierModalPreview";
import { store } from "../../../../../store";
import { maintenanceSlice } from "../../../../../store/reducers/maintenance";

interface IProps {
  fromModal?: boolean;
  maintenanceDetails: IMaintenanceDetails;
  isOpenOrder: boolean;
}

const Executors: React.FC<IProps> = ({ fromModal = false, maintenanceDetails, isOpenOrder }) => {
  const { removeExecutorFromOrderStatus, addExecutorsFromOrderStatus } = useAppSelector(
    (state) => state.maintenanceReducer,
  );

  const { user } = useAppSelector((state) => state.userReducer);

  const [form] = Form.useForm();
  const [modalVisible, set_modalVisible] = useState<boolean>(false);
  const [selectedAccount, set_selectedAccount] = useState<string | number | undefined>(undefined);
  const [selectedSupplier, set_selectedSupplier] = useState<string | number | undefined>(undefined);

  const [sendEmailToSupplier, set_sendEmailToSupplier] = useState<IExecutor>();

  //adding
  const [addExecutors, set_addExecutors] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const removeExecutor = (executor: number) => {
    removeExecutorFromOrderXHR(
      {
        body: {
          executor,
        },
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnSaveData)),
        successCallback: (res) => {
          let tmpOrders = [...store.getState().maintenanceReducer.maintenanceList.data];
          let index = tmpOrders.findIndex((x) => x.id === res.results?.id);
          if (index !== -1 && res.results) {
            tmpOrders[index] = res.results;
            dispatch(
              maintenanceSlice.actions.getMaintenanceListSuccess({
                mergeData: false,
                message: "",
                results: {
                  data: tmpOrders,
                  cursor: store.getState().maintenanceReducer.maintenanceList.cursor,
                },
              }),
            );
          }

          let tmLiveMaintenanceList = [...store.getState().maintenanceReducer.liveMaintenances];
          let indexLive = tmLiveMaintenanceList.findIndex((x) => x.id === res.results?.id);
          if (indexLive !== -1 && res.results) {
            tmLiveMaintenanceList[indexLive] = res.results;
            dispatch(
              maintenanceSlice.actions.getLiveMaintenancesSuccess({
                message: "",
                results: { data: tmLiveMaintenanceList, cursor: null },
              }),
            );
          }
        },
      },
      dispatch,
    );
  };

  const onAddExecutors = (executors: IFormExecutors) => {
    addExecutorsFromOrderXHR(
      {
        body: {
          order_info: maintenanceDetails.id,
          executors,
        },
        errorCallback: () => message.error(t(TRANSLATION_KEY.addExecutorFailed)),
        successCallback: (res) => {
          let tmpOrders = [...store.getState().maintenanceReducer.maintenanceList.data];
          let index = tmpOrders.findIndex((x) => x.id === res.results?.id);
          if (index !== -1 && res.results) {
            tmpOrders[index] = res.results;
            dispatch(
              maintenanceSlice.actions.getMaintenanceListSuccess({
                mergeData: false,
                message: "",
                results: {
                  data: tmpOrders,
                  cursor: store.getState().maintenanceReducer.maintenanceList.cursor,
                },
              }),
            );
          }

          let tmLiveMaintenanceList = [...store.getState().maintenanceReducer.liveMaintenances];
          let indexLive = tmLiveMaintenanceList.findIndex((x) => x.id === res.results?.id);
          if (indexLive !== -1 && res.results) {
            tmLiveMaintenanceList[indexLive] = res.results;
            dispatch(
              maintenanceSlice.actions.getLiveMaintenancesSuccess({
                message: "",
                results: { data: tmLiveMaintenanceList, cursor: null },
              }),
            );
          }

          message.success(t(TRANSLATION_KEY.addExecutorSuccessful));
          set_addExecutors(false);
          form.resetFields();
        },
      },
      dispatch,
    );
  };

  const removeButton = (item: IExecutor) => {
    if (isOpenOrder) {
      return;
    }
    return hasPermission(user.account.permissions, ["manage_wo_executors"]) ? (
      <Popconfirm
        cancelText={t(TRANSLATION_KEY.no)}
        okText={t(TRANSLATION_KEY.yes)}
        onCancel={() => {}}
        onConfirm={() => {
          removeExecutor(item.id);
        }}
        title={t(TRANSLATION_KEY.continueWithAction)}
      >
        <Button danger type="link">
          {t(TRANSLATION_KEY.remove)}
        </Button>
      </Popconfirm>
    ) : null;
  };

  return (
    <>
      <List
        loading={removeExecutorFromOrderStatus === "loading"}
        header={
          !fromModal ? (
            <div className="spaceBetweenRow">
              <Typography.Title level={5} style={{ paddingTop: 4 }}>
                {t(TRANSLATION_KEY.executors)}
              </Typography.Title>
              {!isOpenOrder && (
                <RequirePermission
                  requiredPermissionCode={["manage_wo_executors"]}
                  children={
                    <Button
                      onClick={() => {
                        set_addExecutors(true);
                        getPotentialExecutorsXHR(
                          {
                            errorCallback: () => message.error(TRANSLATION_KEY.errorOnGetData),
                            queryParams: {
                              asset: maintenanceDetails.asset?.id || 0,
                              order_info: maintenanceDetails.id,
                            },
                          },
                          dispatch,
                        );
                      }}
                      type="primary"
                      shape="circle"
                      icon={<PlusOutlined />}
                    />
                  }
                />
              )}
            </div>
          ) : undefined
        }
        dataSource={maintenanceDetails.executors}
        renderItem={(item, index) => (
          <List.Item
            actions={[
              ...(item.supplier &&
              !isOpenOrder &&
              user.account.permissions.filter((p) =>
                ["manage_supplier", "manage_wo"].includes(p.codename),
              ).length > 0
                ? [
                    <Button type="link" onClick={() => set_sendEmailToSupplier(item || undefined)}>
                      {t(TRANSLATION_KEY.sendEmail)}
                    </Button>,
                  ]
                : []),
              removeButton(item),
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar icon={<UserOutlined />} />}
              title={
                isOpenOrder ? (
                  item.supplier?.name
                ) : (
                  <Space>
                    {/* Informations */}
                    <Space direction="vertical" style={{ width: "max-content" }}>
                      <Space style={{ width: "max-content" }}>
                        <Typography.Text strong>
                          #{item.account ? item.account.custom_id : item.supplier?.custom_id}
                        </Typography.Text>
                        <Typography.Text type="secondary">
                          {item.account
                            ? t(item.account.role?.name)
                            : item.supplier
                              ? t(item.supplier?.category?.name || "")
                              : null}
                        </Typography.Text>
                      </Space>
                      <Space>
                        {/* Is working badge */}
                        {item.is_working && <Badge status="success" />}
                        {/* Preview button */}
                        <PreviewButton
                          isActive={
                            item.account ? item.account.is_active : item.supplier?.is_active
                          } // ISupplier model does not have is_active property yet
                          title={item.account ? item.account.name : item.supplier?.name}
                          id={item.account ? item.account.id : item.supplier?.id}
                          url={
                            item.account
                              ? `/app/humanresources/${item.account.id}`
                              : item.supplier
                                ? `/app/suppliers/${item.supplier.id}`
                                : ""
                          }
                          permissionsRequired={
                            item.account
                              ? ["manage_user"]
                              : item.supplier
                                ? ["manage_supplier"]
                                : []
                          }
                          onClick={() => {
                            if (item.account) {
                              set_selectedAccount(item.account?.id);
                            } else if (item.supplier) {
                              set_selectedSupplier(item.supplier?.id);
                            }
                            set_modalVisible(true);
                          }}
                        />
                      </Space>
                    </Space>
                  </Space>
                )
              }
              description={item.account ? item.account.phone_number : item.supplier?.phone_number}
            />
          </List.Item>
        )}
      />

      <Drawer
        title={t(TRANSLATION_KEY.addExecutors)}
        visible={addExecutors}
        onClose={() => set_addExecutors(false)}
        destroyOnClose={true}
      >
        <AddExecutorToOrder
          order_info={maintenanceDetails.id}
          asset_id={maintenanceDetails.asset?.id || 0}
          onAdd={onAddExecutors}
          loading={addExecutorsFromOrderStatus === "loading"}
        />
      </Drawer>

      <Modal
        title={t(TRANSLATION_KEY.sendEmail)}
        visible={!!sendEmailToSupplier}
        onCancel={() => set_sendEmailToSupplier(undefined)}
        footer={null}
        destroyOnClose
      >
        <SupplierEmail
          supplier={sendEmailToSupplier}
          maintenance_id={maintenanceDetails.id}
          close={() => set_sendEmailToSupplier(undefined)}
        />
      </Modal>

      {/* Modal */}
      <Modal
        visible={modalVisible}
        onCancel={() => {
          set_modalVisible(false);
          set_selectedAccount(undefined);
          set_selectedSupplier(undefined);
        }}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        {selectedAccount && <AccountModalPreview id={selectedAccount} />}
        {selectedSupplier && <SupplierModalPreview id={selectedSupplier} />}
      </Modal>
    </>
  );
};

export default Executors;
