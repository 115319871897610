import { Descriptions, message, Modal, Select, Spin, Tag as Chip, Typography, Space } from "antd";
import { t, use } from "i18next";
import React, { useEffect, useState } from "react";
import SelectTagAdd, { ButtonAddSelectTag, Tag } from "../../../components/SelectTagAdd";
import {
  DATE_FORMATS,
  RENT_ORDER_STATUSES,
  STATUSES,
  TRANSLATION_KEY,
} from "../../../helpers/consts";
import { hasModule, hasPermission, sumWorkingTimes } from "../../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { IApiResponse, ILayoutGrids, ILocation } from "../../../models";
import {
  IMaintenanceDetails,
  IUpdateMaintenance,
  MaintenanceStatusTypes,
} from "../../../models/maintenances";
import { IUser } from "../../../models/user";
import {
  updateMaintenanceXHR,
  updateOrerAssetXHR,
} from "../../../store/reducers/maintenance/actionCreator";
import AssetModalPreview from "../../assetdetails/components/AssetModalPreview";
import { settingsSlice } from "../../../store/reducers/settings";

import { Link, useParams } from "react-router-dom";
import { LinkOutlined } from "@ant-design/icons";
import ClientsSelect from "../../../componentsform/ClientsSelect";
import AssetTreeSelect from "../../../componentsform/AssetTreeSelect";
import CustomFieldsSpecGenerator from "../../../components/CustomFieldsSpecGenerator";
import { IRentDetails, IRentUpdate } from "../../../models/clients";
import moment from "moment";
import { updateRentOrderXHR } from "../../../store/reducers/clients/actionCreators";

interface IProps {
  rentDetails: IRentDetails;
  user: IUser | null;
}

const RentInfo: React.FC<IProps> = ({ user, rentDetails }) => {
  const [addLocationVisible, set_addLocationVisible] = useState<boolean>(false);
  const [assetModalVisible, set_assetModalVisible] = useState<boolean>(false);
  const { locations } = useAppSelector((state) => state.settingsReducer);
  const { updateRentOrderStatus } = useAppSelector((state) => state.clientsReducer);
  const { id } = useParams();

  const dispatch = useAppDispatch();

  const updateRent = (data: Partial<IRentUpdate>) => {
    updateRentOrderXHR(
      {
        body: data,
        id: id,
        errorCallback: (error) => {
          if (error.response.data?.message?.custom_field) {
            message.error(
              t(error.response.data.message.message || "").replace(
                "$_dynamic_column",
                error.response.data.message.custom_field,
              ),
            );
            return;
          }
          message.error(t(TRANSLATION_KEY.errorOnSaveData));
        },
      },
      dispatch,
    );
  };

  let descriptionClassname = "select";

  return (
    <div>
      <Spin spinning={updateRentOrderStatus === "loading"}>
        <Descriptions size="small" column={{ xxl: 4, xl: 3, lg: 3, md: 2, sm: 2, xs: 1 }}>
          {/* Asset */}
          <Descriptions.Item label={t(TRANSLATION_KEY.asset)}>
            <Space size={0}>
              {rentDetails.asset?.id && (
                <Link
                  to={`/app/asset-details/${rentDetails.asset?.id}`}
                  type="link"
                  style={{
                    padding: 0,
                    height: "unset",
                    fontSize: 16,
                    fontWeight: 500,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: 210,
                    marginRight: -4,
                  }}
                >
                  <LinkOutlined />
                </Link>
              )}
              <AssetTreeSelect
                includeWithoutAsset={false}
                disabled={true}
                onChange={(e) => {
                  console.log(e);
                  if (Array.isArray(e)) {
                    return;
                  }
                  updateOrerAssetXHR(
                    {
                      body: { asset: e || null, order_info: rentDetails.id },
                      errorCallback: (error) => {
                        message.error(t(TRANSLATION_KEY.errorOnSaveData));
                      },
                    },
                    dispatch,
                  );
                }}
                treeProps={{
                  bordered: false,
                  allowClear: true,
                  showSearch: true,
                  maxTagCount: undefined,
                  style: { width: 224 },
                  value: rentDetails.asset?.id,
                  placeholder: t(TRANSLATION_KEY.selectAssets),
                  multiple: false,
                }}
              />
            </Space>
          </Descriptions.Item>

          {/* Location */}
          <Descriptions.Item className={descriptionClassname} label={t(TRANSLATION_KEY.location)}>
            <Select
              bordered={false}
              allowClear
              style={{ width: "100%" }}
              showArrow={false}
              placeholder={t(TRANSLATION_KEY.selectLocation)}
              key={`location:${rentDetails.location?.id}`}
              defaultValue={rentDetails.location?.id}
              optionFilterProp="children"
              onChange={(location) => {
                updateRent({ location: location || null });
              }}
              filterOption={(input, option) => {
                if (typeof option?.children === "string") {
                  const str: string = option.children;
                  return str.toLowerCase().includes(input.toLowerCase());
                }
                return false;
              }}
              showSearch={true}
              notFoundContent={
                <ButtonAddSelectTag
                  addingVisible={addLocationVisible}
                  set_addingVisible={set_addLocationVisible}
                />
              }
            >
              {locations.map((x) => (
                <Select.Option value={x.id} key={x.id}>
                  {x.name}
                </Select.Option>
              ))}
              <Select.OptGroup
                label={
                  <ButtonAddSelectTag
                    addingVisible={addLocationVisible}
                    set_addingVisible={set_addLocationVisible}
                  />
                }
              />
            </Select>
          </Descriptions.Item>
          <Descriptions.Item label={t(TRANSLATION_KEY.createdAt)}>
            <Typography.Text>
              {moment(rentDetails.created_at).format(
                user?.account.date_format || "DD.MM.YYYY - HH:mm",
              )}
            </Typography.Text>
          </Descriptions.Item>

          <Descriptions.Item label={t(TRANSLATION_KEY.createdBy)}>
            <Typography.Text>{rentDetails.created_by?.name || "-"}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label={t(TRANSLATION_KEY.closedAt)}>
            <Typography.Text>
              {rentDetails.closed_at
                ? moment(rentDetails.closed_at).format(
                    user?.account.date_format || "DD.MM.YYYY - HH:mm",
                  )
                : "-"}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label={t(TRANSLATION_KEY.confirmedBy)}>
            <Typography.Text>{rentDetails.closed_by?.name || "-"}</Typography.Text>
          </Descriptions.Item>

          {/* Status */}
          <Descriptions.Item className={descriptionClassname} label={t(TRANSLATION_KEY.status)}>
            <Select
              bordered={false}
              style={{ width: "100%" }}
              showArrow={false}
              placeholder={t(TRANSLATION_KEY.selectStatus)}
              key={`status:${rentDetails.status}`}
              value={t(rentDetails.status)}
              disabled
              filterOption={(input, option) => {
                if (typeof option?.children === "string") {
                  const str: string = option.children;
                  return str.toLowerCase().includes(input.toLowerCase());
                }
                return false;
              }}
              showSearch={true}
            >
              {RENT_ORDER_STATUSES.filter((x) => x !== rentDetails.status).map((status) => (
                <Select.Option key={status} value={status}>
                  {t(status)}
                </Select.Option>
              ))}
            </Select>
          </Descriptions.Item>

          <Descriptions.Item label={t(TRANSLATION_KEY.client)}>
            <Space size={0}>
              {rentDetails.client && (
                <Link
                  to={`/app/clients/${rentDetails.client.id}`}
                  type="link"
                  style={{
                    padding: 0,
                    height: "unset",
                    fontSize: 16,
                    fontWeight: 500,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: 210,
                    marginRight: -4,
                  }}
                >
                  <LinkOutlined />
                </Link>
              )}
              <ClientsSelect
                disabled={true}
                includeInactive={false}
                includeArchived={false}
                bordered={false}
                value={rentDetails.client?.id}
                onChange={(e: number | undefined | number[]) => {
                  if (Array.isArray(e)) {
                    return;
                  }
                  alert("Update klijenta rent ordera");
                }}
                name=""
                label=""
                form={undefined}
                style={{ width: 210 }}
                mode={undefined}
              />
            </Space>
          </Descriptions.Item>

          {CustomFieldsSpecGenerator({
            onChange: (value) => {
              updateRent({ rent_order_custom_fields_v2: value });
            },
            condition: undefined,
            formType: "rent_order",
            data: rentDetails.rent_order_custom_fields_v2,
            alowUpdate: true,
          })}
        </Descriptions>
      </Spin>

      {/* Asset Modal Preview */}
      <Modal
        visible={assetModalVisible}
        onCancel={() => set_assetModalVisible(false)}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        <AssetModalPreview id={rentDetails.asset?.id} />
      </Modal>
    </div>
  );
};

export default RentInfo;
