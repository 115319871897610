import { useState, useEffect } from "react";
import {
  CheckTreeItem,
  ILocation,
  ITableColumn,
  NewTreeItem,
  PartCategoryTreeItem,
  RCFileTypes,
  SelectTreeItem,
  TreeItem,
} from "../models";
import { BaseListItem, IAsset } from "../models/asset";
import { IPart, IPartCategory, ITreeCheckItem, ITreeSelectItem } from "../models/parts";
import {
  CustomFieldV2,
  CustomFieldValues,
  CustomFieldValueType,
  IChecklistInTask,
  ICustomField,
  ICustomValue,
  ICustoValuesObject,
  IPanelAsset,
  IPermission,
  ITask,
} from "../models/settings";
import Compressor from "compressorjs";
import { RcFile } from "antd/lib/upload";
import {
  IExecutor,
  IFlow,
  IMaintenance,
  IMaintenanceCategory,
  IMaintenanceDetails,
  IMaintenanceType,
  MaintenanceStatusTypes,
} from "../models/maintenances";
import { DynamicFiltersType, ICompany, IFile, IUserAccount } from "../models/user";
import { MediaType } from "../components/Files";
import { DEFAULT_AXIOS_TIMEOUT } from "../services";

import moment, { Duration } from "moment";
import { IFilters } from "../pages/maintenances/Calendar";
import { measureTextWidth } from "@ant-design/charts";
import { message } from "antd";
import { TRANSLATION_KEY, UPLOAD_FILES_LIMITS } from "./consts";
import { t } from "i18next";
import { FormType } from "../componentsform/CustomFieldsFormV2";

interface IStorageColumns {
  visible: string | boolean;
  dataIndex: string;
}

export const sortTableColumns = async (
  columns: ITableColumn<any>[],
  memorizedName: string,
  setCallback: (columns: ITableColumn<any>[]) => void,
): Promise<void> => {
  let sortBy = await localStorage.getItem(memorizedName);

  if (sortBy === null) {
    setCallback(columns);
    localStorage.removeItem(memorizedName);
    return;
  }

  let _columns: IStorageColumns[] = JSON.parse(sortBy);

  const sorted: ITableColumn<any>[] = [];

  if (_columns.length < columns.length) {
    columns.forEach((x) => {
      if (_columns.filter((y) => y.dataIndex === x.dataIndex).length === 0) {
        _columns.push({ ...x });
      }
    });
  }

  try {
    _columns.forEach((x) => {
      const column = columns.find((y) => y.dataIndex === x.dataIndex);
      if (column) {
        column.visible = !!x.visible;
        sorted.push(column);
      }
    });

    setCallback(sorted);
  } catch (error) {
    setCallback(columns);
    localStorage.removeItem(memorizedName);
  }
};

export function getChilds<Type>({
  ids,
  data,
  arr = [],
}: {
  ids: number[]; // parent
  data: TreeItem<Type>[]; // stablo
  arr: number[]; // spremnik
}) {
  let childs = childsOfParent({ ids, data, arr });
  childs.shift(); // očistiti prvi eement koji i je sami ćaća
  return childs;
}

export function childsOfParent<Type>({
  ids,
  data,
  arr = [],
}: {
  ids: number[]; // parent
  data: TreeItem<Type>[]; // stablo
  arr: number[]; // spremnik
}) {
  ids.forEach((asset_id) => {
    arr.push(asset_id);
    data.forEach((element) => {
      if (element.parent_id == asset_id) {
        childsOfParent<Type>({ ids: [element.id], data: data, arr: arr }); //([element.id], data, arr);
      }
    });
  });

  return [...arr];
  // return [...new Set<number[]>(arr)];
}

export function getChildsOfObjectInRawArray<
  T extends {
    id: number;
    parent_id: number | null;
    [key: string]: number | string | any;
  },
>(items: T[], item_id: number, box: T[]) {
  const arr: number[] = [];
  const clearArray: T[] = [];

  let childs = items.filter((x) => x.parent_id === item_id && x.id !== item_id);
  if (childs.length) {
    childs.forEach((x) => {
      getChildsOfObjectInRawArray(items, x.id, box);
    });
  }

  return box;
}

export function createTree(data: IAsset[], parentValue: number | null) {
  const treeData: NewTreeItem<IAsset & { key: string }>[] = [];
  data.forEach((item) => {
    if (parentValue === item.parent_id) {
      treeData.push({
        ...item,
        key: item.id.toString(),
        children: createTree(
          data.filter((x) => x.parent_id !== parentValue),
          item.id,
        ),
      });
    }
  });

  return treeData;
}

export function createTreePanelAsset(data: IPanelAsset[], parentValue: number | null) {
  const treeData: NewTreeItem<IPanelAsset & { key: string }>[] = [];
  data.forEach((item) => {
    if (parentValue === item.parent) {
      treeData.push({
        ...item,
        key: item.id.toString(),
        children: createTreePanelAsset(
          data.filter((x) => x.parent !== parentValue),
          item.id,
        ),
      });
    }
  });

  return treeData;
}

export function createTreeSelect(data: ITreeSelectItem[], parentValue: string | null) {
  const treeData: SelectTreeItem[] = [];

  data.forEach((item) => {
    if (parentValue === item.parent_id) {
      treeData.push({
        ...item,
        children: createTreeSelect(
          data.filter((x) => x.parent_id !== parentValue),
          item.value,
        ),
      });
    }
  });

  return treeData;
}

export function createCheckTreeSelect(data: ITreeCheckItem[], parentValue: string | null) {
  const treeData: CheckTreeItem[] = [];

  data.forEach((item) => {
    if (parentValue === item.parent_id) {
      treeData.push({
        ...item,
        children: createCheckTreeSelect(
          data.filter((x) => x.parent_id !== parentValue),
          item.key,
        ),
      });
    }
  });

  return treeData;
}

/**
 * @param data - Array of assets
 * @returns Array of assets
 * @example
 * setItemsWithoutParentToHighestNode(data)
 * // returns array of assets
 * @description
 * If parent_id is not null and parent_id is not in array of assets, set parent_id to null
 */
export const setItemsWithoutParentToHighestNode = (
  data: { parent_id: null | number; id: number }[],
): any[] => {
  // Return if no data
  if (data.length === 0) {
    return data;
  }

  let tmp: any[] = [];
  for (let index = 0; index < data.length; index++) {
    const element = { ...data[index] };
    if (element.parent_id !== null && !data.filter((x) => x.id == element.parent_id).length) {
      element.parent_id = null;
    }
    tmp.push(element);
  }
  return tmp;
};

/**
 * @param data - Array of assets
 * @returns Array of assets
 * @example
 * parseBaseAssetListParents(data)
 * // returns array of assets
 * @description
 * If parent_id is not null and parent_id is not in array of assets, set parent_id to null
 */
export const parseBaseAssetListParents = (data: BaseListItem[]): BaseListItem[] => {
  // Return if no data
  if (data.length === 0) {
    return data;
  }

  let tmp: BaseListItem[] = [];
  for (let index = 0; index < data.length; index++) {
    const element = { ...data[index] };
    if (element.parent_id !== null && !data.filter((x) => x.id == element.parent_id).length) {
      element.parent_id = null;
    }
    tmp.push(element);
  }
  return tmp;
};

/**
 * @param data - Array of assets
 * @param item - Id of parent
 * @param results - Array of children
 * @returns Array of children
 * @example
 * childrenOfParent(data, 1, [])
 * // returns array of children
 */

export const childrenOfParent = (data: IAsset[], item: number, results: IAsset[] = []) => {
  data.forEach((x) => {
    if (x.parent_id == item) {
      results.push({ ...x });
      childrenOfParent(data, +x.id, results);
    }
  });
  return results;
};

export function generateCustomFieldValues(
  customFields: ICustomField[],
  customValues: ICustomValue[],
): ICustoValuesObject {
  let tmp: ICustoValuesObject = {};
  customFields.forEach((x) => {
    tmp[x.key] = customValues.find((y) => y.key === x.key)?.value || "";
  });

  return tmp;
}

export async function compressImage(file: RcFile, quality = 0.6): Promise<File> {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality,
      success: (result: any) => {
        resolve(new File([result], file.name, { type: result.type }));
      },
      error: (error: any) => reject(error),
    });
  });
}

export function canStartOrder(
  maintenance: IMaintenanceDetails,
  currentUser: IUserAccount,
): boolean {
  // when is more executor on order but im not one of them

  if (!maintenance.executors.length) {
    return true;
  }

  return maintenance.executors.map((x) => x.account?.id).includes(currentUser.id);
}

export function filterFilesByExtension(data: IFile[], filters: MediaType[]): IFile[] {
  data = data.filter((item) => {
    if (item.extension === "jpg" || item.extension === "jpeg" || item.extension === "png")
      return filters.includes("image");
    else if (item.extension === "mp4") return filters.includes("video");
    else if (item.extension === "pdf") return filters.includes("document");
    else return false;
  });

  return data;
}

export function canSeePage(user: IUserAccount, permissionCode: string | undefined): boolean {
  if (!!permissionCode && !user.permissions.filter((x) => x.codename === permissionCode).length) {
    return false;
  }

  return true;
}

export function maintenanceOver(status: MaintenanceStatusTypes | ""): boolean {
  return !(status !== "cancelled" && status !== "closed" && status !== "completed");
}

export function getRunningExecutorFlow(
  user_id: number | undefined,
  isOpenOrder: boolean,
  flows: IFlow[],
): IFlow | undefined {
  // Variables
  let flow: IFlow | undefined;
  let arr: IFlow[] = [...flows];

  // Filtering
  arr = flows.filter((item) => {
    if (item.executor?.account) {
      return item.executor.account.id === user_id && !item.executor.duration;
    } else if (item.executor?.supplier) {
      return item.executor.supplier && !item.executor.duration && isOpenOrder;
    }
  });

  // Finding flow with end === null
  flow = arr.find((item) => item.end === null);

  return flow;
}

export const TOPICS = (startTopic: string, company: string): Record<string, string> => ({
  // me: startTopic + "/me",
  liveMaintenances: startTopic + "/live/orders",
  liveMaintenance: startTopic + "/live/order_details",
  assets: startTopic + "/account_assets",

  critical_qty_list: `${company}/common/warehouse/parts/critical_qty_list`,
  notifications: `${startTopic}/notifications/new`,
  logged_users: `${startTopic}/users/logged_users`,
  top_costs: `${startTopic}/assets/top_costs`,
  orders_without_report: `${startTopic}/maintenance/orders_without_report`,
});
export function parseCosts(value: string | null | undefined): string {
  // Early exit

  let tmp = Number(value).toFixed(2).toString();

  let intlValue = new Intl.NumberFormat("de-DE").format(+tmp);
  let parseDots = parseDecimalDots(intlValue);
  let parsedCommas = parseDecimalDots(parseDots.replace(/\,/g, " "));
  let parsed = `${parsedCommas.split(",")[0]},${tmp.split(".")[1]}`;

  if (value === null || value === undefined) {
    return "";
  }

  return parsed;
}

export function parseDecimalDots(value: string | number | null): string {
  // Early exit
  if (value === null) {
    return "";
  }

  if (typeof value === "number") {
    value = value.toString();
  }

  return value.replace(/,/g, "__").replace(/\./g, ",").replace(/__/g, ".") as string;
}

export function useSingleAndDoubleClick(
  actionSimpleClick: any,
  actionDoubleClick: any,
  delay: number = 250,
) {
  const [click, setClick] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      // simple click
      if (click === 1) actionSimpleClick();
      setClick(0);
    }, delay);

    // the duration between this click and the previous one
    // is less than the value of delay = double-click
    if (click === 2) actionDoubleClick();

    return () => clearTimeout(timer);
  }, [click]);

  return () => setClick((prev) => prev + 1);
}

export function parseUploadFiles(
  files: RcFile[],
  maxSize: number,
  fileExtensionsToExclude?: RCFileTypes[],
): RcFile[] {
  let parsedFiles = [...files];

  // Filtering by size
  parsedFiles = parsedFiles.filter((file) => {
    let size = file.size / 1024 / 1024;
    if (size > maxSize && !fileExtensionsToExclude?.includes(file.type as RCFileTypes)) return true;
    return false;
  });
  return parsedFiles;
}

export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  const debounced = (...args: Parameters<F>) => {
    if (timeout !== null) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export const showComands = (order: IMaintenanceDetails, current_user_id: number): boolean => {
  if (order.status === "held" || order.status === "open" || order.status === "running") {
    return true;
  }

  if (
    !order.executors.filter((x) => x.account?.id === current_user_id).length // ako nema korisnika
  ) {
    return true;
  }

  return (
    !!order.executors.filter((x) => x.account?.id === current_user_id).length &&
    (order.status === "assigned" || order.status === "requested")
  );
};

export function getAvatarColor(avatar: string): string {
  const normalizeHash = (hash: number, min: number, max: number) =>
    Math.floor((hash % (max - min)) + min);

  const hRange = [40, 360];
  const sRange = [50, 75];
  const lRange = [25, 60];

  let hash = 0,
    i = 0;
  for (i; i < avatar.length; i++) hash = avatar.charCodeAt(i) + ((hash << 5) - hash);
  hash = Math.abs(hash);

  const h = normalizeHash(hash, hRange[0], hRange[1]);
  const s = normalizeHash(hash, sRange[0], sRange[1]);
  const l = normalizeHash(hash, lRange[0], lRange[1]);

  return `hsl(${h}, ${s}%, ${l}%)`;
}

export const groupChecklistFromTasks = (tasks: ITask[]): IChecklistInTask[] => {
  let list: IChecklistInTask[] = [];

  for (let index = 0; index < tasks.length; index++) {
    const element = tasks[index];
    if (element.checklist && list.filter((x) => x.id === element.checklist?.id).length === 0) {
      list.push(element.checklist);
    }
  }

  return list;
};

export const getAllPartStoreagesForSelect = (
  assetList: IAsset[],
  part: IPart | undefined | null,
): ITreeSelectItem[] => {
  let warehouses: ITreeSelectItem[] = [
    ...assetList.filter((x) => !!part?.part_storage.filter((y) => y.storage.id === x.id).length),
  ].map((x) => ({
    title: x.name,
    value: x.id.toString(),
    parent_id: x.parent_id?.toString() || null,
    disabled: false,
    path: x.path || undefined,
  }));

  warehouses = warehouses.map((x) => ({
    ...x,
    parent_id: warehouses.find((y) => y.value === x.parent_id) ? x.parent_id : null,
  }));

  return warehouses;
};

/**
 * @param files files to calculate timeout
 * @param factor calculation factor
 * @param defaultTimeout default timeout
 * @returns timeout in miliseconds
 */
export const calculateTimeout = (
  files: RcFile[],
  factor: number = 1.15,
  defaultTimeout: number = DEFAULT_AXIOS_TIMEOUT,
): number => {
  // Get files size in megabytes
  let size = files.reduce((acc, val) => {
    // Show loading message if file size is greater than largeFileSize
    if (val.size / 1024 / 1024 > UPLOAD_FILES_LIMITS.largeFileSize) {
      message.loading(t(TRANSLATION_KEY.stableConnectionReq), (val.size / 1024 / 1024) * factor);
    }
    return acc + val.size / 1024 / 1024;
  }, 0);
  // Calculate timeout in miliseconds
  let calculated_timeout = Math.ceil(size) * factor * 1024;
  // If size is less than default timeout, return default timeout, else return calculated timeout
  let timeout = calculated_timeout <= defaultTimeout ? defaultTimeout : calculated_timeout;
  // Returning timeout
  return timeout;
};

export const formatNumber = (number: number | string | undefined | null): string => {
  if (number) {
    return new Intl.NumberFormat("de-DE").format(+number).replaceAll(".", " ");
  }

  return "";
};

export function hasPermission(permissions: IPermission[], requiredPermissions: string[]): boolean {
  for (let i = 0; i < permissions.length; i++) {
    const element = permissions[i];
    for (let j = 0; j < requiredPermissions.length; j++) {
      if (element.codename === requiredPermissions[j]) {
        return true;
      }
    }
  }
  return false;
}

export const sumWorkingTimes = (data: string[] | null) => {
  if (data === null) {
    return moment.duration("0:0:0");
  }
  let sum: Duration = moment.duration("0:0:0");
  data.forEach((x) => {
    sum.add(moment.duration(x));
  });
  return sum;
};

export const formatTaskDuration = (task: ITask): string => {
  let d = moment.duration(task.planned_duration).humanize();

  if (d === "par sekundi") {
    d = "-";
  }

  return d;
};

export function getExecutors(maintenances: IMaintenance[]): IExecutor[] {
  const executorsSet = new Set<string>();
  const uniqueExecutors: IExecutor[] = [];

  maintenances.forEach((maintenance) => {
    maintenance.executors.forEach((executor) => {
      const executorKey = JSON.stringify({
        account: executor.account,
        supplier: executor.supplier,
      });
      if (!executorsSet.has(executorKey)) {
        executorsSet.add(executorKey);
        uniqueExecutors.push(executor);
      }
    });
  });

  return uniqueExecutors;
}

export function getLocations(data: IMaintenance[]) {
  let arr: ILocation[] = [];
  // Filtering unique locations
  data.forEach((item) => {
    if (item.location && !arr.some((location) => location.id === item.location?.id)) {
      arr.push(item.location);
    }
  });
  return arr;
}

export function getStatuses(data: IMaintenance[]) {
  let arr: MaintenanceStatusTypes[] = [];
  // Filtering unique locations
  data.forEach((item) => {
    if (item.status && !arr.some((status) => status === item.status)) {
      arr.push(item.status);
    }
  });
  return arr;
}

export function getAssets(data: IMaintenance[]) {
  let arr: IAsset[] = [];
  let final: IAsset[] = [];

  // Filling arr with unique assets
  data.forEach((item) => {
    if (item.asset && !arr.some((asset) => asset.id === item.asset?.id)) {
      arr.push(item.asset);
    }
  });

  // If assets parent_id does not exist in array, set parent_id to null
  arr.forEach((item) => {
    final.push({
      ...item,
      parent_id: item.parent_id // Early exit
        ? arr.some((asset) => asset.id === item.parent_id)
          ? item.parent_id
          : null
        : null,
    });
  });
  return final;
}

export function getCategories(data: IMaintenance[]) {
  let arr: IMaintenanceCategory[] = [];
  data.forEach((element) => {
    element.maintenance_categories?.forEach((item) => {
      if (item) arr.push(item);
    });
  });
  return Array.from(new Map(arr.map((item: IMaintenanceCategory) => [item["id"], item])).values());
}

export function getTypes(data: IMaintenance[]) {
  let arr: IMaintenanceType[] = [];
  // Filtering unique types
  data.forEach((item) => {
    if (item.maintenance_type && !arr.some((type) => type.id === item.maintenance_type?.id)) {
      arr.push(item.maintenance_type);
    }
  });
  return arr;
}

export function filter(
  data: IMaintenance[],
  filters: Partial<IFilters>,
  initialFilters: Partial<IFilters>,
) {
  let filteredData = data;
  // Early exit
  if (filters === initialFilters) {
    return data;
  }
  // Filtering assets
  if (filters.assets && filters.assets.length) {
    filteredData = filteredData.filter((item: IMaintenance) => {
      return filters.assets?.includes(item.asset?.id || 0);
    });
  }
  // Filtering executors
  if (filters.executors && filters.executors.length) {
    filteredData = filteredData.filter((item: IMaintenance) => {
      let { executors } = item,
        check = false;
      executors.forEach((executor: IExecutor) => {
        if (executor.account) {
          if (filters.executors?.includes(executor.account.id)) {
            check = true;
          }
        } else if (executor.supplier) {
          if (filters.executors?.includes(executor.supplier.id)) {
            check = true;
          }
        }
      });
      return check;
    });
  }
  // Filtering locations
  if (filters.locations && filters.locations.length) {
    filteredData = filteredData.filter(
      (item: IMaintenance) => item.location && filters.locations?.includes(item.location.id),
    );
  }
  // Filtering types
  if (filters.types && filters.types.length) {
    filteredData = filteredData.filter(
      (item: IMaintenance) =>
        item.maintenance_type && filters.types?.includes(item.maintenance_type.id),
    );
  }
  // Filtering statuses
  if (filters.statuses && filters.statuses.length) {
    filteredData = filteredData.filter(
      (item: IMaintenance) => item.status && filters.statuses?.includes(item.status),
    );
  }
  // Filtering categories
  if (filters.categories && filters.categories.length) {
    filteredData = filteredData.filter((item: IMaintenance) => {
      let check = false;
      item.maintenance_categories?.forEach((category: IMaintenanceCategory) => {
        if (filters.categories?.includes(category.id)) check = true;
      });
      return check;
    });
  }

  return filteredData;
}

// ===============================================================================================================

/**
 * @param name string value of name to get initials from
 * @returns string value of initials
 * @example getInitials("John Doe") => "JD"
 */

export function getInitials(name: string | undefined): string | undefined {
  if (typeof name !== "string") return "";
  return name
    .split(" ")
    .map((word: string) => word[0]?.toUpperCase())
    .join("");
}

// ===============================================================================================================

/**
 * @param name string value of name to generate color
 * @returns string value of hex color
 * @example getColor("John Doe") => "#f5f5f5"
 */

export function generateAvatarColor(name: string): string {
  if (typeof name !== "string") return "";
  return getAvatarColor(name || "");
}

// ===============================================================================================================

/**
 * @param {string | null} duration Recieves string value of duration eg. 02:20:00
 * @returns {string} Returns hours and minutes eg. 02h:20m
 * @example
 *  let parsed_duration = parseTaskDuration("25:15:00");
 *  //  parsed_duration = "25h:15m";
 */
export function parseTaskDuration(duration: string | null): string {
  // Early exit
  if (duration === null) {
    return "";
  }

  // Getting hours and minutes
  let hours = (
    moment.duration(duration).hours() +
    moment.duration(duration).days() * 24
  ).toString();
  let minutes = moment.duration(duration).minutes().toString();

  // Adding zero
  const addZero = (value: string) => (+value <= 9 ? `0${value}` : value);

  // Final result
  let result = `${addZero(hours)}h:${addZero(minutes)}m`;

  return result;
}

// ===============================================================================================================

/**
 * @param {number} containerWidth Recieves number value of container width
 * @param {string} text Recieves string value of text
 * @param {object} style Recieves object value of style
 * @returns {string} Returns string value of html
 * @example
 * let html = renderStatistic(100, "100%", { fontSize: 12, fontFamily: "Roboto" });
 * // html = "<div style="width:100px;font-size:0.75em;line-height:0.75;">100%</div>"
 */
export function renderStatistic(containerWidth: number, text: string, style: any) {
  const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
  const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

  let scale = 0.75;

  if (containerWidth < textWidth) {
    scale = Math.min(
      Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))),
      1,
    );
  }

  const textStyleStr = `width:${containerWidth}px;`;
  return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
    scale < 1 ? 1 : "inherit"
  };">${text}</div>`;
}

// ===============================================================================================================

/**
 * @param {string | null | undefined} value Recieves string value of value
 * @param {number} numberOfDecimalPoints Recieves number value of number of decimal points
 * @param {boolean} percentage Recieves boolean value of percentage
 * @returns {string} Returns string value of parsed value
 * @example
 * let parsed = parseAnalyticsInfo("100.00", 2, true);
 * // parsed = "100,00%"
 */
export function parseAnalyticsInfo(
  value: string | null | undefined,
  numberOfDecimalPoints?: number,
  percentage?: boolean,
): string {
  // Early exit
  if (value === null) {
    return "";
  }

  if (percentage) {
    let num = Number(value);
    // Has decimal points
    if (num % 1 != 0) {
      return parseDecimalDots(num.toFixed(numberOfDecimalPoints)?.toString() || "0");
    }
    // Has no decimal points
    return parseDecimalDots(num?.toString() || "0");
  }

  let decimalPoints = numberOfDecimalPoints || 1;
  let tmp = Number(value).toFixed(decimalPoints).toString();

  let parseDots = parseDecimalDots(tmp);
  let parsed = `${parseDots.split(",")[0]},${tmp.split(".")[1]}`;

  return parsed;
}

// ===============================================================================================================

/**
 * @param {string | number | null | undefined} value Recieves string value of value
 * @param {number} numberOfDecimalPoints Recieves number value of number of decimal points
 * @returns {string} Returns string value of parsed value
 * @example
 * let parsed = parseAnalyticsTime("100.00");
 * // parsed = "100,00%"
 */
export function parseAnalyticsTime(
  value: string | number | null | undefined,
  numberOfDecimalPoints?: number,
): string {
  // Initial value
  let time = value;

  // Early exit
  if (value === null || value === undefined) {
    return "";
  }

  if (typeof value === "number") {
    time = value.toString();
  }

  let decimalPoints = numberOfDecimalPoints || 1;

  // Getting hours
  let hours = `${moment
    .duration(time)
    .asHours()
    .toFixed(decimalPoints)
    .toString()
    .replace(/\./g, ",")} h`;

  return hours;
}

// ===============================================================================================================

/**
 * @param {IAsset[]} data Recieves array of IAsset
 * @returns {IAsset[]} Returns array of IAsset
 * @description
 * This function is used to set parent_id and parent to null if parent_id is not in data
 */
export const setParentsToNull = (data: IAsset[]): IAsset[] => {
  return data.map((x) => {
    let check = x.parent_id ? (data.some((y) => y.id === x.parent_id) ? x.parent_id : null) : null;
    return { ...x, parent_id: check, parent: check ? x.parent : null };
  });
};

// ===============================================================================================================

/**
 * @param {IMaintenance[]} data Receives array of IMaintenance
 * @param {(number | null)} id Receives number value of id
 * @returns {IMaintenance[]} Returns array of IMaintenance
 * @description
 * This function filters maintenances where the user is an executor,
 * or reported the maintenance, or if the status is not "open".
 */
export const filterAllMineMaintenances = (
  data: IMaintenance[],
  id: number | null,
): IMaintenance[] => {
  return data?.filter((x) => {
    // Check if user is an executor or reported the maintenance,
    // or if the status is not "open".
    const isExecutor = x.executors.some((y) => y.account?.id === id);
    const isReporter = x.reported_by.id === id;
    // const isNotOpen = x.status !== "open";

    return isExecutor || isReporter;
  });
};

// ===============================================================================================================

export const inputNumberAdvancedParser = {
  decimalFormatter(value) {
    if (value) {
      const stringValue = value.toString().replace(".", ",");
      const [integerPart, decimalPart] = stringValue.split(",");

      // Only limit the decimal part if it exists and has more than 2 digits
      let limitedDecimalPart =
        decimalPart && decimalPart.length > 2 ? decimalPart.slice(0, 2) : decimalPart;

      return `${integerPart}${limitedDecimalPart ? `,${limitedDecimalPart}` : ""}`;
    }
    return "";
  },
  decimalParser(value) {
    const sanitizedValue = value.replace(/,/g, ".").replace(/[^\d.-]/g, "");
    return sanitizedValue ? parseFloat(sanitizedValue) : undefined;
  },
};

export const hasModule = (company: ICompany, modul: string): boolean => {
  return company.modules.some((x) => x.name === modul);
};

export const getFiltersForQuery = (filters: DynamicFiltersType | undefined) => {
  if (true) return {}; // privremena logika dok na accounte ne ubacimo dinamične filtere
  // let query: Record<string, string | null | boolean | number> = {};
  // for (const key in filters) {
  //   if (typeof filters[key] === "object" && filters[key] !== null) {
  //     if (Array.isArray(filters[key])) {
  //       //ako se radi o nizu
  //       query[key] = (filters[key] as string[]).join(",");
  //     } else {
  //       //ako se radi o datumu
  //       let value = filters[key] as any;
  //       if (value?.from) {
  //         query[`from|${key}`] = value.from;
  //       }
  //       if (value?.to) {
  //         query[`to|${key}`] = value.to;
  //       }
  //     }
  //   } else {
  //     query[key] = filters[key] as any;
  //   }
  // }
  // return query;
};

/**
 * Opens a new browser tab or window and displays a PDF document from base64 data.
 * @param base64Data The base64-encoded string representing the PDF data.
 * @param title The title of the new browser tab.
 * @param callback An optional callback function to be called after the PDF is loaded.
 */
export function openBase64PDF(
  base64Data: string | undefined,
  title: string,
  callback?: () => void,
): void {
  try {
    const pdfData = `data:application/pdf;base64,${base64Data}`;

    // Check if the browser is a mobile device
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    // Determine the target window features based on the device type
    const windowFeatures = isMobile ? "_system" : "_blank";

    // Open the new window
    const newWindow = window.open("", windowFeatures);

    if (newWindow) {
      newWindow.document.title = title;

      // Apply CSS styles to the body to remove margin
      newWindow.document.body.style.margin = "0";

      // Create embedded PDF element
      const embedElement = document.createElement("embed");
      embedElement.setAttribute("width", "100%");
      embedElement.setAttribute("height", "100%");
      embedElement.setAttribute("src", pdfData);
      embedElement.setAttribute("type", "application/pdf");

      // Append embedded PDF element to the document body
      newWindow.document.body.appendChild(embedElement);

      // Call the callback function after the PDF is loaded
      if (callback) {
        embedElement.onload = callback;
      }
    } else {
      throw new Error(
        "Failed to open a new tab/window. Please make sure pop-up blockers are disabled.",
      );
    }
  } catch (error) {
    console.error("Error opening PDF:", error);
  }
}

export function getLastItem<T>(arr: T[] | undefined): T | undefined {
  if (!arr) return undefined;
  return arr.length > 0 ? arr[arr.length - 1] : undefined;
}
