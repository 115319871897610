import { Button, Tabs } from "antd";
import { t } from "i18next";
import React from "react";
import { useSelector } from "react-redux";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { hasModule } from "../../helpers/functions";
import { Invoice } from "../../models/finance";
import { AppRoutes } from "../../navigation/routes";
import { RootState } from "../../store";
import { colors } from "../../styles/colors";
import { Link, Outlet, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { IClient, IRent } from "../../models/clients";
type TypeOfDrawers = {
  client: IClient | undefined;
  rentOrder: IRent | undefined;
  invoice: Invoice | undefined;
};
type ContextType = {
  addNew: TypeOfDrawers | undefined;
  set_addNew: React.Dispatch<React.SetStateAction<TypeOfDrawers | undefined>>;
};

const LOCAL_STORAGE_KEY = "tabKeyClients" + window.location.hostname;

const Clients: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.userReducer);
  const [addNew, set_addNew] = React.useState<TypeOfDrawers>();
  const [tabKey, set_tabKey] = React.useState<string>();
  const location = useLocation();

  const navigate = useNavigate();

  const onTabChange = (tabKey) => {
    //TODO
    set_addNew(undefined);
    set_tabKey(tabKey || "clients");
    navigate(`/app/clients/${tabKey === "clients" ? "" : tabKey}`, { replace: true });
    localStorage.setItem(LOCAL_STORAGE_KEY, tabKey);
  };

  const setActiveTab = async () => {
    console.log(location.pathname);
    let tab = await localStorage.getItem(LOCAL_STORAGE_KEY);
    if (!tab) {
      tab = "clients";
    }

    //here if active tab is rents we need set url to rents like i'm already clicked on rents
    if (tab === "rents") {
      navigate("/app/clients/rents", {
        replace: true,
      });
    } else if (tab === "invoices") {
      navigate("/app/clients/invoices", {
        replace: true,
      });
    }
    if (tab) {
      set_tabKey(tab || "clients");
    } else {
      set_tabKey("");
    }
  };

  React.useEffect(() => {
    setActiveTab();
  }, [location.pathname]);

  return (
    <>
      {/* Tabs */}
      {tabKey !== undefined && (
        <Tabs
          activeKey={tabKey}
          tabBarExtraContent={
            <Button
              type="primary"
              onClick={() => {
                set_addNew({
                  client:
                    tabKey === "clients"
                      ? {
                          custom_fields_v2: {},
                          custom_id: "",
                          id: 0,
                          name: "",
                          phone_number: "",
                          address: "",
                          description: "",
                          email: "",
                          is_archived: false,
                          is_active: true,
                        }
                      : undefined,
                  rentOrder:
                    tabKey !== "clients"
                      ? {
                          custom_id: "",
                          id: 0,
                          asset: undefined,
                          created_at: "",
                          closed_at: null,
                          created_by: null,
                          closed_by: null,
                          real_end: null,
                          order_type: "rent",
                          description: "",
                          status: "active",
                          location: {
                            id: 0,
                            name: "",
                          },
                          client: undefined,
                          rent_order_custom_fields_v2: {},
                          notes: [],
                          should_end_at: undefined,
                          start_at: undefined,
                          notify_days_before_end: undefined,
                        }
                      : undefined,
                  invoice:
                    tabKey === "invoices"
                      ? ({
                          currency: undefined,
                          days_to_due: undefined,
                          price: undefined,
                          rent_order: undefined,
                        } as any)
                      : undefined, // TODO: Define type
                });
              }}
            >
              {t(TRANSLATION_KEY.add)}
            </Button>
          }
          id="bordered-tabs"
          onTabClick={onTabChange}
        >
          {AppRoutes("allroutes")
            .children?.find((x) => x.path === "clients")
            ?.children?.filter((x) => {
              // Filter out invoices page if user doesn't have finance module
              if (!hasModule(user.account.company, "finances") && x.path === "invoices") {
                return false;
              }
              //provjeriti ukoliko nije definirana permisija more
              //ukoliko ima permisija viditi dali ima korisnik
              if (x.permissionCode === undefined) {
                return true;
              }
              return user.account.permissions.map((x) => x.codename).includes(x.permissionCode);
            })
            .map((x) => (
              <Tabs.TabPane
                tab={
                  <Link style={{ color: colors.globalFontColor }} to={x.path}>
                    {x.path === "rents" ? t(TRANSLATION_KEY.rentOrders) : t(x.path || "clients")}
                  </Link>
                }
                key={x.path || "clients"}
              />
            ))}
        </Tabs>
      )}
      <Outlet context={{ addNew, set_addNew } as ContextType} />
    </>
  );
};

export default Clients;

export function useDrawerToggle() {
  return useOutletContext<ContextType>();
}
