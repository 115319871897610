import { CheckOutlined, EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Space, Table, Tooltip } from "antd";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { AddInvoice, Invoice } from "../../models/finance";
import { CustomTableColumn } from "../ViewTabs";
import Columns from "../ViewTabs/Columns";
import useInvoices from "./useInvoices";
import { useDrawerToggle } from "../../pages/Clients";
import CloseInvoiceForm from "../../componentsform/CloseInvoiceForm";

type Props = {};

const InvoicesTable = (props: Props) => {
  // Hooks
  const invoices = useInvoices();
  const { set_addNew } = useDrawerToggle();

  // State
  const [selectedRow, setSelectedRow] = useState<Invoice | undefined>(undefined);

  // Effects
  useEffect(() => {
    invoices.fetchData();
  }, []);

  // State
  const [COLUMNS, set_COLUMNS] = useState<Array<Partial<CustomTableColumn>>>([
    {
      title: t(TRANSLATION_KEY.invoiceCode),
      dataIndex: "custom_id" as keyof Invoice,
      visible: 1,
    },
    {
      title: t(TRANSLATION_KEY.asset),
      dataIndex: "asset" as keyof Invoice,
      visible: 1,
    },
    {
      title: t(TRANSLATION_KEY.rentCode),
      dataIndex: "rent_order" as keyof Invoice,
      visible: 1,
    },
    {
      title: t(TRANSLATION_KEY.invoiceAmount),
      dataIndex: "price" as keyof Invoice,
      visible: 1,
    },
    {
      title: t(TRANSLATION_KEY.invoicePaidAmount),
      dataIndex: "paid_amount" as keyof Invoice,
      visible: 1,
    },
    {
      title: t(TRANSLATION_KEY.invoiceAmountLeft),
      dataIndex: "amount_left" as keyof Invoice,
      visible: 1,
    },
    {
      title: t(TRANSLATION_KEY.status),
      dataIndex: "status" as keyof Invoice,
      visible: 1,
    },
    {
      title: t(TRANSLATION_KEY.bankName),
      dataIndex: "" as keyof Invoice,
      visible: 1,
    },
    {
      title: t(TRANSLATION_KEY.dueDate),
      dataIndex: "due_date" as keyof Invoice,
      visible: 1,
    },
    {
      title: t(TRANSLATION_KEY.createdBy),
      dataIndex: "created_by" as keyof Invoice,
      visible: 1,
    },
    {
      title: t(TRANSLATION_KEY.closedBy),
      dataIndex: "closed_by" as keyof Invoice,
      visible: 1,
    },
    {
      title: t(TRANSLATION_KEY.action),
      dataIndex: "",
      visible: 1,
      // @ts-ignore
      render: (value, record) => {
        return (
          <Space size={8}>
            <Tooltip title={t(TRANSLATION_KEY.closeInvoice)}>
              <Button type="link" icon={<CheckOutlined />} onClick={() => setSelectedRow(record)} />
            </Tooltip>
            <Tooltip title={t(TRANSLATION_KEY.editInvoiceTooltip)}>
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() =>
                  set_addNew({
                    invoice: record,
                    client: undefined,
                    rentOrder: undefined,
                  })
                }
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ]);

  return (
    <Space direction="vertical" style={{ width: "100%" }} size="large">
      <Space style={{ width: "100%", justifyContent: "space-between" }}>
        {/* Left side - Columns */}
        <Space>
          <Columns columns={COLUMNS} set_COLUMNS={set_COLUMNS} />
        </Space>

        {/* Right side - filters */}
        <Space>
          {/* Info */}
          <Tooltip title={t(TRANSLATION_KEY.searchGoesByNameCodeAsset)}>
            <InfoCircleOutlined />
          </Tooltip>
          {/* Search */}
          <Input.Search style={{ width: 280 }} />
        </Space>
      </Space>
      {/* Table */}
      <Table
        size="small"
        loading={invoices.loading}
        dataSource={invoices.data}
        columns={COLUMNS.filter((x) => x.visible)}
        rowKey={(item) => item.id}
        pagination={false}
      />
      {/* Close invoice modal */}
      <Modal
        title={t(TRANSLATION_KEY.enterTransaction)}
        width={720}
        centered
        onCancel={() => setSelectedRow(undefined)}
        visible={!!selectedRow}
        footer={null}
        destroyOnClose
      >
        <CloseInvoiceForm invoice={selectedRow} />
      </Modal>
    </Space>
  );
};

export default InvoicesTable;
