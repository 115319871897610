import React, { useState, useEffect } from "react";

// Antd
import {
  Button,
  Drawer,
  Input,
  message,
  Modal,
  PageHeader,
  Spin,
  Table,
  TableColumnType,
  Tooltip,
  Typography,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

// Rest
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getSuppliersXHR } from "../../store/reducers/supplier/actionCreator";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { t } from "i18next";
import { ISupplier } from "../../models/supplier";
import { Link, useLocation } from "react-router-dom";
import SupplierForm from "../../componentsform/SupplierForm";
import SupplierModalPreview from "../supplier/components/SupplierModalPreview";
import { TableView } from "../../models/user";
import ViewTabs, {
  ColumnFilterType,
  CustomTableColumn,
  customTableColumnRender,
  filterColumns,
  saveTableDefinition,
} from "../../components/ViewTabs";
import Columns from "../../components/ViewTabs/Columns";

const STORAGE_COLUMNS_NAME = "SuppliersTable";

type TableRenders =
  | "SupplierName"
  | "SupplierCategory"
  | "SupplierDescription"
  | "SupplierPhone"
  | "SupplierAddress";

type Data = ISupplier & { search: string };

const mapData = (accs: ISupplier[]): Data[] => {
  return accs.map((x) => ({
    ...x,
    search:
      x.email?.toLowerCase() +
      x.name?.toLowerCase() +
      x.category?.name?.toLowerCase() +
      x.description?.toLowerCase() +
      x.phone_number?.toLowerCase() +
      x.address?.toLowerCase(),
  }));
};

const Suppliers: React.FC = () => {
  // Hooks
  const dispatch = useAppDispatch();
  const location = useLocation();

  // Variables
  const { getSuppliersStatus, suppliers } = useAppSelector((state) => state.supplierReducer);
  const [addSupplierVisible, set_addSupplierVisible] = useState<boolean>(false);
  const { user } = useAppSelector((state) => state.userReducer);
  const [search, set_search] = useState<string>("");
  const [data, set_data] = useState<Data[]>([]);
  const [modalVisible, set_modalVisible] = useState<boolean>(false);
  const [firstMount, set_firstMount] = useState<boolean>(true);
  const [tableDefinition, set_tableDefinition] = useState<
    Array<
      TableView & {
        updated: number;
        active: boolean;
      }
    >
  >(
    user.account.views?.suppliers?.map((x, i) => ({
      ...x,
      updated: new Date().getTime(),
      active: i === 0,
    })) || [],
  );
  const [pageSize, setPageSize] = useState(
    localStorage.getItem("suppliersTableViewPageSize") || 30,
  );
  const [selectedSupplier, set_selectedSupplier] = useState<string | number | undefined>(undefined);

  const [COLUMNS, set_COLUMNS] = React.useState<
    Array<Partial<CustomTableColumn> & TableColumnType<ISupplier>>
  >([]);

  useEffect(() => {
    if (firstMount) {
      set_firstMount(false);
      return;
    }
    let tmpDefinitions =
      user.account.views?.suppliers?.map((x, i) => ({
        ...x,
        updated: new Date().getTime(),
        active: i === 0,
      })) || [];
    set_tableDefinition(tmpDefinitions);
  }, [user]);
  // Methods
  useEffect(() => {
    try {
      let activeView = tableDefinition.find((x) => x.active);
      if (activeView) {
        setColumns(activeView.table_structure, 0);
      }
    } catch (e) {
      console.log(tableDefinition);
      alert("Error");
      console.log(e);
    }

    if (suppliers.length === 0) {
      getSuppliersXHR({}, dispatch);
    }
  }, []);

  useEffect(() => {
    set_data(mapData(suppliers));
  }, [suppliers]);

  function setPageSizeToLocalStorage(size: number) {
    setPageSize(size);
    localStorage.setItem("suppliersTableViewPageSize", size.toString());
  }

  let filtredData: Data[] = [];

  if (search) {
    filtredData = data?.filter((x) => x.search.includes(search));
  } else {
    filtredData = data;
  }
  const render = (key: TableRenders) => {
    let tmp: Record<TableRenders, any> = {
      SupplierName: (text: string, value: ISupplier) => (
        <Link to={`${value.id}`}>{value.name}</Link>
      ),
      SupplierCategory: (text: string, value: ISupplier) => (
        <Typography.Text>{t(value.category?.name || "")}</Typography.Text>
      ),
      SupplierDescription: (text: string, value: ISupplier) => (
        <Typography.Text>{t(value.description)}</Typography.Text>
      ),
      SupplierAddress: (text: string, value: ISupplier) => (
        <Typography.Text>{t(value.address)}</Typography.Text>
      ),
      SupplierPhone: (text: string, value: ISupplier) => (
        <Typography.Text>{t(value.phone_number)}</Typography.Text>
      ),
    };
    return tmp[key];
  };

  const setColumns = (_c: CustomTableColumn[], viewIndex: number) => {
    // ostaje unutar komponente za koju se prave views-ovi iz razloga jer će trebati filtere okidati
    let tmp: Array<CustomTableColumn & TableColumnType<ISupplier>> = [];
    _c.forEach((c) => {
      tmp.push({
        ...c,
        title: t(c.title),
        render: c.columnRenderComponent
          ? render(c.columnRenderComponent as TableRenders)
          : customTableColumnRender(
              c.filterComponent as ColumnFilterType,
              c.dataIndex,
              user,
              "custom_fields_v2",
            ),
        onCell: (record: ISupplier) => {
          return { rowSpan: 1 };
        },
        filterDropdown: undefined,
      });
    });
    set_COLUMNS(tmp);
  };

  return (
    <div>
      {/* Page header */}
      <PageHeader
        style={{ padding: 0, paddingTop: 10, marginBottom: 2 }}
        title={t(TRANSLATION_KEY.suppliers)}
        extra={[
          <Button onClick={() => set_addSupplierVisible(true)} type="primary">
            {t(TRANSLATION_KEY.add)}
          </Button>,
        ]}
      />
      <div>
        <div>
          <ViewTabs
            onChange={(activeKey) => {
              let index = tableDefinition.findIndex((view) => view.name === activeKey);
              let tmpList = [...tableDefinition];
              if (index === -1) {
                message.error("view_onSaveColumnsDefinition");
                return;
              }
              tmpList = tmpList.map((x) => ({ ...x, active: false }));
              tmpList[index] = { ...tmpList[index], active: true };
              // getSuppliersXHR({}, dispatch);
              setColumns(tmpList[index].table_structure, index);
              set_tableDefinition(tmpList);
            }}
            setColumns={setColumns}
            views={tableDefinition}
            viewCategory="suppliers"
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "18px 0",
        }}
      >
        <div>
          {!firstMount && (
            <Columns
              columns={COLUMNS}
              set_COLUMNS={(columns) => {
                let index = tableDefinition.findIndex((view) => view.active === true);
                if (index === -1) {
                  message.error("view");
                  return;
                }
                let tmp = tableDefinition[index];
                if (!tmp) {
                  return;
                }

                let inerTmp: CustomTableColumn[] = [];
                columns.forEach((x) => {
                  inerTmp.push({
                    title: x.title || "",
                    dataIndex: x.dataIndex || "",
                    visible: x.visible === undefined ? 1 : x.visible,
                    onCellFlag: x.onCellFlag || false,
                    filterComponent: x.filterComponent || null,
                    columnRenderComponent: x.columnRenderComponent || null,
                    objectKey: x.objectKey || null,
                  });
                });
                tmp = { ...tmp, table_structure: inerTmp, updated: Date.now(), active: true };
                let tmpTableDefinition = [...tableDefinition].map((x) => ({ ...x, active: false }));
                tmpTableDefinition[index] = tmp;
                setColumns(inerTmp, index);
                set_tableDefinition(tmpTableDefinition);
              }}
            />
          )}

          <Button
            style={{ marginLeft: 18 }}
            type="link"
            onClick={() => {
              let activeView = tableDefinition.find((x) => x.active);
              saveTableDefinition(
                tableDefinition,
                user.account.views?.suppliers?.find((x) => x.id === activeView?.id),
                "suppliers",
              );
            }}
          >
            {t(TRANSLATION_KEY.saveChanges)}
          </Button>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tooltip title={t(TRANSLATION_KEY.searchGoesByNameCategory)}>
            <InfoCircleOutlined />
          </Tooltip>
          <div style={{ width: 12 }} />

          <Input.Search
            onChange={({ target: { value } }) => {
              set_search(value.toLowerCase());
            }}
            allowClear
          />
        </div>
      </div>

      <Spin spinning={getSuppliersStatus === "loading"}>
        <Table
          size="small"
          rowKey={(item) => item.id}
          dataSource={filtredData}
          columns={filterColumns<ISupplier>(COLUMNS)}
          pagination={{
            defaultPageSize: Number(pageSize),
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "50", "100"],
            onChange(page, pageSize) {
              setPageSizeToLocalStorage(pageSize);
            },
          }}
        />
      </Spin>

      <Drawer
        title={t(TRANSLATION_KEY.addSupplier)}
        width={540}
        destroyOnClose={true}
        children={<SupplierForm supplier={null} close={() => set_addSupplierVisible(false)} />}
        visible={addSupplierVisible}
        onClose={() => set_addSupplierVisible(false)}
      />

      {/* Modal */}
      <Modal
        visible={modalVisible}
        onCancel={() => {
          set_modalVisible(false);
          set_selectedSupplier(undefined);
        }}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        <SupplierModalPreview id={selectedSupplier} />
      </Modal>
    </div>
  );
};

export default Suppliers;
