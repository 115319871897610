import { PageHeader, Tabs } from "antd";
import { t } from "i18next";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { hasModule } from "../../helpers/functions";
import { AppRoutes } from "../../navigation/routes";
import { RootState } from "../../store";

interface IProps {}

const { TabPane } = Tabs;

const Analytics: React.FC<IProps> = () => {
  // Hooks
  const navigate = useNavigate();
  const location = useLocation();

  // State
  const { user } = useSelector((state: RootState) => state.userReducer);

  // Functions
  const onTabChange = (path: string) => {
    navigate(`${path}`, { replace: false });
  };

  // Derived state
  let activeKey = location.pathname.split("/")[3] || "";
  const userHasModuleClient = useMemo(() => hasModule(user.account.company, "clients"), [user]);

  if (["wo-time", "wo-costs", "wo-success"].includes(activeKey)) {
    activeKey = "";
  }

  return (
    <div>
      {/* Page header */}
      <PageHeader
        style={{ padding: 0, paddingTop: 10, marginBottom: 20 }}
        title={t(TRANSLATION_KEY.analytics)}
        footer={
          <Tabs id="bordered-tabs" activeKey={activeKey} onChange={onTabChange}>
            {AppRoutes("allroutes")
              .children?.find((x) => x.path === "analytics")
              ?.children?.filter((x) => {
                // Filter out client-analytics page if user doesn't have clients module
                if (!userHasModuleClient && x.path === "client-analytics") {
                  return false;
                }
                //provjeriti ukoliko nije definirana permisija more
                //ukoliko ima permisija viditi dali ima korisnik
                if (x.permissionCode === undefined) {
                  return true;
                }
                return user.account.permissions.map((x) => x.codename).includes(x.permissionCode);
              })
              ?.map((x) => (
                <TabPane
                  tab={
                    <Link to={x.path} style={{ fontSize: 14 }}>
                      {t(x.path || "maintenancesanalytics")}
                    </Link>
                  }
                  key={x.path}
                />
              ))}
          </Tabs>
        }
      />

      {/* Rendering routes */}
      <Outlet />
    </div>
  );
};

export default Analytics;
