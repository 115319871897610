import React from "react";
import { Button, Divider, List, Space, Tag, Typography } from "antd";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { IPartStorage } from "../../../models/parts";
import { parseCosts, parseDecimalDots } from "../../../helpers/functions";
import { IUser } from "../../../models/user";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { useAppSelector } from "../../../hooks";

type IProps = {
  data: IPartStorage[];
  onClick: (item: IPartStorage) => void;
};

const WarehouseList = ({ data, onClick }: IProps) => {
  const { user } = useAppSelector((state: RootState) => state.userReducer);

  return (
    <div className="white-container" style={{ minHeight: 240, height: "100%" }}>
      {/* Title */}
      <Typography.Title level={5} style={{ paddingTop: 14 }}>
        {t(TRANSLATION_KEY.warehouse)}
      </Typography.Title>

      {/* Divider */}
      <Divider />

      {/* List */}
      <List
        dataSource={data}
        renderItem={(item: IPartStorage) => (
          <List.Item
            style={{ padding: 6 }}
            extra={
              <Button type="link" onClick={() => onClick(item)}>
                {t(TRANSLATION_KEY.spend)}
              </Button>
            }
          >
            <List.Item.Meta
              title={
                <Space direction="vertical">
                  <Space>
                    <Typography.Text strong>#{item.storage?.custom_id || ""}</Typography.Text>
                    <Typography.Text type="secondary">{item.storage.path}</Typography.Text>
                  </Space>
                  <Space size={32}>
                    <Typography.Text strong>{item.storage.name}</Typography.Text>
                    <Space>
                      <Tag className="tag-chip">
                        {`${parseDecimalDots(item.qty)} ${t(item.uom)}`}
                      </Tag>
                      <Tag className="tag-chip">
                        {`${parseCosts(item.storage_total_price)} ${
                          user?.account.company.currency
                        }`}
                      </Tag>
                    </Space>
                  </Space>
                </Space>
              }
              description={item.storage.location?.name || undefined}
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default WarehouseList;
