import { message, Select } from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DatePickerCustom, { CalendarTypes } from "../../../../../components/DatePickerCustom";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { IAccount } from "../../../../../models/user";
import { accountsXHR } from "../../../../../store/reducers/accounts/actionCreators";

interface IProps {
  onChange: (filters: IFilters) => void;
}

export interface IFilters {
  calendarType: CalendarTypes | undefined;
  start: string | undefined;
  end: string | undefined;
  maintenance_categories: number[];
  maintenance_type: number[];
  executors: number[] | undefined;
}

const ReportTwoFilters: React.FC<IProps> = ({ onChange }) => {
  // State
  const { maintenancesTypes, maintenanceCategories } = useAppSelector(
    (state) => state.maintenanceReducer,
  );
  const { user } = useAppSelector((state) => state.userReducer);
  let [searchParams, setSearchParams] = useSearchParams();
  const [localAccounts, set_localAccounts] = useState<IAccount[]>([]);
  const [calendarVisible, set_calendarVisible] = useState(false);
  const [firstRender, set_firstRender] = useState(true);
  const dispatch = useAppDispatch();

  const [filters, set_filters] = useState<IFilters>({
    calendarType: (searchParams.get("calendarType") as CalendarTypes) || "month",
    start: searchParams.get("start") || moment().startOf("month").toISOString(true),
    end: searchParams.get("end") || moment().endOf("month").toISOString(true),
    maintenance_categories:
      searchParams
        .get("maintenance_categories")
        ?.split("|")
        .map((x) => +x) || [],
    maintenance_type:
      searchParams
        .get("maintenance_type")
        ?.split("|")
        .map((x) => +x) || [],
    executors:
      searchParams
        .get("executors")
        ?.split("|")
        .map((x) => +x) || [],
  });

  // Effects
  useEffect(() => {
    accountsXHR(
      {
        errorCallback: (error: any) => {
          console.log(error);
          message.error(t(TRANSLATION_KEY.errorOnGetData));
        },
        queryParams: {
          ordering: "asc",
          limit: 9999,
          order_by: "id",
        },
        successCallback: (res) => {
          if (res.results) {
            set_localAccounts(res.results);
          }
        },
      },
      dispatch,
    );
    setFilters();
    setTimeout(() => {
      set_calendarVisible(true);
    }, 210);
  }, []);

  useEffect(() => {
    if (filters && !firstRender) {
      localStorage.setItem(
        window.location.pathname + `|${user.account.id}|${user.account.name}`,
        JSON.stringify(filters),
      );
      onChange(filters);
    }
    set_firstRender(false);
  }, [filters]);

  // Functions
  const setFilters = async () => {
    let filters: string | null = await localStorage.getItem(
      window.location.pathname + `|${user.account.id}|${user.account.name}`,
    );

    if (!filters) {
      set_filters({
        calendarType: "month",
        start: moment().startOf("month").toISOString(true),
        end: moment().endOf("month").toISOString(true),
        maintenance_categories: [],
        maintenance_type: [],
        executors: [],
      });
    } else {
      let tmp: IFilters = JSON.parse(filters);

      set_filters(tmp);
    }
  };

  let content = filters && (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        padding: "12px",
        background: "white",
        justifyContent: "stretch",
        borderRadius: 4,
        gap: 12,
        border: "1px solid #dedede",
      }}
    >
      <Select
        onChange={(e) =>
          set_filters({
            ...filters,
            executors: e,
          })
        }
        value={filters.executors}
        defaultValue={filters.executors}
        onClear={() => set_filters({ ...filters, executors: [] })}
        placeholder={t(TRANSLATION_KEY.executors)}
        style={{ overflow: "auto", width: "16%", maxWidth: 224 }}
        allowClear={true}
        mode="multiple"
        maxTagCount="responsive"
        showSearch
        optionFilterProp="name"
        filterOption={(input, option: any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {localAccounts.map((item, index) => (
          <Select.Option key={index} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
      <Select
        onChange={(e) => {
          set_filters({
            ...filters,
            maintenance_categories: e,
          });
        }}
        value={filters.maintenance_categories}
        onClear={() => {
          set_filters({ ...filters, maintenance_categories: [] });
        }}
        placeholder={t(TRANSLATION_KEY.maintenanceCategory)}
        style={{ overflow: "auto", width: "16%", maxWidth: 224 }}
        allowClear={true}
        mode="multiple"
        maxTagCount="responsive"
        showSearch
        optionFilterProp="name"
        filterOption={(input, option: any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {maintenanceCategories.map((item, index) => (
          <Select.Option key={index} value={item.id}>
            {t(item.trans_key || item.name)}
          </Select.Option>
        ))}
      </Select>
      <Select
        onChange={(e) => {
          set_filters({
            ...filters,
            maintenance_type: e,
          });
        }}
        value={filters.maintenance_type}
        onClear={() => {
          set_filters({ ...filters, maintenance_type: [] });
        }}
        placeholder={t(TRANSLATION_KEY.maintenanceTypes)}
        style={{ overflow: "auto", width: "16%", maxWidth: 224 }}
        allowClear={true}
        mode="multiple"
        maxTagCount="responsive"
        showSearch
        optionFilterProp="name"
        filterOption={(input, option: any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {maintenancesTypes.map((item, index) => (
          <Select.Option key={index} value={item.id}>
            {t(item.trans_key || item.name)}
          </Select.Option>
        ))}
      </Select>

      <div style={{ flex: 1 }} />
      {calendarVisible && (
        <DatePickerCustom
          selectStyle={{ marginRight: 12 }}
          pickerStyle={{ maxWidth: 224 }}
          start={filters.start}
          end={filters.end}
          type={filters.calendarType || "month"}
          skipInitial
          onChange={(start, end, type) => {
            set_filters({
              ...filters,
              start,
              end,
              calendarType: type || "month",
            });
          }}
        />
      )}
    </div>
  );

  return content;
};

export default ReportTwoFilters;
