import { Form, FormInstance, Select, SelectProps, Spin, message } from "antd";
import { t } from "i18next";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { TRANSLATION_KEY } from "../helpers/consts";
import { debounce } from "../helpers/functions";
import { useAppDispatch, useAppSelector } from "../hooks";
import { ILoadMoreParams } from "../models";
import { IClient, IRent } from "../models/clients";
import {
  getClientXHR,
  getClientsXHR,
  getRentOrderDetailsXHR,
  getRentedAssetsXHR,
} from "../store/reducers/clients/actionCreators";
import { RENTS_SIZE_LIMIT } from "../pages/Clients/pages/AllRents";

interface IProps {
  // Antd Select props
  selectProps: SelectProps;
  // Form props
  name: string;
  label: string;
  // Rest
  includeInactive: boolean;
  required?: boolean;
  form: FormInstance | undefined;
  excludeWithoutClient?: boolean;
}

interface ExposedFunctions {
  fetchMissing: (missingId: number) => void;
}

const RentOrderSelect: React.ForwardRefRenderFunction<ExposedFunctions, IProps> = (
  {
    selectProps,
    // Form props
    name,
    label,
    // Rest
    required,
    includeInactive,
  },
  ref,
) => {
  // Hooks
  const dispatch = useAppDispatch();

  // State
  const [fetchStatus, setFetchStatus] = useState("");
  const [search, setSearch] = React.useState<string>("");
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [rentOrders, setRentOrders] = useState<IRent[]>([]);
  const { getRentedAssetsStatus } = useAppSelector((state) => state.clientsReducer);
  const [loadMoreParams, setLoadMoreParams] = useState<ILoadMoreParams>({
    offset: 0,
    limit: RENTS_SIZE_LIMIT,
  });

  // Effects
  useEffect(() => {
    getRentOrders("", loadMoreParams, false);
  }, []);

  useEffect(() => {
    if (Array.isArray(selectProps.value)) {
      if (selectProps.value.length === 0) return;
      let missings: number[] = [];
      selectProps.value.forEach((val) => {
        if (!rentOrders.find((x) => x.id === val)) {
          missings.push(val);
        }
      });
      missings.forEach((missingId) => {
        callMissingRentOrder(missingId);
      });
    } else if (selectProps.value) {
      if (!rentOrders.find((x) => x.id === selectProps.value)) {
        callMissingRentOrder(selectProps.value);
      }
    }
  }, [selectProps.value]);

  // Functions
  const getRentOrders = (search: string, loadMoreParams: ILoadMoreParams, mergeData: boolean) => {
    setFetchStatus("loading");
    getRentedAssetsXHR(
      {
        loading: "selectLoading",
        noSaveToStore: true,
        mergeData,
        queryParams: {
          search,
          offset: loadMoreParams.offset,
          limit: loadMoreParams.limit,
          clients: undefined,
          status: undefined, // TODO: Validate this
          is_active: includeInactive ? "true,false" : "true",
        },
        successCallback: (res) => {
          if (!res.results) return;
          if (mergeData) {
            setRentOrders([...rentOrders, ...res.results]);
          } else {
            setRentOrders(res.results);
          }
          setLoadMoreParams({
            ...loadMoreParams,
            offset: loadMoreParams.offset + RENTS_SIZE_LIMIT,
          });
          if (res.results && res.results?.length < RENTS_SIZE_LIMIT) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
          setFetchStatus("");
        },
        errorCallback: () => {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
          setFetchStatus("");
        },
      },
      dispatch,
    );
  };

  const debouncedGetClientAsset = useCallback(
    debounce<typeof getRentOrders>(getRentOrders, 300),
    [],
  );

  const handlePopupScroll = (e) => {
    if (!hasMore) return;
    const { target } = e;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      // Reached the bottom of the dropdown, load more options
      getRentOrders(search, loadMoreParams, true);
      // Simulate loading for demonstration purpose
    }
  };

  const callMissingRentOrder = (missingId: number) => {
    getRentOrderDetailsXHR(
      {
        loading: "selectLoading",
        id: missingId,
        successCallback: (res) => {
          let tmpRentOrders = [...rentOrders];
          tmpRentOrders.push({
            asset: res.results?.asset || undefined,
            client: res.results?.client || undefined,
            closed_at: res.results?.closed_at || null,
            closed_by: res.results?.closed_by || null,
            created_at: res.results?.created_at || "",
            created_by: res.results?.created_by || null,
            custom_id: res.results?.custom_id || "",
            description: res.results?.description || "",
            id: res.results?.id || -1,
            notes: res.results?.notes || [],
            location: res.results?.location || { id: 0, name: "" },
            order_type: res.results?.order_type || "rent",
            real_end: res.results?.real_end || null,
            rent_order_custom_fields_v2: res.results?.rent_order_custom_fields_v2 || {},
            status: res.results?.status || "active",
            start_at: res.results?.start_at || "",
            should_end_at: res.results?.should_end_at || "",
            notify_days_before_end: res.results?.notify_days_before_end || 0,
          });
          setRentOrders(tmpRentOrders);
        },
      },
      dispatch,
    );
  };

  useImperativeHandle(ref, () => ({
    fetchMissing: (missingId: number) => {
      if (rentOrders.find((x) => x.id === missingId)) return;
      callMissingRentOrder(missingId);
    },
  }));

  const select = useMemo(
    () => (
      <Select
        {...selectProps}
        filterOption={false}
        loading={fetchStatus === "loading"}
        onBlur={() => {
          getRentOrders("", { ...loadMoreParams, offset: 0 }, false);
        }}
        onSearch={(value) => {
          setSearch(value);
          debouncedGetClientAsset(value, { ...loadMoreParams, offset: 0 }, false);
        }}
        allowClear
        showSearch
        onPopupScroll={handlePopupScroll}
        options={rentOrders.map((x) => ({ label: x.custom_id, value: x.id }))}
      />
    ),
    [
      rentOrders,
      fetchStatus,
      debouncedGetClientAsset,
      handlePopupScroll,
      loadMoreParams,
      selectProps,
    ],
  );

  // Bare select
  if (!name) {
    return <Spin spinning={getRentedAssetsStatus === "selectLoading"}>{select}</Spin>;
  }

  // Form select
  return (
    <Spin spinning={getRentedAssetsStatus === "selectLoading"}>
      <Form.Item
        rules={[
          {
            required: required,
            message: t(TRANSLATION_KEY.filedRequired),
          },
        ]}
        label={label}
        name={name}
      >
        {select}
      </Form.Item>
    </Spin>
  );
};
export default forwardRef(RentOrderSelect);
