import { Button, Checkbox, Form, FormInstance, FormProps, Radio } from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { AnimateChangeInHeight } from "../../components/AnimatedChangeInHeight";
import useInvoices from "../../components/InvoicesTable/useInvoices";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { Invoice, InvoicePayType } from "../../models/finance";
import InvoiceCashForm from "./CashForm";

type Props = {
  invoiceForm?: FormInstance<any>;
  invoice: Invoice | undefined;
};

const CloseInvoiceForm = ({ invoiceForm, invoice }: Props) => {
  // Hooks
  const { add, update } = useInvoices();
  const [form] = Form.useForm();

  // State
  let isInInvoiceForm = !!invoiceForm;
  const [invoicePayType, setInvoicePayType] = useState<InvoicePayType>("cash");

  // Functions
  const onFinish = (values: any) => {
    let body = {
      ...values,
      currency: invoice?.currency,
    };
    console.log("On finish", body);
  };

  const Wrapper = useMemo(() => {
    if (isInInvoiceForm) {
      return React.Fragment;
    }
    return Form;
  }, [isInInvoiceForm]);

  const WrapperProps = useMemo(() => {
    if (isInInvoiceForm) {
      return {};
    }

    let initialValues = {
      cash_form: [{ amount: undefined, pay_date: moment() }],
    };

    return {
      form: invoiceForm ?? form,
      initialValues,
      onFinish,
      layout: "vertical",
      size: "large",
      style: { width: "100%" },
    } as FormProps;
  }, [isInInvoiceForm, invoiceForm, form, onFinish]);

  const FormMap: Record<InvoicePayType, React.ReactNode> = {
    cash: <InvoiceCashForm invoice={invoice} />,
    card_manual: <div>Card Manual</div>, // TODO: Implement Card Manual Form
    card_automatic: <div>Card Automatic</div>, // TODO: Implement Card Automatic Form
  };

  return (
    <Wrapper {...WrapperProps}>
      <Radio.Group
        value={invoicePayType}
        size="middle"
        buttonStyle="solid"
        style={{ width: "100%", marginBottom: 20 }}
        onChange={(e) => setInvoicePayType(e.target.value)}
      >
        <Radio.Button
          style={{ width: "33%", textAlign: "center" }}
          value={"cash" as InvoicePayType}
        >
          cash
        </Radio.Button>
        <Radio.Button
          style={{ width: "33%", textAlign: "center" }}
          value={"card_manual" as InvoicePayType}
        >
          card_manual
        </Radio.Button>
        <Radio.Button
          style={{ width: "33%", textAlign: "center" }}
          value={"card_automatic" as InvoicePayType}
        >
          card_automatic
        </Radio.Button>
      </Radio.Group>

      {/* Form */}
      <AnimateChangeInHeight>{FormMap[invoicePayType]}</AnimateChangeInHeight>

      {/* Field visible in all forms */}
      <Form.Item name="close_invoice" valuePropName="checked">
        <Checkbox>{t(TRANSLATION_KEY.closeInvoice)}</Checkbox>
      </Form.Item>

      {!isInInvoiceForm && (
        <Form.Item>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button htmlType="submit" type="primary">
              {t(TRANSLATION_KEY.save)}
            </Button>
          </div>
        </Form.Item>
      )}
    </Wrapper>
  );
};

export default CloseInvoiceForm;
