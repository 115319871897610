import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import moment, { Moment } from "moment";
import { t } from "i18next";

// Antd
import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, Modal, Space, Table, Tooltip, Typography } from "antd";

// Rest
import Columns from "../../components/Columns";
import LoadMoreButton from "../../components/LoadMoreButton";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { hasModule, parseCosts, sortTableColumns } from "../../helpers/functions";
import { ICursor, ITableColumn } from "../../models";
import { IMaintenance } from "../../models/maintenances";
import styles from "./styles.module.css";
import MaintenanceModalPreview from "../../pages/maintenance/components/ModalPreview";
import AccountModalPreview from "../../pages/account/components/AccountModalPreview";
import PreviewButton from "../PreviewButton";
import AssetModalPreview from "../../pages/assetdetails/components/AssetModalPreview";
import DatePickerCustom, { CalendarTypes } from "../DatePickerCustom";
import { useAppSelector } from "../../hooks";
import ClientTableItem from "../ClientTableItem";

// Constants
const STORAGE_COLUMNS_NAME = "MaintenancesTable";
const { RangePicker } = DatePicker;

// Props
interface IProps {
  data: IMaintenance[];
  loading: boolean;
  showOpenCalendarButton?: boolean;
  handleOpenCalendar?: any;
  onDateChanged: (
    start: string | undefined,
    end: string | undefined,
    type: CalendarTypes | undefined,
  ) => void;
  onLoadMore: () => void;
  onSearch: (search: string) => void;
  cursor?: ICursor;
  start: string | undefined;
  end: string | undefined;
  working_data_show?: boolean;
  hideHeader?: boolean;
}

const MaintenancesTable: React.FC<IProps> = ({
  hideHeader,
  data,
  loading,
  showOpenCalendarButton = false,
  handleOpenCalendar,
  onDateChanged,
  onLoadMore,
  onSearch,
  cursor,
  start,
  end,
  working_data_show,
}) => {
  // Hooks
  const location = useLocation();

  // Variables
  const [modalVisible, set_modalVisible] = useState<boolean>(false);
  const currency = useAppSelector((state) => state.userReducer.user.account.company.currency);
  const [selectedMaintenance, set_selectedMaintenance] = useState<string | number | undefined>(
    undefined,
  );
  const { user } = useAppSelector((state) => state.userReducer);
  const [selectedAccount, set_selectedAccount] = useState<string | number | undefined>(undefined);
  const [selectedAsset, set_selectedAsset] = useState<string | number | undefined>(undefined);
  const [COLUMNS, set_COLUMNS] = useState<ITableColumn<IMaintenance>[]>([
    {
      title: t(TRANSLATION_KEY.orderNumber),
      dataIndex: "order_number",
      visible: true,
      render: (text: string, value: IMaintenance) => (
        <PreviewButton
          isActive={value.is_active}
          title={value.order_number}
          id={value.id}
          url={`/app/maintenances/${value.id}/`}
          onClick={() => {
            set_modalVisible(true);
            set_selectedMaintenance(value.id);
          }}
        />
      ),
    },
    {
      title: t(TRANSLATION_KEY.description),
      dataIndex: "description",
      visible: true,
      render(text, value, index) {
        return (
          <Typography.Text style={{ maxWidth: 320 }} ellipsis={{ tooltip: text }}>
            {text}
          </Typography.Text>
        );
      },
    },
    {
      title: t(TRANSLATION_KEY.asset),
      dataIndex: "asset",
      visible: true,
      render: (text: string, value: IMaintenance) => {
        if (!value.asset) {
          return <Typography.Text>{t(TRANSLATION_KEY.withoutAsset)}</Typography.Text>;
        }

        return (
          <Space direction="vertical" size={0}>
            <PreviewButton
              isActive={value.asset?.is_active}
              title={value.asset?.name}
              id={value.id}
              url={`/app/asset-details/${value.asset?.id}/`}
              permissionsRequired={["view_asset"]}
              onClick={() => {
                set_modalVisible(true);
                set_selectedAsset(value.asset?.id);
              }}
            />
            {value.asset?.parent_id && (
              <Typography.Text
                type="secondary"
                style={{ maxWidth: 320 }}
                ellipsis={{ tooltip: value?.asset.path }}
              >
                {value?.asset.path}
              </Typography.Text>
            )}
          </Space>
        );
      },
    },
    {
      title: t(TRANSLATION_KEY.status),
      dataIndex: "status",
      visible: true,
      render: (text: string) => <span>{t(text)}</span>,
    },
    {
      title: t(TRANSLATION_KEY.location),
      dataIndex: "location",
      visible: true,
      render: (text: string, value: IMaintenance) => <span>{value.location?.name || "-"}</span>,
    },
    {
      title: t(TRANSLATION_KEY.reportedBy),
      dataIndex: "reportedBy",
      visible: true,
      render: (text: string, value: IMaintenance) => (
        <div>
          <PreviewButton
            isActive={value.reported_by.is_active}
            title={value.reported_by.name}
            id={value.reported_by.id}
            url={`/app/humanresources/${value.reported_by.id}`}
            permissionsRequired={["manage_user"]}
            onClick={() => {
              set_modalVisible(true);
              set_selectedAccount(value.reported_by.id);
            }}
          />
          <Typography.Text type="secondary">
            {moment(value.created_at).format(user.account.date_format || "LLL")}
          </Typography.Text>
        </div>
      ),
    },
  ]);

  useEffect(() => {
    let tmp = [...COLUMNS];
    //prikazati dodatne kolone
    if (working_data_show) {
      tmp.push({
        title: t(TRANSLATION_KEY.workingTime),
        dataIndex: "executor_working_time",
        visible: true,
        render: (text: string, value: IMaintenance) => (
          <span>{value.executor_working_time ? value.executor_working_time.toString() : "-"}</span>
        ),
      });
      tmp.push({
        title: t(TRANSLATION_KEY.workingCosts),
        dataIndex: "executor_working_price",
        visible: true,
        render: (text: string, value: IMaintenance) => (
          <span>
            {parseCosts(
              value.executor_working_price ? value.executor_working_price.toString() : "0",
            ) +
              " " +
              currency}
          </span>
        ),
      });
      if (hasModule(user.account.company, "clients")) {
        tmp.push({
          title: t(TRANSLATION_KEY.client),
          dataIndex: "client",
          visible: true,
          render: (text: string, value: IMaintenance) => (
            <ClientTableItem
              is_active={value.client?.is_active || false}
              name={value.client?.name}
              id={value.client?.id}
            />
          ),
        });
      }
      set_COLUMNS(tmp);
    }

    sortTableColumns(tmp, STORAGE_COLUMNS_NAME + location.pathname, set_COLUMNS);
  }, []);

  return (
    <>
      {/* Header */}
      {!hideHeader && (
        <div className={styles.headerContainer}>
          {/* Columns */}
          <div className={styles.headerFlexSpaceBetween}>
            <Columns
              columns={COLUMNS}
              memorizedName={STORAGE_COLUMNS_NAME + location.pathname}
              onChange={set_COLUMNS}
            />
            {/* Calendar button */}
            {showOpenCalendarButton && (
              <Button onClick={handleOpenCalendar} type="primary" style={{ marginLeft: 12 }}>
                {t(TRANSLATION_KEY.openCalendar)}
              </Button>
            )}
          </div>
          <div className={styles.headerFlexSpaceBetween}>
            <div className={styles.headerFlexSpaceBetween}>
              {/* Date picker */}
              <DatePickerCustom
                start={start}
                end={end}
                pickerStyle={{ marginRight: 12 }}
                selectStyle={{ marginRight: 12 }}
                type="month"
                onChange={onDateChanged}
                skipInitial={false}
              />

              {/* Info */}
              <Tooltip title={t(TRANSLATION_KEY.searchGoesByNameCodeAsset)}>
                <InfoCircleOutlined />
              </Tooltip>
              {/* Search */}
              <Input.Search
                onChange={({ target: { value } }) => onSearch(value.toLowerCase())}
                style={{ marginRight: 18, marginLeft: 12, width: 180 }}
              />
            </div>
          </div>
        </div>
      )}

      {/* Table */}
      <Table
        size="small"
        loading={loading}
        dataSource={data}
        columns={COLUMNS.filter((x) => x.visible).map((item) => ({
          ...item,
          title: t(item.title),
        }))}
        rowKey={(item) => item.id}
        pagination={false}
      />

      {/* Load more button */}
      {cursor?.next && <LoadMoreButton loading={loading} onClick={onLoadMore} />}

      {/* Modal */}
      <Modal
        visible={modalVisible}
        onCancel={() => {
          set_modalVisible(false);
          set_selectedMaintenance(undefined);
          set_selectedAccount(undefined);
        }}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        {selectedMaintenance && <MaintenanceModalPreview id={selectedMaintenance} />}
        {selectedAccount && <AccountModalPreview id={selectedAccount} />}
        {selectedAsset && <AssetModalPreview id={selectedAsset} />}
      </Modal>
    </>
  );
};

export default MaintenancesTable;
