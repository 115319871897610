import {
  CalendarOutlined,
  EditOutlined,
  InfoCircleOutlined,
  KeyOutlined,
  ShopOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  Button,
  Input,
  Modal,
  Space,
  Table,
  TableColumnType,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import { t } from "i18next";
import React, { useEffect, useState, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { useAppSelector } from "../../../hooks";
import { NewTreeItem } from "../../../models";
import { IAsset } from "../../../models/asset";
import EditParent from "../components/EditParent";
import {
  childrenOfParent,
  createTree,
  getChildsOfObjectInRawArray,
  setItemsWithoutParentToHighestNode,
} from "../../../helpers/functions";
import { debounce } from "lodash";
import { TableView } from "../../../models/user";
import ViewTabs, {
  ColumnFilterType,
  CustomTableColumn,
  customTableColumnRender,
  filterColumns,
  getFilterComponent,
  saveTableDefinition,
} from "../../../components/ViewTabs";
import Columns from "../../../components/ViewTabs/Columns";

type TableRenders = "AssetName" | "AssetCategory" | "AssetLocation" | "AssetParent";

const AssetTreePage: React.FC = () => {
  const [search, set_search] = useState<string>("");
  const [editParent, set_editParent] = useState<IAsset>();
  const [pageSize, setPageSize] = useState(localStorage.getItem("assetTreeViewPageSize") || 30);
  const { assetList } = useAppSelector((state) => state.assetReducer);
  const { user } = useAppSelector((state) => state.userReducer);
  const [firstMount, set_firstMount] = useState(true);

  const [tableDefinition, set_tableDefinition] = useState<
    Array<
      TableView & {
        updated: number;
        active: boolean;
      }
    >
  >(
    user.account.views?.asset?.map((x, i) => ({
      ...x,
      updated: new Date().getTime(),
      active: i === 0,
    })) || [],
  );

  const [COLUMNS, set_COLUMNS] = React.useState<
    Array<Partial<CustomTableColumn> & TableColumnType<IAsset>>
  >([]);

  useEffect(() => {
    if (firstMount) {
      set_firstMount(false);
      return;
    }
    let tmpDefinitions =
      user.account.views?.asset?.map((x, i) => ({
        ...x,
        updated: new Date().getTime(),
        active: i === 0,
      })) || [];
    set_tableDefinition(tmpDefinitions);
  }, [user]);

  useEffect(() => {
    let activeView = tableDefinition.find((x) => x.active);
    if (activeView) {
      setColumns(activeView.table_structure, 0);
    }
  }, []);

  const render = (type: TableRenders) => {
    switch (type) {
      case "AssetName":
        return (text: string, value: IAsset) => (
          <Space size="middle">
            <Link to={`/app/asset-details/${value.id}`}>
              <span style={{ color: "rgba(0,0,0,.85)" }}>#{value.custom_id}</span> {value.name}
            </Link>
            <Space size="small">
              {value.type === "wrh" && (
                <Tooltip title={t(TRANSLATION_KEY.warehouse)}>
                  <Tag className="grey-chip">
                    <ShopOutlined />
                  </Tag>
                </Tooltip>
              )}
              {!value.can_report_order && (
                <Tooltip title={t(TRANSLATION_KEY.cantReportOrder)}>
                  <Tag className="grey-chip">
                    <StopOutlined style={{ color: "#d4282a" }} />
                  </Tag>
                </Tooltip>
              )}
              {value.client_owner && (
                <Tooltip title={`${t(TRANSLATION_KEY.ownedBy)}: ${value.client_owner.name}`}>
                  <Tag className="grey-chip">
                    <KeyOutlined style={{ color: "#000" }} />
                  </Tag>
                </Tooltip>
              )}
              {value.rented_to_client && (
                <Tooltip title={`${t(TRANSLATION_KEY.rentedBy)}: ${value.rented_to_client.name}`}>
                  <Tag className="grey-chip">
                    <CalendarOutlined style={{ color: "#000" }} />
                  </Tag>
                </Tooltip>
              )}
            </Space>
          </Space>
        );
      case "AssetCategory":
        return (text: string, value: IAsset) => {
          return <span>{t(value.category.name)}</span>;
        };
      case "AssetParent":
        return (text: string, value: IAsset) => (
          <div className="spaceBetweenRow">
            {value.is_active ? (
              <Link to={`/app/asset-details/${value.parent?.id}`}>{value.parent?.name}</Link>
            ) : (
              <Typography.Text>{value.parent?.name}</Typography.Text>
            )}

            <EditOutlined
              style={{ marginRight: 12, display: value.is_active ? "block" : "none" }}
              className="pointer"
              onClick={() =>
                set_editParent({
                  custom_fields_v2: value.custom_fields_v2,
                  name: value.name,
                  id: value.id,
                  custom_id: value.custom_id,
                  parent_id: value.parent_id,
                  parent: value.parent,
                  autopilot: value.autopilot,
                  buy_price: value.buy_price,
                  buy_price_currency: value.buy_price_currency,
                  relevant_links: value.relevant_links,
                  type: value.type,
                  category: value.category,
                  status: value.status,
                  location: value.location,
                  description: value.description,
                  created_at: value.created_at,
                  supplier: value.supplier,
                  avatar: value.avatar,
                  path: value.path,
                  files: value.files,
                  custom_fields: value.custom_fields,
                  can_report_order: value.can_report_order,
                  is_active: value.is_active,
                  base_buy_price: value.base_buy_price,
                  base_currency: value.base_currency,
                  client_owner: value.client_owner,
                  rented_to_client: value.rented_to_client,
                  has_rent_orders: value.has_rent_orders,
                })
              }
            />
          </div>
        );
      case "AssetLocation":
        return (text: string, value: IAsset) => <span>{value.location?.name || "-"}</span>;
    }
  };

  const handleSearch = debounce((value) => {
    set_search(value.toLowerCase());
  }, 300);

  const tree = useMemo(() => {
    let data = setItemsWithoutParentToHighestNode([...assetList]);
    if (search) {
      let searched = data.filter(
        (x) =>
          x.name.toLowerCase().includes(search) ||
          x.location?.name.toLowerCase().includes(search) ||
          t(x.category.name).toLowerCase().includes(search),
      );
      let _searched = setItemsWithoutParentToHighestNode([...searched]);
      _searched.forEach((x) => {
        _searched = [..._searched, ...childrenOfParent([...data], +x.id)];
      });
      searched = setItemsWithoutParentToHighestNode([..._searched]);
      let clearedArray = searched.filter(
        (thing, index, self) => index === self.findIndex((t) => t.id === thing.id),
      );
      return createTree(clearedArray, null);
    } else {
      return createTree(data, null);
    }
  }, [search, assetList]);

  useMemo(() => {
    goTroughtTreeSetChildrenOnNUllIfIsEmpty(tree);
  }, [tree]);

  function setPageSizeToLocalStorage(size: number) {
    setPageSize(size);
    localStorage.setItem("assetTreeViewPageSize", size.toString());
  }

  const setColumns = (_c: CustomTableColumn[], viewIndex: number) => {
    let tmp: Array<CustomTableColumn & TableColumnType<IAsset>> = [];
    _c.forEach((c) => {
      tmp.push({
        ...c,
        title: t(c.title),
        render: c.columnRenderComponent
          ? render(c.columnRenderComponent as TableRenders)
          : customTableColumnRender(
              c.filterComponent as ColumnFilterType,
              c.dataIndex,
              user,
              "custom_fields_v2",
            ),
        onCell: (record: IAsset) => {
          return { rowSpan: 1 };
        },
        filterDropdown: undefined,
      });
    });
    set_COLUMNS(tmp);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div />
        <div className="spaceBetweenRow">
          <Tooltip title={t(TRANSLATION_KEY.searchAssetByNameTypeCategoryLocation)}>
            <InfoCircleOutlined />
          </Tooltip>

          <div style={{ width: 12 }} />

          <Input.Search onChange={({ target: { value } }) => handleSearch(value)} allowClear />
        </div>
      </div>
      <ViewTabs
        onChange={(activeKey) => {
          let index = tableDefinition.findIndex((view) => view.name === activeKey);
          let tmpList = [...tableDefinition];
          if (index === -1) {
            message.error("view_onSaveColumnsDefinition");
            return;
          }
          tmpList = tmpList.map((x) => ({ ...x, active: false }));
          tmpList[index] = { ...tmpList[index], active: true };

          setColumns(tmpList[index].table_structure, index);
          set_tableDefinition(tmpList);
        }}
        setColumns={setColumns}
        views={tableDefinition}
        viewCategory="asset"
      />
      <div style={{ marginBottom: 12 }}>
        {!firstMount && (
          <Columns
            columns={COLUMNS}
            set_COLUMNS={(columns) => {
              let index = tableDefinition.findIndex((view) => view.active === true);
              if (index === -1) {
                message.error("view");
                return;
              }
              let tmp = tableDefinition[index];
              if (!tmp) {
                return;
              }

              let inerTmp: CustomTableColumn[] = [];
              columns.forEach((x) => {
                inerTmp.push({
                  title: x.title || "",
                  dataIndex: x.dataIndex || "",
                  visible: x.visible === undefined ? 1 : x.visible,
                  onCellFlag: x.onCellFlag || false,
                  filterComponent: x.filterComponent || null,
                  columnRenderComponent: x.columnRenderComponent || null,
                  objectKey: x.objectKey || null,
                });
              });
              tmp = { ...tmp, table_structure: inerTmp, updated: Date.now(), active: true };
              let tmpTableDefinition = [...tableDefinition].map((x) => ({ ...x, active: false }));
              tmpTableDefinition[index] = tmp;
              setColumns(inerTmp, index);
              set_tableDefinition(tmpTableDefinition);
            }}
          />
        )}
        <Button
          style={{ marginLeft: 18 }}
          type="link"
          onClick={() => {
            let activeView = tableDefinition.find((x) => x.active);
            saveTableDefinition(
              tableDefinition,
              user.account.views?.asset?.find((x) => x.id === activeView?.id),
              "asset",
            );
          }}
        >
          {t(TRANSLATION_KEY.saveChanges)}
        </Button>
      </div>

      <Table
        size="small"
        pagination={{
          defaultPageSize: Number(pageSize),
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30", "50", "100"],
          onChange(page, pageSize) {
            setPageSizeToLocalStorage(pageSize);
          },
        }}
        rowKey={(item) => item.id}
        dataSource={tree}
        columns={filterColumns<IAsset>(COLUMNS)}
      />
      <Modal
        title={t(TRANSLATION_KEY.chooseParentAsset)}
        visible={!!editParent}
        footer={null}
        destroyOnClose={true}
        onCancel={() => set_editParent(undefined)}
        children={
          <EditParent
            assetList={assetList}
            assetForChange_name={editParent?.name || ""}
            assetForChange_id={editParent?.id || 0}
            close={() => set_editParent(undefined)}
            assets={assetList.filter(
              (x) =>
                !getChildsOfObjectInRawArray(assetList, editParent?.id || 0, [])
                  .map((item) => item.id)
                  .includes(x.id) && editParent?.id !== x.id,
            )}
          />
        }
      />
    </>
  );
};

export default AssetTreePage;

const goTroughtTreeSetChildrenOnNUllIfIsEmpty = (
  array: NewTreeItem<IAsset>[] | null | undefined,
) => {
  array?.forEach((x) => {
    if (x.children?.length === 0) {
      x.children = null;
    } else {
      goTroughtTreeSetChildrenOnNUllIfIsEmpty(x.children);
    }
  });
};
