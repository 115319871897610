import { message, Select, Spin, TreeSelect } from "antd";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { TRANSLATION_KEY } from "../../../../../helpers/consts";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import moment from "moment";
import { getAssetXHR } from "../../../../../store/reducers/asstes/actionCreators";
import { createTreeSelect } from "../../../../../helpers/functions";
import DatePickerCustom, { CalendarTypes } from "../../../../../components/DatePickerCustom";
import { IAccount } from "../../../../../models/user";
import { accountsXHR } from "../../../../../store/reducers/accounts/actionCreators";
import { ISupplier } from "../../../../../models/supplier";
import { getSuppliersXHR } from "../../../../../store/reducers/supplier/actionCreator";
import AssetTreeSelect from "../../../../../componentsform/AssetTreeSelect";
import ClientsSelect from "../../../../../componentsform/ClientsSelect";

interface IProps {
  onChange: (filters: IFilters) => void;
}

export interface IFilters {
  calendarType: CalendarTypes;
  start: string | undefined;
  end: string | undefined;
  asset_categories: number[];
  assets: number[];
  executor_account: number[] | undefined; // svaki novi parametar more biti undefinde posto postoji mogucnost da se ne nalazi u localstorage-u
  executor_supplier: number[] | undefined;
  clients: number[] | undefined;
}

const Filters: React.FC<IProps> = ({ onChange }) => {
  // Variables
  const { categories, assetList, getAssetStatus } = useAppSelector((state) => state.assetReducer);
  const { user } = useAppSelector((state) => state.userReducer);
  const { accounts, getAccountStatus } = useAppSelector((state) => state.accountsReducer);
  const [localSuppliers, set_localSuppliers] = useState<ISupplier[]>([]);
  const dispatch = useAppDispatch();
  const [calendarVisible, set_calendarVisible] = useState(false);
  const [localAccounts, set_localAccounts] = useState<IAccount[]>([]);

  const [filters, set_filters] = useState<IFilters>();
  useEffect(() => {
    getSuppliersXHR(
      {
        errorCallback: (error) => {
          console.log(error);
          message.error(t(TRANSLATION_KEY.errorOnGetData));
        },
        successCallback: (res) => {
          if (res.results) {
            set_localSuppliers(res.results);
          }
        },
      },
      dispatch,
    );
    getAssetXHR(
      {
        errorCallback: (error) => {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
          console.log(error);
        },
      },
      dispatch,
    );
    accountsXHR(
      {
        errorCallback: (error: any) => {
          console.log(error);
          message.error(t(TRANSLATION_KEY.errorOnGetData));
        },
        queryParams: {
          ordering: "asc",
          limit: 9999,
          order_by: "id",
        },
        successCallback: (res) => {
          if (res.results) {
            set_localAccounts(res.results);
          }
        },
      },
      dispatch,
    );
    setFilters();
    setTimeout(() => {
      set_calendarVisible(true);
    }, 210);
  }, []);

  const setFilters = async () => {
    let filters: string | null = await localStorage.getItem(
      parsePathName() + `|${user.account.id}|${user.account.name}`,
    );
    if (!filters) {
      set_filters({
        calendarType: "month",
        start: moment().startOf("month").toISOString(true),
        end: moment().endOf("month").toISOString(true),
        asset_categories: [],
        assets: [],
        executor_account: [],
        executor_supplier: [],
        clients: [],
      });
    } else {
      let tmp: IFilters = JSON.parse(filters);
      set_filters(tmp);
    }
  };

  useEffect(() => {
    if (filters) {
      localStorage.setItem(
        parsePathName() + `|${user.account.id}|${user.account.name}`,
        JSON.stringify(filters),
      );
      onChange(filters);
    }
  }, [filters]);

  let tree = createTreeSelect(
    assetList.map((x) => ({
      title: x.name,
      value: x.id.toString(),
      parent_id: x.parent_id?.toString() || null,
      disabled: false,
    })),
    null,
  );

  let hasClients = user?.account?.company?.modules?.filter((x) => x.name === "clients").length > 0;

  const parsePathName = () => {
    // ovo je prva ruta koja je uvek ista stoga smo dodali ovaj uslov kako bi se moglo koristiti sa localstorage-om, ovo je samo na početnoj ruti
    if (window.location.pathname === "/app/analytics/") {
      return window.location.pathname + "wo_overwievs";
    } else if (window.location.pathname === "/app/analytics") {
      return window.location.pathname + "/wo_overwievs";
    }
    return window.location.pathname;
  };

  let content = filters && (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        padding: "12px",
        background: "white",
        justifyContent: "stretch",
        borderRadius: 4,
        gap: 12,
        border: "1px solid #dedede",
      }}
    >
      <Select
        onChange={(e) =>
          set_filters({
            ...filters,
            executor_account: e,
          })
        }
        defaultValue={filters.executor_account}
        onClear={() => set_filters({ ...filters, executor_account: [] })}
        placeholder={t(TRANSLATION_KEY.executors)}
        style={{ overflow: "auto", width: "16%", maxWidth: 224 }}
        allowClear={true}
        mode="multiple"
        maxTagCount="responsive"
        showSearch
        optionFilterProp="name"
        filterOption={(input, option: any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {localAccounts.map((item, index) => (
          <Select.Option key={index} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
      <Select
        onChange={(e) =>
          set_filters({
            ...filters,
            executor_supplier: e,
          })
        }
        defaultValue={filters.executor_supplier}
        onClear={() => set_filters({ ...filters, executor_supplier: [] })}
        placeholder={t(TRANSLATION_KEY.suppliers)}
        style={{ overflow: "auto", width: "16%", maxWidth: 224 }}
        allowClear={true}
        mode="multiple"
        maxTagCount="responsive"
        showSearch
        optionFilterProp="name"
        filterOption={(input, option: any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {localSuppliers.map((item, index) => (
          <Select.Option key={index} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
      <Select
        onChange={(e) =>
          set_filters({
            ...filters,
            asset_categories: e,
          })
        }
        defaultValue={filters.asset_categories}
        onClear={() => set_filters({ ...filters, asset_categories: [] })}
        placeholder={t(TRANSLATION_KEY.assetCategory)}
        style={{ overflow: "auto", width: "16%", maxWidth: 224 }}
        allowClear={true}
        mode="multiple"
        maxTagCount="responsive"
        showSearch
        optionFilterProp="name"
        filterOption={(input, option: any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {categories.map((item, index) => (
          <Select.Option key={index} value={item.id}>
            {t(item.trans_key || item.name)}
          </Select.Option>
        ))}
      </Select>
      <AssetTreeSelect
        includeWithoutAsset={true}
        onChange={(e) => {
          if (Array.isArray(e)) {
            set_filters({
              ...filters,
              assets: e,
            });
          }
        }}
        treeProps={{
          allowClear: true,
          maxTagCount: "responsive",
          style: { width: 224 },
          value: filters.assets,
          placeholder: t(TRANSLATION_KEY.selectAssets),
          treeCheckable: true,
          showCheckedStrategy: TreeSelect.SHOW_ALL,
          treeCheckStrictly: true,
          treeDefaultExpandAll: true,
          multiple: true,
        }}
      />
      {hasClients && (
        <>
          <ClientsSelect
            includeArchived={true}
            includeInactive={true}
            placeholder={t(TRANSLATION_KEY.clients)}
            style={{ width: 240 }}
            mode="multiple"
            value={filters.clients}
            onChange={(a) => {
              if (!Array.isArray(a)) return;

              set_filters({ ...filters, clients: a });
            }}
            name=""
            label=""
            form={undefined}
          />
        </>
      )}
      <div style={{ flex: 1 }} />
      {calendarVisible && (
        <DatePickerCustom
          selectStyle={{ marginRight: 12 }}
          pickerStyle={{ maxWidth: 224 }}
          start={filters.start}
          end={filters.end}
          type={filters.calendarType}
          skipInitial
          onChange={(start, end, type) => {
            set_filters({
              ...filters,
              start,
              end,
              calendarType: type || "month",
            });
          }}
        />
      )}
    </div>
  );

  return <Spin spinning={getAssetStatus === "loading"}>{content}</Spin>;
};

export default Filters;
