import {
  BarcodeOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  PlusSquareOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Drawer,
  List,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import Search from "antd/lib/input/Search";
import Upload, { RcFile } from "antd/lib/upload";
import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useDispatch } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import Columns from "../../../../components/Columns";
import FilesComponent from "../../../../components/Files";
import PreviewButton from "../../../../components/PreviewButton";
import RequirePermission from "../../../../components/RequirePermission";
import SelectTagAdd, { ButtonAddSelectTag, Tag } from "../../../../components/SelectTagAdd";
import AddExpenseForm, { IAddExpense } from "../../../../componentsform/AddExpenseForm";
import SpendPartForm from "../../../../componentsform/SpendPartForm";
import { TRANSLATION_KEY } from "../../../../helpers/consts";
import { debounce, parseCosts, sortTableColumns } from "../../../../helpers/functions";
import { useAppSelector } from "../../../../hooks";
import { IApiResponse, ITableColumn } from "../../../../models";
import { IExpense, IMaintenanceDetails } from "../../../../models/maintenances";
import { IPart, IPartSpend } from "../../../../models/parts";
import api, { failedQueue, isRefreshing } from "../../../../services";
import { getAssetXHR } from "../../../../store/reducers/asstes/actionCreators";
import { maintenanceSlice } from "../../../../store/reducers/maintenance";
import { getExpensesXHR } from "../../../../store/reducers/maintenance/actionCreator";
import { getUsualExpensesXHR } from "../../../../store/reducers/settings/actionCreator";
import ScanBinder from "../../../../scan";

import {
  getPartByCodeXHR,
  getPartsXHR,
  retrievePartXHR,
} from "../../../../store/reducers/warehouse/actionCreator";
import AccountModalPreview from "../../../account/components/AccountModalPreview";
// import PartModalPreview from "../../../warehouse/components/PartModalPreview";
import WorkingCostsDetail from "../../components/WorkingCostsDetail";
import WorkCosts from "./WorkCosts";
import PartModalPreview from "../../../part/components/PartModalPreview";

export type IEditExpense = Partial<IAddExpense>;

type IProps = {};

const Costs: React.FC<IProps> = () => {
  // Constants
  const STORAGE_COLUMNS_NAME = "COSTS_TABLE";

  // Hooks
  const [expenseForm] = useForm();
  const location = useLocation();
  const dispatch = useDispatch();

  const { id } = useParams();

  // Variables
  const [getPartLoading, set_getPartLoading] = useState<boolean>(false);
  const [scannerVisible, set_scannerVisible] = useState<boolean>(false);
  const [modalVisible, set_modalVisible] = useState<boolean>(false);
  const [selectedPreviewPart, set_selectedPreviewPart] = useState<number | undefined>(undefined);
  const { maintenanceDetails, expenses, getExpensesStatus } = useAppSelector(
    (state) => state.maintenanceReducer,
  );
  const { user } = useAppSelector((state) => state.userReducer);
  const { parts, getPartsStatus } = useAppSelector((state) => state.warehouseReducer);
  const [selectedExpenseForDeleting, set_selectedExpenseForDeleting] = useState<number | undefined>(
    undefined,
  );
  const [selectedAccount, set_selectedAccount] = useState<string | number | undefined>(undefined);
  const [accountModalPreviewVisible, set_accountModalPreviewVisible] = useState<boolean>(false);

  const [selectedExpense, set_selectedExpense] = useState<
    Omit<IExpense, "order_info"> & { order_info?: string | number }
  >();
  const [partModalVisibleSpend, set_partModalVisibleSpend] = useState<boolean>(false);
  const [selectedPart, set_selectedPart] = useState<IPart | undefined>(undefined);

  let _c: ITableColumn<IPartSpend>[] = [
    {
      title: t(TRANSLATION_KEY.name),
      dataIndex: "name",
      visible: true,
      render: (text: string, value: IPartSpend) => {
        if (!value.part?.id) {
          return value.name;
        }

        return (
          <PreviewButton
            isActive={value.part.is_active}
            title={value.part.name}
            id={value.part.id}
            url={`/app/item-details/${value.part.id}`}
            permissionsRequired={["manage_warehouse"]}
            onClick={() => {
              set_modalVisible(true);
              set_selectedPreviewPart(value.part.id);
            }}
          />
        );

        // return(
        //   <Link to={`/app/item-details/${value.part.id}/`}>
        //     {value.name}
        //   </Link>
        // )
      },
    },
    {
      title: t(TRANSLATION_KEY.note),
      dataIndex: "note",
      visible: true,
    },
    {
      title: t(TRANSLATION_KEY.qty),
      dataIndex: "qty",
      visible: true,
    },
    {
      title: t(TRANSLATION_KEY.price),
      dataIndex: "total_price",
      render: (text: string, value: IPartSpend) => {
        return `${parseCosts(value.total_price.toString())} ${value.total_price_uom}`;
      },
      visible: true,
    },

    {
      title: t(TRANSLATION_KEY.createdBy),
      dataIndex: "created_by",
      visible: true,
      render: (text: string, value: IPartSpend) => (
        <>
          <PreviewButton
            isActive={value.created_by.is_active}
            title={value.created_by.name}
            id={value.created_by.id}
            url={`/app/humanresources/${value.created_by.id}`}
            permissionsRequired={["manage_user"]}
            onClick={() => {
              set_accountModalPreviewVisible(true);
              set_selectedAccount(value.created_by.id);
            }}
          />
          <Typography.Text type="secondary">
            {moment(value.created_at).format(user.account.date_format || undefined)}
          </Typography.Text>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "",
      visible: "always",
      render: (text: string, value: IPartSpend) => (
        <div>
          <RequirePermission
            anywayIgnorePermission={value.created_by.id === user.account.id}
            requiredPermissionCode={["manage_wo"]}
            children={
              <Popconfirm
                onConfirm={() => set_selectedExpenseForDeleting(value.id)}
                title={t(TRANSLATION_KEY.continueWithAction)}
                cancelText={t(TRANSLATION_KEY.no)}
                okText={t(TRANSLATION_KEY.yes)}
                placement="top"
              >
                <Button type="link" danger>
                  {t(TRANSLATION_KEY.remove)}
                </Button>
              </Popconfirm>
            }
          />
        </div>
      ),
    },
  ];

  const [drawerVisible, set_drawerVisible] = useState<boolean>(false);
  const [deleteLoading, set_deleteLoading] = useState<boolean>(false);
  const [loading, set_loading] = useState<boolean>(false);
  const [preview, set_preview] = useState<number>();
  const [COLUMNS, set_COLUMNS] = useState<ITableColumn<IPartSpend>[]>(_c);

  // Methods
  useEffect(() => {
    // Getters
    getUsualExpensesXHR(
      { errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)) },
      dispatch,
    );
    getExpensesXHR(
      {
        queryParams: { order_info: id },
        errorCallback: () => message.error(t(TRANSLATION_KEY.errorOnGetData)),
      },
      dispatch,
    );
    getAssetXHR({ errorCallback: (data: any) => message.error(t("errorOnFetchData")) }, dispatch);

    // Table sort
    sortTableColumns(COLUMNS, STORAGE_COLUMNS_NAME + location.pathname, set_COLUMNS);
  }, []);

  useEffect(() => {
    if (selectedExpenseForDeleting) {
      removePartSpend(selectedExpenseForDeleting);
    }
  }, [selectedExpenseForDeleting]);

  useEffect(() => {
    set_COLUMNS(_c);
  }, [maintenanceDetails]);

  async function removePartSpend(spend_part: number) {
    set_deleteLoading(true);
    let token = await localStorage.getItem("token");
    try {
      let response = api.post<string>(
        `/warehouse/parts/remove_spent/`,
        { spend_part },
        { headers: { Authorization: "Bearer " + token } },
      );

      let arr: IPartSpend[] = [...maintenanceDetails.parts];
      let index = arr.findIndex((item) => item.id === spend_part);
      arr.splice(index, 1);
      const fakeRes: IApiResponse<IMaintenanceDetails> = {
        results: {
          ...maintenanceDetails,
          parts: arr,
        },
        message: "",
      };
      // Update store
      dispatch(maintenanceSlice.actions.getMaintenanceSuccess(fakeRes));
      // Reseting selectedExpenseForDeleting
      set_selectedExpenseForDeleting(undefined);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => removePartSpend(spend_part));
        }
        return;
      }
      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
    set_deleteLoading(false);
  }

  function onEditExpense(item: IExpense) {
    set_selectedExpense({
      ...item,
      order_info: item.order_info.id,
    });
    expenseForm.setFieldsValue({ tag: item.usual_expense.id });
    expenseForm.setFieldsValue({ price: item.value });
    expenseForm.setFieldsValue({ price_uom: item.currency });
  }

  const onDeleteExpenseMaterial = async (id: number, esxpense_id: number) => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let response = api.delete<string>(`/maintenance/expense_material/${id}/`, {
        headers: { Authorization: "Bearer " + token },
      });

      let arr: IExpense[] = [...expenses];
      let index = arr.findIndex((x) => x.id === esxpense_id);
      let materials = [...arr[index].materials];
      let materialIndex = materials.findIndex((x) => x.id === id);
      materials.splice(materialIndex, 1);
      let newObject: IExpense = {
        ...arr[index],
        materials,
      };

      arr[index] = newObject;

      dispatch(
        maintenanceSlice.actions.getExpensesSuccess({
          results: arr,
          message: "",
        }),
      );
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onDeleteExpenseMaterial(id, esxpense_id));
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
    set_loading(false);
  };

  async function onDeleteExpense(id: number) {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let response = api.delete<string>(`/maintenance/expense/${id}/`, {
        headers: { Authorization: "Bearer " + token },
      });

      const arr: IExpense[] = [...expenses];
      const index = arr.findIndex((x) => x.id === id);
      arr.splice(index, 1);

      dispatch(
        maintenanceSlice.actions.getExpensesSuccess({
          results: arr,
          message: "",
        }),
      );
    } catch (error: any) {
      if (error?.response?.status === 401) {
        if (isRefreshing) {
          failedQueue.push(() => onDeleteExpense(id));
        }
        return;
      }

      message.error(t(TRANSLATION_KEY.errorOnDeleteData));
    }
    set_loading(false);
  }

  function getParts(search: string) {
    getPartsXHR(
      {
        errorCallback: (data: any) => message.error(t(TRANSLATION_KEY.errorOnGetData)),
        queryParams: {
          order_by: "name",
          ordering: "asc",
          limit: 10000,
          cursor: "name",
          search,
        },
      },
      dispatch,
    );
  }

  function debounceOnSearch(input: string) {
    getParts(input);
  }

  const setSearch = debounce<typeof debounceOnSearch>(debounceOnSearch, 600);

  let searchedPartsToSpend = parts.data.filter((x) => x.part_storage.length > 0);

  let materialCostsSum = 0;

  maintenanceDetails.parts.forEach((x) => {
    materialCostsSum += x?.total_price ? +x?.total_price : 0;
  });

  expenses.forEach((x) => {
    materialCostsSum += x.base_value ? +x.base_value : 0;
  });

  return (
    <div>
      <RequirePermission
        children={
          <div className="maintenenaceGridsContienrs" style={{ marginBottom: 24 }}>
            <WorkCosts sumMaterialCosts={materialCostsSum} />
          </div>
        }
        requiredPermissionCode={["manage_analytics"]}
      />

      {/* Lists */}
      <div style={{ marginBottom: 24 }} className="white-container">
        <div className="spaceBetweenRow" style={{ paddingBottom: 12 }}>
          <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
            {/* Title */}
            <Typography.Title level={5} style={{ paddingTop: 6 }}>
              {t(TRANSLATION_KEY.itemsSpent)}
            </Typography.Title>

            {/* Columns */}
            <Columns
              columns={COLUMNS}
              memorizedName={STORAGE_COLUMNS_NAME + location.pathname}
              onChange={set_COLUMNS}
            />
          </div>

          {/* Add button */}
          <RequirePermission
            requiredPermissionCode={["manage_wo", "manage_warehouse", "add_part_on_wo"]}
            children={
              <Space>
                <Button
                  type="default"
                  shape="circle"
                  icon={<BarcodeOutlined />}
                  onClick={() => {
                    set_scannerVisible(true);
                  }}
                />
                <Button
                  type="primary"
                  shape="circle"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    getParts("");
                    set_drawerVisible(true);
                  }}
                />
              </Space>
            }
          />
        </div>

        {/* Table */}

        <Table
          loading={loading || deleteLoading}
          size="small"
          dataSource={maintenanceDetails.parts}
          columns={COLUMNS.filter((x) => x.visible).map((item) => ({
            ...item,
            title: t(item.title),
          }))}
          rowKey={(item: IPartSpend) => item.id}
          pagination={false}
        />
      </div>

      <Row gutter={[24, 24]} style={{ position: "relative" }}>
        <Col lg={24} xl={12}>
          <div className="white-container" style={{ minHeight: 240, height: "100%" }}>
            {/* Title */}
            <div className="spaceBetweenRow" style={{ marginTop: 8, marginBottom: -6 }}>
              <Typography.Title level={5}>{t(TRANSLATION_KEY.expenses)}</Typography.Title>

              <Button
                onClick={() =>
                  set_selectedExpense({
                    id: 0,
                    value: 0,
                    currency: "",
                    order_info: id,
                    created_at: null,
                    updated_at: null,
                    usual_expense: { id: 0, name: "" },
                    created_by: null,
                    materials: [],
                    base_currency: "",
                    base_value: "",
                  })
                }
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
              />
            </div>

            {/* Divider */}
            <Divider style={{ marginTop: 20, marginBottom: 18 }} />

            {/* List header */}
            {expenses.length > 0 && (
              <div style={{ width: "100%" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ width: "calc(33% - 11px)" }}>
                    <Typography.Text type="secondary">{t(TRANSLATION_KEY.name)}</Typography.Text>
                  </div>
                  <div style={{ width: "calc(33% - 11px)" }}>
                    {" "}
                    <Typography.Text type="secondary">{t(TRANSLATION_KEY.value)}</Typography.Text>
                  </div>
                  <div style={{ width: "calc(33% - 11px)" }}>
                    <Typography.Text type="secondary">
                      {t(TRANSLATION_KEY.createdBy)}
                    </Typography.Text>
                  </div>
                  <div style={{ width: "33px" }} />
                </div>
              </div>
            )}

            <List
              style={{ height: "100%" }}
              dataSource={expenses}
              loading={getExpensesStatus === "loading" || loading}
              renderItem={(item) => (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 12,
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "calc(33% - 11px)" }}>
                      <div>
                        <Typography.Text>{item.usual_expense.name}</Typography.Text>
                      </div>
                    </div>
                    <div style={{ width: "calc(33% - 11px)" }}>
                      <Typography.Text>
                        {`${parseCosts(item.value?.toString())} ${item?.currency} / `}
                        {`${parseCosts(item.base_value?.toString())} ${
                          user.account?.company.currency
                        }`}
                      </Typography.Text>
                    </div>
                    <div style={{ width: "calc(33% - 11px)" }}>
                      <Space direction="vertical" size={0}>
                        <Typography.Text>{item.created_by?.name}</Typography.Text>
                        <Typography.Text type="secondary">
                          {moment(item.created_at).format()}
                        </Typography.Text>
                      </Space>
                    </div>
                    <div style={{ width: "33px" }}>
                      <RequirePermission
                        requiredPermissionCode={["manage_wo"]}
                        anywayIgnorePermission={user.account.id === item.created_by?.id}
                        children={
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <Button
                              type="link"
                              onClick={() => onEditExpense(item)}
                              style={{ padding: "0px 1px" }}
                              icon={<EditOutlined />}
                            />
                            <Popconfirm
                              onConfirm={() => onDeleteExpense(item.id)}
                              title={t(TRANSLATION_KEY.continueWithAction)}
                              cancelText={t(TRANSLATION_KEY.no)}
                              okText={t(TRANSLATION_KEY.yes)}
                              placement="top"
                              style={{ marginTop: 6 }}
                            >
                              <Button
                                type="link"
                                danger
                                style={{ padding: "0px 1px" }}
                                icon={<DeleteOutlined />}
                              />
                            </Popconfirm>
                          </div>
                        }
                      />
                    </div>
                  </div>
                  {item.materials.length > 0 && (
                    <FilesComponent
                      files={item.materials}
                      onDelete={() => {}}
                      hideEditButton
                      size="tiny"
                      layout="grid"
                      hideButtons
                    />
                  )}
                </div>
              )}
            />
          </div>
        </Col>

        <Col lg={24} xl={12}>
          <RequirePermission
            children={
              <div className="white-container" style={{ minHeight: 240, height: "100%" }}>
                {/* Title */}
                <Typography.Title style={{ marginTop: 12 }} level={5}>
                  {t(TRANSLATION_KEY.workCostsOfMaintenances)}{" "}
                </Typography.Title>

                {/* Divider */}
                <Divider style={{ marginTop: 20, marginBottom: 18 }} />

                {/* Components */}
                <WorkingCostsDetail />
              </div>
            }
            requiredPermissionCode={["manage_analytics"]}
          />
        </Col>
      </Row>

      {scannerVisible && (
        <ScanBinder
          requireLogin={false}
          loading={getPartLoading}
          callback={(code: string) => {
            set_getPartLoading(true);
            getPartByCodeXHR(
              {
                queryParams: {
                  code,
                },
                errorCallback: (error: any) => {
                  message.error(
                    <Typography.Title level={3}>
                      {error.response?.status === 404
                        ? t(TRANSLATION_KEY.itemNotExisitsInDataBase)
                        : error.message}
                    </Typography.Title>,
                    6,
                  );
                  set_getPartLoading(false);
                  set_scannerVisible(false);
                },
                successCallback: (response) => {
                  set_selectedPart(response.results);
                  set_partModalVisibleSpend(true);
                  set_getPartLoading(false);
                  set_scannerVisible(false);
                },
              },
              dispatch,
            );
          }}
          close={() => set_scannerVisible(false)}
        />
      )}

      <Drawer
        width={540}
        visible={drawerVisible}
        onClose={() => set_drawerVisible(false)}
        title={t(TRANSLATION_KEY.addExpense)}
        destroyOnClose={true}
      >
        <List
          itemLayout="horizontal"
          dataSource={searchedPartsToSpend}
          header={
            <div>
              <Search
                loading={getPartsStatus === "loading"}
                placeholder={t(TRANSLATION_KEY.searchParts)}
                onChange={(e) => setSearch(e.target.value.toLowerCase())}
                style={{ marginBottom: 10, marginTop: -12 }}
                allowClear
                size="large"
                enterButton
              />
            </div>
          }
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  onClick={() => {
                    unstable_batchedUpdates(() => {
                      set_partModalVisibleSpend(true);
                      set_selectedPart(item);
                    }, []);
                  }}
                  type="link"
                >
                  {t(TRANSLATION_KEY.spend)}
                </Button>,
              ]}
            >
              <List.Item.Meta
                description={item.description}
                title={
                  <Space>
                    <Typography.Text type="secondary">{`#${item.code}`}</Typography.Text>
                    <Typography.Text>{item.name}</Typography.Text>
                  </Space>
                }
              />
            </List.Item>
          )}
        />
      </Drawer>

      {/* Spend with part modal */}
      <Modal
        visible={partModalVisibleSpend}
        title={t(TRANSLATION_KEY.addExpense)}
        onCancel={() => {
          set_partModalVisibleSpend(false);
        }}
        footer={null}
        centered
        closable
        destroyOnClose={true}
      >
        {selectedPart && (
          <SpendPartForm
            close={() => set_partModalVisibleSpend(false)}
            selectedPart={selectedPart}
            maintenance={maintenanceDetails.id}
          />
        )}
      </Modal>

      {/* Expense modal */}
      <Drawer
        visible={!!selectedExpense}
        title={
          selectedExpense?.id === 0 ? t(TRANSLATION_KEY.addExpense) : t(TRANSLATION_KEY.editExpense)
        }
        onClose={() => {
          set_selectedExpense(undefined);
        }}
        footer={null}
        destroyOnClose
      >
        {!!selectedExpense && (
          <AddExpenseForm
            order_info={id}
            expense={selectedExpense}
            close={() => set_selectedExpense(undefined)}
          />
        )}
      </Drawer>
      {/* Account preview modal */}
      <Modal
        visible={accountModalPreviewVisible}
        onCancel={() => {
          set_accountModalPreviewVisible(false);
          set_selectedAccount(undefined);
        }}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        <AccountModalPreview id={selectedAccount} />
      </Modal>

      <Modal
        visible={modalVisible}
        onCancel={() => {
          set_modalVisible(false);
          set_selectedPreviewPart(undefined);
        }}
        footer={null}
        centered
        width={940}
        closable={false}
        destroyOnClose
      >
        <PartModalPreview id={selectedPreviewPart} />
      </Modal>

      {/* {preview && (
        <PartModalPreview close={() => set_preview(undefined)} id={preview} />
      )} */}
    </div>
  );
};

export default Costs;
