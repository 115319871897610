import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DataWithPagination, IApiResponse } from "../../../models";
import { BankAccount, Invoice } from "../../../models/finance";

interface IFinanceState {
  // Bank Accounts
  bankAccounts: DataWithPagination<BankAccount>;
  getBankAccountsStatus: string;
  addBankAccountStatus: string;
  updateBankAccountStatus: string;
  deleteBankAccountStatus: string;
  // Invoices
  invoices: Invoice[];
  getInvoicesStatus: string;
  addInvoiceStatus: string;
  updateInvoiceStatus: string;
}

const initialState = {
  // Bank Accounts
  bankAccounts: {
    total: 0,
    data: [],
  },
  getBankAccountsStatus: "",
  addBankAccountStatus: "",
  updateBankAccountStatus: "",
  deleteBankAccountStatus: "",
  // Invoices
  invoices: [],
  getInvoicesStatus: "",
  addInvoiceStatus: "",
  updateInvoiceStatus: "",
} as IFinanceState;

export const financeSlice = createSlice({
  name: "finance",
  initialState,
  reducers: {
    resetState: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },

    // -----

    // #region Bank Accounts
    getBankAccounts(state) {
      state.getBankAccountsStatus = "loading";
    },
    getBankAccountsSuccess(
      state,
      action: PayloadAction<IApiResponse<DataWithPagination<BankAccount>> & { mergeData: boolean }>,
    ) {
      state.getBankAccountsStatus = "";

      if (!action.payload.results) {
        return;
      }
      if (action.payload.mergeData) {
        state.bankAccounts = {
          total: action.payload.results.total,
          data: [...state.bankAccounts.data, ...action.payload.results.data],
        };
      } else {
        state.bankAccounts = action.payload.results;
      }
    },
    getBankAccountsFail(state, action: PayloadAction<string>) {
      state.getBankAccountsStatus = action.payload;
    },

    // -----

    addBankAccount(state) {
      state.addBankAccountStatus = "loading";
    },
    addBankAccountSuccess(state, action: PayloadAction<IApiResponse<BankAccount>>) {
      state.addBankAccountStatus = "";
      if (action.payload.results) {
        state.bankAccounts = {
          total: state.bankAccounts.total + 1,
          data: [action.payload.results, ...state.bankAccounts.data],
        };
      }
    },
    addBankAccountFail(state, action: PayloadAction<string>) {
      state.addBankAccountStatus = action.payload;
    },

    // -----

    updateBankAccount(state) {
      state.updateBankAccountStatus = "loading";
    },
    updateBankAccountSuccess(state, action: PayloadAction<IApiResponse<BankAccount>>) {
      state.updateBankAccountStatus = "";
      if (action.payload.results) {
        let data = [...state.bankAccounts.data];
        const index = data.findIndex((item) => item.id === action.payload.results?.id);
        data.splice(index, 1, action.payload.results);
        state.bankAccounts = {
          total: state.bankAccounts.total,
          data,
        };
      }
    },
    updateBankAccountFail(state, action: PayloadAction<string>) {
      state.updateBankAccountStatus = action.payload;
    },

    // -----

    deleteBankAccount(state) {
      state.deleteBankAccountStatus = "loading";
    },
    deleteBankAccountSuccess(state, action: PayloadAction<number>) {
      state.deleteBankAccountStatus = "";
      state.bankAccounts = {
        total: Math.max(0, state.bankAccounts.total - 1),
        data: state.bankAccounts.data.filter((item) => item.id !== action.payload),
      };
    },
    deleteBankAccountFail(state, action: PayloadAction<string>) {
      state.deleteBankAccountStatus = action.payload;
    },
    // #endregion

    // -----

    // #region Invoices
    getInvoices(state) {
      state.getInvoicesStatus = "loading";
    },
    getInvoicesSuccess(state, action: PayloadAction<IApiResponse<Invoice[]>>) {
      state.getInvoicesStatus = "";
      if (action.payload.results) {
        state.invoices = action.payload.results;
      }
    },
    getInvoicesFail(state, action: PayloadAction<string>) {
      state.getInvoicesStatus = action.payload;
    },

    // -----

    addInvoice(state) {
      state.addInvoiceStatus = "loading";
    },
    addInvoiceSuccess(state, action: PayloadAction<IApiResponse<Invoice>>) {
      state.addInvoiceStatus = "";
      if (action.payload.results) {
        state.invoices = [action.payload.results, ...state.invoices];
      }
    },
    addInvoiceFail(state, action: PayloadAction<string>) {
      state.addInvoiceStatus = action.payload;
    },

    // -----

    updateInvoice(state) {
      state.updateInvoiceStatus = "loading";
    },
    updateInvoiceSuccess(state, action: PayloadAction<IApiResponse<Invoice>>) {
      state.updateInvoiceStatus = "";
      if (action.payload.results) {
        let data = [...state.invoices];
        const index = data.findIndex((item) => item.id === action.payload.results?.id);
        data.splice(index, 1, action.payload.results);
        state.invoices = data;
      }
    },
    updateInvoiceFail(state, action: PayloadAction<string>) {
      state.updateInvoiceStatus = action.payload;
    },

    // #endregion
  },
});

export default financeSlice.reducer;
