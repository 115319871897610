import { Tabs } from "antd";
import { t } from "i18next";
import React, { useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../navigation/routes";

interface IProps {}

const { TabPane } = Tabs;

const ClientAnalytics: React.FC<IProps> = () => {
  // Hooks
  const navigate = useNavigate();
  const location = useLocation();

  // State
  const [routes] = useState(
    AppRoutes("allroutes").children.find((x) => x.path === "analytics")?.children[3],
  );

  // Functions
  const onTabChange = (path: string) => {
    navigate(`${path}`, { replace: false });
  };

  return (
    <div>
      <div className="spaceBetweenRow">
        <Tabs activeKey={location.pathname.split("/")[4]} onChange={onTabChange}>
          {routes &&
            routes.children.map((x) => (
              <TabPane
                tab={<Link to={x.path}>{t(x.path || "byClientReport")}</Link>}
                key={x.path}
              />
            ))}
        </Tabs>
      </div>
      <Outlet />
    </div>
  );
};

export default ClientAnalytics;
