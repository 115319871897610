import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, InputNumber, Space, Typography } from "antd";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../helpers/consts";
import { useAppSelector } from "../../hooks";
import { Invoice } from "../../models/finance";
import moment from "moment";

type Props = {
  invoice: Invoice | undefined;
};

const InvoiceCashForm = ({ invoice }: Props) => {
  // State
  const { user } = useAppSelector((state) => state.userReducer);
  const format = user.account?.date_format || "DD.MM.YYYY - HH:mm";

  return (
    <>
      <div
        style={{
          width: "calc(100% - 22px)",
          display: "flex",
          alignItems: "center",
          gap: 8,
          marginBottom: 8,
        }}
      >
        <Typography.Text style={{ width: "50%" }}>Datum</Typography.Text>
        <Typography.Text style={{ width: "50%" }}>Iznos</Typography.Text>
      </div>
      <Form.List name="cash_form">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item required={false} key={field.key}>
                <div style={{ width: "100%", display: "flex", alignItems: "center", gap: 8 }}>
                  <div style={{ width: "100%" }}>
                    <Form.Item
                      {...field}
                      name={[field.name, "pay_date"]}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
                      noStyle
                    >
                      <DatePicker format={format} style={{ width: "100%" }} />
                    </Form.Item>
                  </div>
                  <div style={{ width: "100%" }}>
                    <Form.Item
                      {...field}
                      name={[field.name, "amount"]}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
                      noStyle
                    >
                      <InputNumber style={{ width: "100%" }} addonAfter={invoice?.currency} />
                    </Form.Item>
                  </div>
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                </div>
              </Form.Item>
            ))}

            {/* New Field Button */}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() =>
                  add({
                    amount: undefined,
                    currency: invoice?.currency,
                    pay_date: moment(),
                  })
                }
                block
                icon={<PlusOutlined />}
              />
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
      <div style={{ height: 8 }} />
    </>
  );
};

export default InvoiceCashForm;
