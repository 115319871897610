import { DeleteOutlined } from "@ant-design/icons";
import { Button, Input, InputNumber, Space } from "antd";
import { t } from "i18next";
import React, { useEffect } from "react";

export type SearchValue = {
  from: number | undefined | string | null;
  to: number | undefined | string | null;
};

interface IProps {
  column_key: string;
  value: SearchValue | undefined;
  close?: () => void;
  opened: string;
  onSave: (value: SearchValue, column_key: string) => void;
}

const NumberFilter: React.FC<IProps> = ({ close, opened, value, onSave, column_key }) => {
  const searchInputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const [searchValue, set_searchValue] = React.useState<SearchValue>({
    from: value?.from,
    to: value?.to,
  });

  useEffect(() => {
    if (value) {
      set_searchValue(value);
    }
  }, [value]);

  console.log(value);

  useEffect(() => {
    if (searchInputRef.current) {
      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 300);
    }
  }, [opened]);

  console.log("NumberFilter -> searchValue", searchValue);

  const resetValues = () => {
    set_searchValue({ from: undefined, to: undefined });
  };

  return (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <InputNumber
          ref={searchInputRef || undefined}
          placeholder={t("from")}
          value={searchValue?.from}
          onChange={(e) => {
            set_searchValue({ ...searchValue, from: e === undefined ? undefined : e });
          }}
          onPressEnter={() => onSave(searchValue, column_key)}
          style={{ marginBottom: 8, marginLeft: 6 }}
        />
        <InputNumber
          placeholder={t("to")}
          value={searchValue?.to}
          onChange={(e) => set_searchValue({ ...searchValue, to: e === undefined ? undefined : e })}
          onPressEnter={() => onSave(searchValue, column_key)}
          style={{ marginBottom: 8, marginLeft: 6 }}
        />

        <Button
          style={{ marginBottom: 8, marginLeft: 6 }}
          type="primary"
          onClick={() => onSave(searchValue, column_key)}
        >
          Pretraži
        </Button>
        <Button
          onClick={() => {
            set_searchValue({ from: undefined, to: undefined });
            onSave({ from: undefined, to: undefined }, column_key);
          }}
          style={{ marginBottom: 8, marginLeft: 6 }}
          icon={<DeleteOutlined />}
          type="default"
        />
      </div>
    </div>
  );
};

export default NumberFilter;
