import {
  Button,
  Checkbox,
  Dropdown,
  Input,
  List,
  Menu,
  Space,
  TableColumnType,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import ReactDragListView from "react-drag-listview";
import { CustomTableColumn } from ".";
import { DownOutlined, HolderOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../helpers/consts";

interface IProps {
  columns: Array<Partial<CustomTableColumn>>;
  set_COLUMNS: (COLUMNS: Array<Partial<CustomTableColumn>>) => void;
}

const Columns: React.FC<IProps> = ({ columns, set_COLUMNS }) => {
  const [COLUMNS, _set_COLUMNS] = useState<Array<Partial<CustomTableColumn>>>(columns);
  const [searchValue, set_searchValue] = useState<string>("");

  useEffect(() => {
    _set_COLUMNS(columns);
  }, []);

  const onDragEnd = async (fromIndex: number, toIndex: number) => {
    if (toIndex < 0) return; // Ignores if outside designated area

    const items = columns.filter((item) => item.dataIndex !== "actions");
    const item = items.splice(fromIndex, 1)[0];
    items.splice(toIndex, 0, item);

    let action = columns.find((item) => item.dataIndex === "actions");
    if (action) {
      items.push(action);
    }
    set_COLUMNS(items);
    _set_COLUMNS(items);
  };

  const onChangeVisibility = async (index: number) => {
    const items = [...COLUMNS].filter((item) => item.dataIndex !== "actions");
    const item = { ...items[index] };
    if (item.visible === 3) return;
    if (item.visible === 2) {
      item.visible = 1;
    } else if (item.visible === 1) {
      item.visible = 2;
    }
    items[index] = item;

    let action = columns.find((item) => item.dataIndex === "actions");
    if (action) {
      items.push(action);
    }

    set_COLUMNS(items);
    _set_COLUMNS(items);
  };

  const menu = (
    <Menu>
      <ReactDragListView nodeSelector=".ant-list-item.draggble" onDragEnd={onDragEnd}>
        <List
          style={{
            padding: "0 6px",
            borderRadius: 3,
            width: 240,
            maxHeight: 540,
            overflow: "auto",
          }}
          header={
            <Input
              allowClear
              placeholder="Pretraži kolone"
              onChange={(e) => {
                const value = e.target.value?.toLowerCase();
                set_searchValue(value || "");
              }}
              style={{ padding: "0 6px", borderRadius: 3, height: 32 }}
            />
          }
          dataSource={COLUMNS.filter((item) => item.visible !== 3)}
          renderItem={(item, index) => {
            return (
              <List.Item
                style={{
                  display: item.title?.toLowerCase().includes(searchValue) ? "flex" : "none",
                }}
                className="ListItemCustomClass draggble"
                actions={[
                  <Checkbox
                    checked={item.visible === 1}
                    onChange={() => onChangeVisibility(index)}
                  />,
                ]}
              >
                <List.Item.Meta
                  description={
                    <Space>
                      <HolderOutlined />
                      <Typography.Text
                        ellipsis={{
                          tooltip: item.title,
                        }}
                        style={{ width: 180, display: "inline-block" }}
                      >
                        {item.title}
                      </Typography.Text>
                    </Space>
                  }
                />
              </List.Item>
            );
          }}
        />
      </ReactDragListView>
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      // visible={listOpen}
      arrow={true}
      placement="bottomLeft"
    >
      <Button>
        {t(TRANSLATION_KEY.columns)} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default Columns;
