import React, { useEffect, useState } from "react";

// Antd
import { Button, List, Modal } from "antd";

// Rest
import { TRANSLATION_KEY } from "../../../helpers/consts";
import { t } from "i18next";
import { useAppSelector } from "../../../hooks";
import { hasPermission } from "../../../helpers/functions";
import { IMaintenance, IMaintenanceDetails } from "../../../models/maintenances";
import Search from "antd/lib/input/Search";
import MaintenanceModalPreview from "./ModalPreview";
import ConfirmForm from "../../../componentsform/ConfirmForm";
import { unstable_batchedUpdates } from "react-dom";
import MaintenanceListItem from "./MaintenanceListItem";

interface IProps {
  close: () => void;
  order?: IMaintenanceDetails;
}

const ConfirmOrder: React.FC<IProps> = ({ close, order }) => {
  const [search, set_search] = useState<string>("");
  const [visible, set_visible] = useState<IMaintenance>();
  const [maintenanecPreviewModalVisible, set_maintenanecPreviewModalVisible] =
    useState<boolean>(false);
  const [selectedMaintenance, set_selectedMaintenance] = useState<number | undefined>(order?.id);
  const { liveMaintenances } = useAppSelector((state) => state.maintenanceReducer);
  const { user } = useAppSelector((state) => state.userReducer);

  let filteredData: IMaintenance[] = liveMaintenances.filter((x) => x.status === "completed");

  useEffect(() => {
    if (order) {
      set_selectedMaintenance(order.id);
    }
  }, [order]);

  if (search) {
    filteredData = filteredData.filter(
      (item) =>
        item.description.toLowerCase().includes(search) ||
        item.asset?.name.toLowerCase().includes(search) ||
        item.location?.name.toLowerCase().includes(search),
    );
  }

  // Methods
  //   useEffect(() => {
  //     set_modalVisible(visible)
  //   }, [visible])

  const ConfirmModal = (
    <Modal
      visible={!!visible}
      onCancel={() => {
        set_visible(undefined);
        set_selectedMaintenance(undefined);
      }}
      footer={null}
      centered
      width="600px"
      destroyOnClose
      title={t(TRANSLATION_KEY.closeMaintenance)}
    >
      {visible && (
        <ConfirmForm
          close={() => {
            unstable_batchedUpdates(() => {
              set_visible(undefined);
              set_selectedMaintenance(undefined);
            }, []);
          }}
          selectedMaintenance={visible?.id || 0}
        />
      )}
    </Modal>
  );

  if (order) {
    return ConfirmModal;
  }

  let canConfirm = false;

  canConfirm = hasPermission(user.account.permissions, ["manage_wo", "confirm_wo"]);

  return (
    <div>
      <List
        itemLayout="horizontal"
        dataSource={filteredData}
        header={
          <>
            <Search
              placeholder={t(TRANSLATION_KEY.searchOrders)}
              onChange={(e) => set_search(e.target.value.toLowerCase())}
              style={{ marginBottom: 10, marginTop: -12 }}
              allowClear
              enterButton
            />
          </>
        }
        renderItem={(item) => (
          <MaintenanceListItem
            item={item}
            containerStyle={{ padding: "10px 4px" }}
            onClick={
              canConfirm || item.reported_by.id === user.account.id
                ? () => set_visible(item)
                : undefined
            }
            buttonText={t(TRANSLATION_KEY.confirm)}
            onPreview={() => {
              set_maintenanecPreviewModalVisible(true);
              set_selectedMaintenance(item.id);
            }}
          />
        )}
      />

      {/* Modal */}
      {ConfirmModal}

      {/* Maintenance preview */}
      <Modal
        visible={maintenanecPreviewModalVisible}
        onCancel={() => {
          set_maintenanecPreviewModalVisible(false);
          set_selectedMaintenance(undefined);
        }}
        footer={null}
        centered
        width="800px"
        closable={false}
        destroyOnClose
      >
        <MaintenanceModalPreview id={selectedMaintenance} />
      </Modal>
    </div>
  );
};

export default ConfirmOrder;
