import { Form, FormInstance, Input, Typography } from "antd";
import { t } from "i18next";
import { TRANSLATION_KEY } from "../../../../../../helpers/consts";
import { AddBankAccount, BankAccount } from "../../../../../../models/finance";
import { useEffect } from "react";
import useBankAccounts from "./useBankAccounts";

type Props = {
  bankAccount?: BankAccount;
  form: FormInstance<AddBankAccount>;
  close?: () => void;
};

const BankAccountForm = ({ bankAccount, form, close }: Props) => {
  // Hooks
  const { add, update } = useBankAccounts();

  // Effects
  useEffect(() => {
    if (!bankAccount) {
      form?.resetFields();
    }
    return () => {
      form?.resetFields();
    };
  }, []);

  // Constants
  const initialValues: Partial<AddBankAccount> | undefined = bankAccount
    ? {
        bank_name: bankAccount?.bank_name,
        bic: bankAccount?.bic,
        iban: bankAccount?.iban,
        owner: bankAccount?.owner,
      }
    : undefined;

  function onFinish(values: AddBankAccount) {
    if (bankAccount) {
      update(values, bankAccount.id, close);
    } else {
      add(values, close);
    }
  }

  return (
    <Form form={form} initialValues={initialValues} layout="vertical" onFinish={onFinish}>
      {/* Bank */}
      <Form.Item
        name="bank_name"
        label={t(TRANSLATION_KEY.bankName)}
        rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
      >
        <Input />
      </Form.Item>

      {/* Owner */}
      <Form.Item
        name="owner"
        label={t(TRANSLATION_KEY.accountOwner)}
        rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
      >
        <Input />
      </Form.Item>

      {/* IBAN */}
      <Form.Item
        name="iban"
        label={
          <Typography.Text style={{ textTransform: "uppercase" }}>
            {t(TRANSLATION_KEY.iban)}
          </Typography.Text>
        }
        rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
      >
        <Input />
      </Form.Item>

      {/* BIC */}
      <Form.Item
        name="bic"
        label={
          <Typography.Text style={{ textTransform: "uppercase" }}>
            {t(TRANSLATION_KEY.bic)}
          </Typography.Text>
        }
        rules={[{ required: true, message: t(TRANSLATION_KEY.filedRequired) }]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
};

export default BankAccountForm;
