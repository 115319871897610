import { message } from "antd";
import { t } from "i18next";
import { useCallback, useState } from "react";
import { TRANSLATION_KEY } from "../../../../../../helpers/consts";
import { debounce } from "../../../../../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks";
import { AddBankAccount } from "../../../../../../models/finance";
import api, { failedQueue, isRefreshing } from "../../../../../../services";
import { financeSlice } from "../../../../../../store/reducers/finance";
import {
  addBankAccountXHR,
  getBankAccountsXHR,
  updateBankAccountXHR,
} from "../../../../../../store/reducers/finance/actionCreators";

const useBankAccounts = () => {
  // Hooks
  const dispatch = useAppDispatch();

  // State
  const [deleteLoading, setDeleteLoading] = useState<number>(-1);
  const { bankAccounts, getBankAccountsStatus } = useAppSelector((state) => state.financeReducer);
  const [filters, setFilters] = useState<BankAccountsFilters>({
    offset: 0,
    limit: 10,
    search: "",
    client__isnull: true,
  });

  // #region CRUD Operations
  function fetchData(queryParams = filters) {
    getBankAccountsXHR(
      {
        queryParams,
        errorCallback: (data) => {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
        },
      },
      dispatch,
    );
  }

  function addBankAccount(values: AddBankAccount, onSuccess?: () => void) {
    addBankAccountXHR(
      {
        body: values,
        errorCallback(error) {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
        },
        successCallback(data) {
          onSuccess && onSuccess();
        },
      },
      dispatch,
    );
  }

  function updateBankAccount(values: AddBankAccount, id: number, onSuccess?: () => void) {
    updateBankAccountXHR(
      {
        body: values,
        id,
        errorCallback(error) {
          message.error(t(TRANSLATION_KEY.errorOnGetData));
        },
        successCallback(data) {
          onSuccess && onSuccess();
        },
      },
      dispatch,
    );
  }

  async function deleteBankAccount(id: number) {
    setDeleteLoading(id || -1);
    let token = localStorage.getItem("token");
    if (!token) return;
    try {
      let res = await api.delete(`/finances/bank_account/${id}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let data = [...bankAccounts.data];
      let index = data.findIndex((item) => item.id === id);
      if (index !== -1) {
        data.splice(index, 1);
        dispatch(
          financeSlice.actions.getBankAccountsSuccess({
            results: {
              data,
              total: bankAccounts.total - 1,
            },
            message: "",
            mergeData: false,
          }),
        );
      }
    } catch (error: any) {
      console.log(error);
      if (error.response.status === 401) {
        if (error?.response?.status === 401) {
          if (isRefreshing) {
            failedQueue.push(() => deleteBankAccount(id));
          }
          return;
        }
        message.error(t(TRANSLATION_KEY.errorOnDeleteData));
      }
    } finally {
      setDeleteLoading(-1);
    }
  }

  // #endregion

  // #region Filters
  function updateFilters(newFilters: Partial<BankAccountsFilters>) {
    setFilters((prev) => ({
      ...prev,
      ...newFilters,
    }));
  }

  function sync(filters: BankAccountsFilters) {
    // Update filters
    updateFilters(filters);
    // Fetch data
    fetchData(filters);
  }

  function onPageChange(page: number, pageSize: number) {
    let newFilters: BankAccountsFilters = {
      ...filters,
      offset: (page - 1) * pageSize,
      limit: pageSize,
    };
    sync(newFilters);
  }

  function onSearchChange(search: string) {
    let newFilters = {
      ...filters,
      search,
    };
    sync(newFilters);
  }

  const search = useCallback(debounce<typeof onSearchChange>(onSearchChange, 500), []);

  // #endregion

  return {
    data: bankAccounts.data,
    total: bankAccounts.total,
    loading: getBankAccountsStatus === "loading",
    deleteLoading,
    filters,
    fetchData,
    add: addBankAccount,
    update: updateBankAccount,
    delete: deleteBankAccount,
    onPageChange,
    search,
  };
};

export default useBankAccounts;
export type BankAccountsHook = ReturnType<typeof useBankAccounts>;
export type BankAccountsFilters = {
  // Pagination params
  offset: number;
  limit: number;
  // Data params
  search: string;
  client__isnull: boolean;
};
